import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 5%;



  @media print {
    button {
      display: none;
    }
  }
`
