import React, { useState } from 'react'
import { useLazyQuery, useMutation, gql } from '@apollo/client'
import { Container, Wrapper, Icon, CustomError } from './Styled'
import { Spinner } from 'core/components/VoucherForm/Spinner'
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik'
import { initValues, schema } from './data'
import { MDBBtn } from 'mdbreact'
import { api } from 'data/apollo/api'
import { getTokenDetails } from 'data/helpers/token'
import { PostRequest } from './PostRequest'
import { ReactComponent as LogoAnim } from 'assets/images/dots-anim.svg'
import debounce from 'lodash/debounce'
import clone from 'lodash/cloneDeep'
import OCH from 'react-outside-click-handler'
import _map from 'lodash/map';
import { values } from 'lodash'

interface FormData {
  ClientID: string
  year: string
  make: string
  model: string
  stknum: string
  vinnum: string
  description: string
  color: string
  DealerName: string
  dealerID: string
}

const fragment = gql`
  fragment NewPermStock on GetPermanentStock {
    ClientID
    stknum
    vinnum
    year
    make
    model
    color
    description
  }
`

export const Form: React.FC<{
  dealers: any
  form: { formData: FormData; setFormData: (param: any) => void }
  setShowForm: (param: boolean) => void
}> = ({ form: { formData, setFormData }, setShowForm, dealers }) => {

  const userAccess = getTokenDetails('userAccess');
//  const clientid = getTokenDetails('clientid')
  const [showRequestStatusModal, setShowRequestStatusModal] = useState(false)
  const initialFormValues = formData || initValues
  const [stkExists, setStkExists] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [doesStknumExist, { loading: stkLoading }] = useLazyQuery(
    api.remote.query.doesStknumExist,
    {
      onCompleted: ({ permanentStock }) => {
        if (permanentStock.length) {
          setStkExists(true)
        } else {
          setStkExists(false)
        }
      }
    }
  )

  const [editForm] = useMutation(api.remote.mutation.editPermanentStock, {
    onCompleted: () => {
      setFormData(null)
      setShowRequestStatusModal(true)
    },
    update(cache, { data: { editPermanentStock } }) {
      cache.modify({
        fields: {
          permanentStock(existingPermanentStock) {
            const copiedPermStock = clone(existingPermanentStock)
            const index = existingPermanentStock.findIndex(
              (ele: FormData) => ele.stknum === editPermanentStock.stknum
            )
            editPermanentStock.__typename = 'MergedClass'
            const temp = Object.assign(copiedPermStock[index], editPermanentStock)
            copiedPermStock.splice(index, 1, temp)
            return copiedPermStock
          }
        }
      })
    }
  })

  const [submitForm] = useMutation(api.remote.mutation.addPermanentStock, {
    onCompleted: () => {
      setFormData(null)
      setShowRequestStatusModal(true)
    },
    update(cache, { data: addPermanentStock }) {
      cache.modify({
        fields: {
          permanentStock(existingPermanentStock) {
            const newPermStock = cache.writeFragment({
              data: addPermanentStock,
              fragment
            })
            return [...existingPermanentStock, newPermStock]
          }
        }
      })
    }
  })

  const handleStkNum = (e: React.ChangeEvent<HTMLInputElement>, clientid: string) => {
    setStkExists(false)
    if (!e.target.value || !clientid) {
      return
    }
    doesStknumExist({
      variables: {
        cids: userAccess,
        clientid: clientid,
        stknum: e.target.value
      }
    })
  }

  const debouncedFunc = debounce(handleStkNum, 1000)

  return (
    <Container>
      <OCH
        onOutsideClick={() => {
          setFormData(null)
          setShowForm(false)
        }}
        display='contents'
      >
        <Wrapper>
          {showRequestStatusModal ? (
            <PostRequest setShowForm={setShowForm} />
          ) : (
            <Formik
              initialValues={initialFormValues}
              validationSchema={schema}
              onSubmit={async values => {
                setFormSubmitting(true)
                formData
                  ? editForm({ variables: { ...values } })
                  : submitForm({ variables: { ...values } })
              }}
            >
              {({ values, errors, touched, handleChange }) => (
                <>
                  {formSubmitting && <Spinner />}
                  <FormikForm autoComplete='off' spellCheck='false'>
                  <label htmlFor='ClientID'>Location</label>
                    <Field
                      name='ClientID'
                      as='select'
                      id='ClientID'
                      value={values.ClientID}
                      className={`form-control ${
                        touched.ClientID && errors.ClientID ? 'is-invalid' : ''
                      }`}
                      >
                      {
                        _map(dealers, (location: string, clientid: string) => {
                          return <option key={clientid.toLowerCase()} value={clientid.toLowerCase()}>{location}</option>
                        })
                      }
                    </Field>
                    <ErrorMessage
                      component='div'
                      name='ClientID'
                      className='invalid-feedback'
                    />
                    <label htmlFor='stknum'>Stock Number</label>
                    {stkLoading && (
                      <Icon>
                        <LogoAnim />
                      </Icon>
                    )}
                    <Field
                      name='stknum'
                      type='input'
                      id='stknum'
                      readOnly={formData?.stknum}
                      value={values.stknum}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e)
                        debouncedFunc(e, values.ClientID)
                      }}
                      className={`form-control ${
                        (touched.stknum && errors.stknum) || stkExists
                          ? 'is-invalid'
                          : ''
                      }`}
                    />
                    {stkExists && (
                      <CustomError>Stock number already exists</CustomError>
                    )}
                    {!stkExists && (
                      <ErrorMessage
                        component='div'
                        name='stknum'
                        className='invalid-feedback'
                      />
                    )}
                    <label htmlFor='year'>Vehicle Year</label>
                    <Field
                      name='year'
                      type='input'
                      id='year'
                      value={values.year}
                      // readOnly={true}
                      className={`form-control ${
                        touched.year && errors.year ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      component='div'
                      name='year'
                      className='invalid-feedback'
                    />
                    <label htmlFor='make'>Vehicle Make</label>
                    <Field
                      name='make'
                      type='input'
                      id='make'
                      value={values.make}
                      // readOnly={true}
                      className={`form-control ${
                        touched.make && errors.make ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      component='div'
                      name='make'
                      className='invalid-feedback'
                    />
                    <label htmlFor='model'>Vehicle Model</label>
                    <Field
                      name='model'
                      type='input'
                      id='model'
                      value={values.model}
                      // readOnly={true}
                      className={`form-control ${
                        touched.model && errors.model ? 'is-invalid' : ''
                      }`}
                    />
                    <label htmlFor='color'>Color</label>
                    <Field
                      name='color'
                      type='input'
                      id='color'
                      value={values.color}
                      // readOnly={true}
                      className={`form-control ${
                        touched.color && errors.color ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      component='div'
                      name='color'
                      className='invalid-feedback'
                    />
                    <ErrorMessage
                      component='div'
                      name='model'
                      className='invalid-feedback'
                    />
                    <label htmlFor='vinnum'>VIN</label>
                    <Field
                      name='vinnum'
                      type='input'
                      id='vinnum'
                      value={values.vinnum}
                      // readOnly={true}
                      className={`form-control ${
                        touched.vinnum && errors.vinnum ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      component='div'
                      name='vinnum'
                      className='invalid-feedback'
                    />
                    <label htmlFor='description'>Description</label>
                    <Field
                      name='description'
                      type='input'
                      id='description'
                      value={values.description}
                      // readOnly={true}
                      className={`form-control ${
                        touched.description && errors.description
                          ? 'is-invalid'
                          : ''
                      }`}
                    />
                    <ErrorMessage
                      component='div'
                      name='description'
                      className='invalid-feedback'
                    />
                    <MDBBtn
                      color='primary'
                      style={{ margin: '0 auto' }}
                      type='submit'
                      disabled={stkExists || formSubmitting}
                    >
                      Submit
                    </MDBBtn>
                  </FormikForm>
                </>
              )}
            </Formik>
          )}
        </Wrapper>
      </OCH>
    </Container>
  )
}
