import React from 'react'
import { THead } from './Styled'
import { ArrowBox } from './ArrowBox'
import { Wrapper } from './Styled'

export const TableHeader: React.FC<{
  sorting: { nestedTableSort: any; requestSort: (arg: string) => void }
}> = ({ sorting: { nestedTableSort, requestSort } }) => {
  const handleClick = (type: string) => () => {
    requestSort(type)
  }
  return (
    <THead>
      <tr>
        <th onClick={handleClick('voucherid')}>
          <Wrapper highlight={nestedTableSort.key === 'voucherid'}>
            <span className='fuelops__th-underline'>ID</span>
            {nestedTableSort.key === 'voucherid' && (
              <ArrowBox config={nestedTableSort} headerName='voucherid' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('clientid')}>
          <Wrapper highlight={nestedTableSort.key === 'clientid'}>
            <span className='fuelops__th-underline'>Location</span>
            {nestedTableSort.key === 'clientid' && (
              <ArrowBox config={nestedTableSort} headerName='clientid' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('status')}>
          <Wrapper highlight={nestedTableSort.key === 'status'}>
            <span className='fuelops__th-underline'>Status</span>
            {nestedTableSort.key === 'status' && (
              <ArrowBox config={nestedTableSort} headerName='status' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('amtnottoexceed')}>
          <Wrapper highlight={nestedTableSort.key === 'amtnottoexceed'}>
            <span className='fuelops__th-underline'>Amt. Iss.</span>
            {nestedTableSort.key === 'amtnottoexceed' && (
              <ArrowBox config={nestedTableSort} headerName='amtnottoexceed' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('stknum')}>
          <Wrapper highlight={nestedTableSort.key === 'stknum'}>
            <span className='fuelops__th-underline'>Stk</span>
            {nestedTableSort.key === 'stknum' && (
              <ArrowBox config={nestedTableSort} headerName='stknum' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('vehcolor')}>
          <Wrapper highlight={nestedTableSort.key === 'vehcolor'}>
            <span className='fuelops__th-underline'>Color</span>
            {nestedTableSort.key === 'vehcolor' && (
              <ArrowBox config={nestedTableSort} headerName='vehcolor' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('vehmake')}>
          <Wrapper highlight={nestedTableSort.key === 'vehmake'}>
            <span className='fuelops__th-underline'>Make</span>
            {nestedTableSort.key === 'vehmake' && (
              <ArrowBox config={nestedTableSort} headerName='vehmake' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('vehmodel')}>
          <Wrapper highlight={nestedTableSort.key === 'vehmodel'}>
            <span className='fuelops__th-underline'>Model</span>
            {nestedTableSort.key === 'vehmodel' && (
              <ArrowBox config={nestedTableSort} headerName='vehmodel' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('issuedto')}>
          <Wrapper highlight={nestedTableSort.key === 'issuedto'}>
            <span className='fuelops__th-underline'>Iss. To</span>
            {nestedTableSort.key === 'issuedto' && (
              <ArrowBox config={nestedTableSort} headerName='issuedto' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('departmentissue')}>
          <Wrapper highlight={nestedTableSort.key === 'departmentissue'}>
            <span className='fuelops__th-underline'>Dept</span>
            {nestedTableSort.key === 'departmentissue' && (
              <ArrowBox config={nestedTableSort} headerName='departmentissue' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('createdby')}>
          <Wrapper highlight={nestedTableSort.key === 'createdby'}>
            <span className='fuelops__th-underline'>Issued</span>
            {nestedTableSort.key === 'createdby' && (
              <ArrowBox config={nestedTableSort} headerName='createdby' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('totalspent')}>
          <Wrapper highlight={nestedTableSort.key === 'totalspent'}>
            <span className='fuelops__th-underline'>Closed</span>
            {nestedTableSort.key === 'totalspent' && (
              <ArrowBox config={nestedTableSort} headerName='totalspent' />
            )}
          </Wrapper>
        </th>
        <th onClick={handleClick('createdby')}>
          <Wrapper highlight={nestedTableSort.key === 'createdby'}>
            <span className='fuelops__th-underline'>Dept</span>
            {nestedTableSort.key === 'createdby' && (
              <ArrowBox config={nestedTableSort} headerName='createdby' />
            )}
          </Wrapper>
        </th>
      </tr>
    </THead>
  )
}
