import styled from 'styled-components'

export const Row = styled.tr<{ expanded?: boolean; lastRow: boolean }>`
  height: 40px;
  font-size: 12px;
  vertical-align: top;
  color: #55585e;

  td:first-child {
    padding-left: 15px;
    color: blue !important;
  }

  td:last-child {
    padding: 8px 2px;
    text-align: center;
    cursor: pointer;
    background-color: ${props => (props.expanded ? '#f7fbff' : 'initial')};
  }

  td {
    border-bottom: ${props => (props.lastRow ? 'none' : '2px solid #eef1f7')};
    padding: 10px 5px 4px;
    font-weight: 600;
  }

  #fuelops__table-borderdiv {
    border-left: 2px solid #eef1f7;
    height: 70%;
    width: 2px;
  }

  #fuelops__recaps-recon-dots {
    cursor: pointer;
    padding: 0;
    svg {
      fill: #b6b7bd;
      height: 20px;
      width: 20px;
    }
  }

  #fuelops__recaps-recon-dots:hover {
    svg {
      fill: #5d6066;
    }
  }
`

export const TBody = styled.tbody`
  #reconciliation__tbody-row-hidden {
    display: none;
  }
  #reconciliation__tbody-row-hidden {
    display: default;
  }
`