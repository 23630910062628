import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 20px;
  font-size: 12px;
  color: #55585e;

  .pagination ul {
    display: inline-block;
    margin-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .pagination li {
    display: inline-block;
    padding: 0 3px;
    color: #ccc;
    font-size: 1.1em;
  }

  .paginate-active {
    color: ${props => props.theme.main.charcoal} !important;
  }

  tr {
    :first-child {
      td:first-child {
        border-radius: 5px 0 0 0;
      }
      td:last-child: 0 5px 0 0;
    }
    :last-child {
      td:first-child {
        border-radius: 0 0 0 5px;
      }
      td:last-child {
        border-radius: 0 0 5px 0;
      }
    }
    td {
      padding: 4px 5px;
    }
  }

  th {
    min-width: 90px;
    padding: 10px 5px;
    cursor: pointer;
  }

  th:first-child {
    padding-left: 9px;
    min-width: 30px;
  }

  tbody {
    background-color: #fff;
    box-shadow: 0 0 4px 2px #e7e7e7;
    border-radius: 10px;
  }

  thead {
    tr:first-child {
      border-top: none;
    }
  }

  // tr {
  //   border-top: 2px solid #f2f2f2;
  // }

  td:first-child {
    padding-left: 15px;
    border-top: none;
  }

  td:last-child {
    width: 100px;
    padding-right: 15px;
  }

  td {
    padding: 8px 5px;
    font-weight: bolder;
    border-top: 1.5px solid #f2f2f2;
    color: ${props => props.theme.main.charcoal};
  }

  tfoot {
    tr {
      background-color: #f4f4f5;
      border-radius: 5px;
    }
  }
`

export const Wrapper = styled.div<{ highlight?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${props => (props.highlight ? props.theme.main.charcoal : '#aaa')};
`

export const Searchbox = styled.div`
  width: 300px;
`
