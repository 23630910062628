import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding-left: 10px;

  .md-form {
    margin: 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
`
