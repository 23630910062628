import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  font-family: Montserrat, sans-serif;
  top: 8px;
  left: 75px;
  background-color: #abb1b5;
  border-radius: 3px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    6px 6.7px 5.3px rgba(0, 0, 0, 0.048), 12px 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086);

  :before {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    top: 10px;
    left: -20px;
    border: 10px solid transparent;
    border-right-color: #abb1b5;
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
  }

  li {
    width: 200px;
    background-position: 100% 0%;
    background-image: linear-gradient(
      to right,
      rgb(86, 101, 115) 50%,
      transparent 50%
    );
    background-size: 201% 101%;
    transition: all 0.25s ease-in-out;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.25s ease-in-out;
      height: 45px;
      width: 100%;
      color: #fff;
      text-decoration: none;
    }

    &:hover {
      background-position: 0% 50%;
    }
  }
  li:first-child {
    border-radius: 3px 3px 0 0;
  }
  li:last-child {
    border-radius: 0 0 3px 3px;
  }
`
