import React, { useState, useEffect, useRef } from 'react'
import { useApolloClient, useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Container } from './Styled'
import { Header } from './Header'
import { VoucherInformation } from './VoucherInformation'
import { OrderHistory } from './OrderHistory'
import { AuthorizationDetails } from './AuthorizationDetails'
import { getTokenDetails } from 'data/helpers/token'
import { ShowNull } from './ShowNull'
import { api } from 'data/apollo/api'
import { LogoAnim } from 'core/components/LogoAnim'
import { ErrorScreen } from 'core/components/ErrorScreen'
import Button from 'react-bootstrap/Button'

export const Print: React.FC = (props: any) => {
  const { location } = props
  const client = useApolloClient()
  client.query({ query: api.remote.query.makes })
  const signature = useRef('')
  const times = useRef({ startTime: 0, endTime: 0 })
  const [showButton, setShowButton] = useState(true)
  const history = useHistory()
  const [showNull, setShowNull] = useState(false)
  const dataValues = location.state?.dataValues || {}
  const [setPrint] = useMutation(api.remote.mutation.setVoucherPrinted, {
    variables: {
      voucherid: dataValues.voucherid,
      VoucherPrinted: true,
      VoucherSignature: signature.current
    },
    onCompleted: () => {
      location.state.dataValues = { redirect: true }
    }
  })
  
  const {
    loading: dealerLoading,
    error: dealerError,
    data: dealerData
  } = useQuery(api.remote.query.dealerInfo, {
    variables: {
      clientid: dataValues.clientid
    }
  })
  
  const [findVoucher] = useLazyQuery(api.remote.query.findVoucher, {
    variables: { voucherid: dataValues.voucherid },
    onCompleted: resp => {
      if (!resp.getVoucher) {
        history.replace('/')
      }
    }
  })
  
  useEffect(() => {
    const mediaQueryList = window.matchMedia('print')
    let canSend = true
    let showNull = true
    const watchPrint = async (mql: any) => {
      // setShowButton(false)
      if (!mql.matches) {
        times.current.startTime = Date.now()
        if (canSend) {
          canSend = false
          const data: any = {
            voucherid: dataValues.voucherid
          }
          if (signature) data.VoucherSignature = signature.current
          try {
            setPrint()
          } catch (e) {
            console.log(e)
          }
        }
      } else {
        times.current.endTime = Date.now()
        // setShowButton(false)
        if (showNull) {
          showNull = false
          mediaQueryList.removeEventListener('print', watchPrint)
          setTimeout(() => {
            setShowNull(true)
          }, 500)
        }
      }
    }
    mediaQueryList.addListener(watchPrint)
    return () => mediaQueryList.removeEventListener('print', watchPrint)
  }, [])
  
  const handlePrint = () => {
    window.print()
  }
  
  useEffect(() => {
    async function getVoucher() {
      if (dataValues.voucherid) {
        findVoucher()
      }
    }
    getVoucher()
  }, [dataValues.voucherid]);
  
  
  if (JSON.stringify(dataValues) === '{}') {
    history.push('/')
  }
  
  if (showNull) {
    return <ShowNull times={times.current} />
  }
  
  if (dealerLoading) {
    return <LogoAnim />
  }
  
  if (dealerError) {
    return <ErrorScreen />
  }
  
  return (
    <Container>
          <Header data={dealerData} />
          <VoucherInformation {...dataValues} />
          <AuthorizationDetails
            {...dataValues}
          />
          <OrderHistory currentVoucher={dataValues} />
          <br />
          <strong>
            *** NOTE: PURCHASE ORDER ONLY VALID ON DATE OF ISSUE ***
          </strong>
          {showButton && (
            <Button
              disabled={JSON.stringify(location.state) === '{}'}
              onClick={handlePrint}
              size='lg'
              style={{ width: '150px', margin: '20px auto 0' }}
            >
              Print
            </Button>
          )}
    </Container>
  )
}
