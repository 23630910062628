import React from 'react'
import { Row, Col } from 'antd';
//import { useMediaQuery } from 'react-responsive'
import logo from 'assets/images/fuelops.svg';
import { UserProfile } from './UserProfile';

export const DashboardLayout: React.FC<{
  children?: any
}> = ({ children }) => {
//  const isMobile = useMediaQuery({ maxWidth: '767px' })
  
  return (
    <Row
      justify="space-around"
      align="middle"
      style={{
        backgroundColor: '#fff',
        boxShadow: '0px 2px 4px #e5e5e5',
        fontFamily: 'Montserrat, sans-serif',
        height: '45px',
        zIndex: 1000
      }}
    >
      <Col flex='160px'>
        <img src={logo} style={{ marginLeft:'15px', width:'140px', height:'27px' }}/>
      </Col>
      {children && (
        <Col flex='380px'>
          {children}
        </Col>
      )}
      <Col flex='auto' style={{height:'100%'}}>
        <UserProfile />
      </Col>
    </Row>
  )
}
