import React from 'react'
import { Route, Redirect } from 'react-router'
import { getTokenDetails } from 'data/helpers/token'

export const PrivatePrint = ({ component: Component, ...rest }) => {
  const details = getTokenDetails('userType')
  const auth = details === '1' || details === '2' || details === '4'
  return (
    <Route
      {...rest}
      render={props =>
        auth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/'
            }}
          />
        )
      }
    />
  )
}
