import React, { useState } from 'react'
import { Container, Wrapper, Image, CardInfo } from './Styled'
import bg from 'assets/images/reconcile-card-bg.jpg'
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle } from 'mdbreact'
import { ReactComponent as Minimize } from 'assets/images/minimize.svg'
import { ReactComponent as Expand } from 'assets/images/expand.svg'
import { Result } from './Result'
import { Transition } from 'react-transition-group'
import DealerOpsLogo from 'assets/images/dealeropsfourdots.png'

const calculateValues = (data: any) => {
  const vouchers = data
  if (!vouchers) {
    return undefined
  }
  const values = vouchers.reduce(
    (acc: any, curr: any) => {
      if (curr.Reconciled) {
        acc.reconciled += curr.totalspent
        acc.numRec++
      }
      if (!curr.Reconciled && curr.totalspent > curr.amtnottoexceed) {
        acc.exceed.push(curr)
      }
      acc.outstanding += curr.amtnottoexceed
      return acc
    },
    {
      outstanding: 0,
      reconciled: 0,
      numRec: 0,
      exceed: []
    }
  )
  values.reconciled = values.reconciled.toFixed(2)
  values.outstanding = values.outstanding.toFixed(2)
  values.remaining = (vouchers.length - values.numRec).toFixed(0)

  delete values.numRec

  return values
}

export const Card: React.FC<{ data: any; minimize: any }> = ({
  data,
  minimize: { minimize, setMinimize }
}) => {
  const dataValues = calculateValues(data)
  const [translate, setTranslate] = useState({ x: 0, y: 0 })

  const handleMinimize = (event: any) => {
    const tabX = window.innerWidth - 20
    const tabY = 100
    const targetX = +((tabX - event.clientX) / 1.8).toFixed(0)
    const targetY = +((tabY - event.clientY) / 1.8).toFixed(0)
    setTranslate({ x: targetX, y: targetY })
    setMinimize(!minimize)
  }

  return (
    <Transition
      in={!minimize}
      mountOnEnter
      unmountOnExit
      timeout={{ enter: 0, exit: 200 }}
    >
      {state => (
        <Container state={state} translate={translate}>
          <MDBCard>
            <div
              style={{
                backgroundColor: '#55585e',
                height: '29px',
                width: '100%'
              }}
            />
            {/* <MDBCardImage className='img-fluid' src={bg} /> */}
            <img
              style={{
                position: 'absolute',
                top: '5.5px',
                left: '105px',
                height: '16px',
                width: 'auto'
              }}
              src={DealerOpsLogo}
              alt='DealerOps Logo'
            />
            <span
              style={{
                position: 'absolute',
                top: '3px',
                left: '19px',
                color: '#fff'
              }}
            >
              Summary
            </span>
            <Image onClick={handleMinimize}>
              {minimize ? <Expand /> : <Minimize />}
            </Image>
            {/* <Wrapper minimize={minimize}> */}
            <Wrapper>
              {parseInt(dataValues.remaining) !== 0 ? (
                <MDBCardBody>
                  <CardInfo>
                    <span>Total Outstanding</span>
                    <span>${dataValues.outstanding}</span>
                  </CardInfo>
                  {/* <CardInfo>
                <span>Total Reconciled</span>
                <span>${dataValues.reconciled}</span>
              </CardInfo> */}
                  {/* <CardInfo>
                <span>Difference</span>
                {colorizeDifference(
                  dataValues.reconciled,
                  dataValues.outstanding
                )}
              </CardInfo> */}
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Unreconciled(#)</span>
                    <span>{dataValues.remaining}</span>
                  </div>
                </MDBCardBody>
              ) : (
                <h5 style={{ padding: '20px' }}>
                  All vouchers in this date range have been reconciled
                </h5>
                // <Result />
              )}
            </Wrapper>
          </MDBCard>
        </Container>
      )}
    </Transition>
  )
}
