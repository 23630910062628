import React from 'react'
import { Route, Redirect } from 'react-router'
import { checkAuth } from 'data/helpers/token'

export const PrivateRoute = ({ component: Component, dealers, setDateRange, dateRange, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkAuth() ? (
        <Component dealers={dealers} setDateRange={setDateRange} dateRange={dateRange} {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/'
          }}
        />
      )
    }
  />
)
