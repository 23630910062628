import React, { useState } from 'react'
import { Container } from './Styled'
import { SummaryTable } from './SummaryTable'
import { FraudTable } from './FraudTable'
import { Transition } from 'react-transition-group'
import { Overlay } from './Overlay'
import { ReturnDisplay } from 'scenes/Dashboard/components/Table/components/ReturnDisplay'

export const Tables: React.FC<{ data?: any }> = ({ data }) => {
//  const { vouchersInRange } = data
  const [sharedData, setSharedData] = useState<any>({});
  const fVouchers = fraudulentVouchersByCreated(data);
  
  return (
    <Container>
      <Transition
        in={!!sharedData.voucherid}
        mountOnEnter
        unmountOnExit
        timeout={{ exit: 0 }}
      >
        {state => (
          <Overlay
            sharedData={sharedData}
            state={state}
            Component={ReturnDisplay}
            closeModal={setSharedData}
          />
        )}
      </Transition>
      <h4>Summary</h4>
      <SummaryTable data={fVouchers} />
      <FraudTable data={data} setSharedData={setSharedData} />
    </Container>
  )
}

export const fraudulentVouchersByCreated = (data: any) => {
  const mappingObject: { [key: string]: any } = {}
  data.forEach((item: any) => {
    if (!Object.keys(mappingObject).includes(item.createdby)) {
      mappingObject[item.createdby] = {
        count: 0,
        pending: 0,
        closed: 0
      }
    }
    if (item.totalspent > item.amtnottoexceed) {
      const person = mappingObject[item.createdby]
      person.count++
      person.pending += item.amtnottoexceed
      person.closed += item.totalspent
    }
  })
  return mappingObject
}
