import React from 'react'
import { Container } from './Styled'

export const ErrorScreen: React.FC = () => {
  const handleMouse = (vals: any) => {
    const pageX = document.body.clientWidth || 500
    const pageY = document.body.clientHeight || 1000
    const $ele = document.querySelector('.box__ghost-eyes') as HTMLElement
    const yAxis = ((pageY / 2 - vals.clientY) / pageY) * 300
    const mouseX = vals.clientX / -pageX
    const xAxis = -mouseX * 100 - 100
    $ele!.style.transform = `translate(${xAxis}%, -${yAxis}%)`
  }
  return (
    <Container
      onMouseMove={({ clientX, clientY }) => handleMouse({ clientX, clientY })}
    >
      <div className='box'>
        <div className='box__ghost'>
          <div className='symbol'></div>
          <div className='symbol'></div>
          <div className='symbol'></div>
          <div className='symbol'></div>
          <div className='symbol'></div>
          <div className='symbol'></div>

          <div className='box__ghost-container'>
            <div className='box__ghost-eyes'>
              <div className='box__eye-left'></div>
              <div className='box__eye-right'></div>
            </div>
            <div className='box__ghost-bottom'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className='box__ghost-shadow'></div>
        </div>

        <div className='box__description'>
          <div className='box__description-container'>
            <div className='box__description-title'>Whoops!</div>
            <div className='box__description-text'>
              There was a problem fetching data for your request
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
