import React, { useState, createContext } from 'react'
import { useApolloClient } from '@apollo/client'
import { Container, Wrapper, Main } from './Styled'
import { CloseButton } from './CloseButton'
import { TotalsTable } from './TotalsTable'
import { AllRowsTable } from './AllRowsTable'
import { Overlay } from './Overlay'
import { Transition } from 'react-transition-group'
import { api } from 'data/apollo/api'

export const EditContext = createContext({})

export const EditRow: React.FC<{
  data?: any
  state?: any
  close?: any
}> = (props: any) => {
  const { data, state, close } = props
  const [sharedData, setSharedData] = useState<any>({})
  const client = useApolloClient()
  client.query({ query: api.remote.query.makes })
  const handleClose = () => {
    close({ items: [], title: '' })
  }
  const rowData = calculateRowTotals(data.items)
  return (
    <EditContext.Provider value={{ setSharedData }}>
      <Transition
        in={!!sharedData.voucherid}
        mountOnEnter
        unmountOnExit
        timeout={{ exit: 700 }}
      >
        {state => <Overlay sharedData={sharedData} state={state} />}
      </Transition>
      <Container state={state}>
        <Wrapper state={state}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseButton handleClose={handleClose} />
          </div>
          <Main>
            <TotalsTable data={data} rowData={rowData} />
            <AllRowsTable data={data} rowData={rowData} />
          </Main>
        </Wrapper>
      </Container>
    </EditContext.Provider>
  )
}

const calculateRowTotals = (data: any) => {
  return data.reduce(
    (acc: any, curr: any) => {
      const { amtnottoexceed, status, totalspent } = curr
      const { pending, closed, total } = acc
      let changedCount = 0
      let changedAmount = 0
      if (status === 'P') {
        pending.total += amtnottoexceed
        pending.count++
        changedCount++
        changedAmount += amtnottoexceed
      }
      if (status === 'F') {
        closed.total += amtnottoexceed
        closed.count++
        changedCount++
        changedAmount += amtnottoexceed
      }
      changedCount && (total.count += changedCount)
      changedAmount && (total.total += changedAmount)
      return acc
    },
    {
      pending: { count: 0, total: 0 },
      closed: { count: 0, total: 0 },
      total: { count: 0, total: 0 }
    }
  )
}
