import styled from 'styled-components'

export const Container = styled.div`
  background-color: #f4f4f5;
  padding: 15px 15px 0;
  height: calc(100vh - 45px);

  .table {
    td {
      padding: 2px 5px;
    }
  }
`