import JwtDecode from 'jwt-decode';
import store from 'store'

type Input = 'userType' | 'userAccess' | 'username' | 'corpdb' | 'clientid' | 'storeName'

type User = {
  userType: string,
  userAccess: string[],
  username: string,
  corpdb: string,
  clientid: string,
  storeName: string,
}

export const checkAuth = () => {
  return !!store.get('foAccess')
}

export const getTokenDetails = (input: Input) => {
  const user: User = JwtDecode(store.get('foAccess'));
  return user[input];
}
