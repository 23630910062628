import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: absolute;
  top: 30px;
  right: 20px;
  margin: 25px 0px;
  justify-content: space-between;
  max-width: 850px;
  color: #55585e;

  @media (min-width: 1197px) {
    left: 774px;
  }

  h4 {
    font-weight: bolder;
  }
`

export const OptionsWrapper = styled.div`
  position: relative;
  margin-right: 15px;
`

export const Button = styled.div`
  border: 2px solid #e0e3eb;
  padding: 6px 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
  color: #55585e;
  font-weight: bolder;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.2s linear;
  
  :hover {
    background-color: rgb(245, 245, 245);
  }

  .ripple {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    animation: ripple 0.6s linear;
  }

  @keyframes ripple {
    to {
      transform: scale(2.5);
      opacity: 0;
    }
  }

  svg {
    margin-left: 5px;
    height: 20px;
    width: 20px;
  }
`

export const Options = styled.div`
  position: absolute;
  top: 40px;
  right: -20px;
  z-index: 1;
  padding: 20px;
  background-color: #fff;
  border: 2px solid #e0e3eb;
  width: 250px;
  border-radius: 5px;

  .MuiTypography-body1 {
    font-size: 13px;
    color: #55585e;
    font-weight: bolder;
    font-family: Montserrat, sans-serif;
  }

  .MuiFormControlLabel-root {
    margin: 0;
  }
`

export const Wrapper = styled.div<{ active?: boolean }>`
  display: flex;

  #fuelops__recap-recon-search {
    position: relative;

    :active,
    :focus-within,
    :focus {
      svg {
        fill: #7c818f;
      }
    }

    input {
      padding: 5px 5px 5px 30px;
      border: 2px solid #e0e3eb;
      border-radius: 3px;
    }

    input::placeholder {
      color: #cdd0d6;
    }

    input:focus {
      border: 2px solid #7c818f;
      outline: none;
    }

    svg {
      position: absolute;
      top: 11px;
      left: 10px;
      width: 15px;
      height: 15px;
      fill: ${props => (props.active ? '#7c818f' : '#e0e3eb')};
    }
  }
`
