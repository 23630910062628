import React, { useMemo, useState, useEffect } from 'react'
import { Container, Wrapper, Searchbox } from './Styled'
import Checkbox from '@material-ui/core/Checkbox'
import cloneDeep from 'lodash/cloneDeep'
import { currencyFormatter, statusFormatter } from 'data/helpers'
import { formatDate } from 'data/helpers/dates'
import { ArrowBox } from './ArrowBox'
import { TR } from './TR'
import { useMutation, gql } from '@apollo/client'
import { Header } from './Header'
import { PrintButton } from 'core/components/PrintButton'
// @ts-ignore
import ReactPaginate from 'react-paginate'

export const FraudTable: React.FC<{ data: any; setSharedData?: any }> = ({
  data,
  setSharedData
}) => {
  const RESULTS_PER_PAGE = 5
  const [searchText, setSearchText] = useState('')
  const [hideColumns, setHideColumns] = useState(false)
  const [showOnlySelected, setShowOnlySelected] = useState(false)
  const [paginate, setPaginate] = useState(false)
  const [primaryCheckbox, setPrimaryCheckbox] = useState(false)
  const [offset, setOffset] = useState(0)
  const [sortConfig, setSortConfig] = useState({
    key: 'voucherid',
    order: 'desc'
  })
  
  let fVouchers = simpleFraudVouchers(data).map((voucher: any) => ({
    ...voucher,
    checked: false
  }));
  
  const requestSort = (key: string) => {
    let order = 'asc'
    if (sortConfig.key === key && sortConfig.order === 'asc') {
      order = 'desc'
    }
    setSortConfig({ key, order })
  }
  
  const handlePageClick = (data: any) => {
    let selected = data.selected
    setOffset(selected)
  }
  
  let sortedItems = useMemo(() => {
    return fVouchers.sort((a: any, b: any) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.order === 'asc' ? -1 : 1
      } else if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.order === 'asc' ? 1 : -1
      } else {
        return 0
      }
    })
  }, [fVouchers, sortConfig.key, sortConfig.order])

  sortedItems = useMemo(() => {
    return sortedItems.filter((ele: any) => {
      if (searchText === '') {
        return ele
      }
      let keepElement = false
      Object.keys(ele).forEach((key: any) => {
        if (keepElement === true) {
          return
        }
        if (key === 'status') {
          keepElement = statusFormatter(ele[key])!
            .toLowerCase()
            .includes(searchText.toLowerCase())
          return
        }
        keepElement =
          ele[key] &&
          ele[key].toString().toLowerCase().includes(searchText.toLowerCase())
      })
      return keepElement
    })
  }, [searchText, sortedItems, sortConfig.key, sortConfig.order])

  const openOverlay = (data: any) => {
    const copiedObj = { ...data }
    delete copiedObj.checked
    setSharedData(copiedObj)
  }

  const handleCheckboxes = (e: any) => {
    if (primaryCheckbox) {
      sortedItems.map((item: any) => (item.checked = false))
    } else {
      sortedItems.map((item: any) => (item.checked = true))
    }
    setPrimaryCheckbox(!primaryCheckbox)
  }

  if (showOnlySelected) {
    sortedItems = sortedItems.filter((item: any) => item.checked === true)
  }

  if (paginate) {
    sortedItems = sortedItems.filter((_: any, idx: number) => {
      if (offset === 0) {
        return idx < RESULTS_PER_PAGE
      }
      return (
        idx >= offset * RESULTS_PER_PAGE &&
        idx < (offset + 1) * RESULTS_PER_PAGE
      )
    })
  }

  return (
    <Container>
      <Header
        searchText={searchText}
        setSearchText={setSearchText}
        hideColumns={hideColumns}
        setHideColumns={setHideColumns}
        showOnlySelected={showOnlySelected}
        setShowOnlySelected={setShowOnlySelected}
        paginate={paginate}
        setPaginate={setPaginate}
      />
      <table>
        <thead>
          <tr className='fuelops__fraud-table-head'>
            <th>
              <Checkbox
                checked={primaryCheckbox}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={() => console.log('clicked')}
              />
            </th>
            <th onClick={() => requestSort('voucherid')}>
              <Wrapper highlight={sortConfig.key === 'voucherid'}>
                ID{' '}
                {sortConfig.key === 'voucherid' && (
                  <ArrowBox config={sortConfig} headerName='voucherid' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('clientid')}>
              <Wrapper highlight={sortConfig.key === 'clientid'}>
                Location{' '}
                {sortConfig.key === 'clientid' && (
                  <ArrowBox config={sortConfig} headerName='clientid' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('status')}>
              <Wrapper highlight={sortConfig.key === 'status'}>
                Status{' '}
                {sortConfig.key === 'status' && (
                  <ArrowBox config={sortConfig} headerName='status' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('createdate')}>
              <Wrapper highlight={sortConfig.key === 'createdate'}>
                Issued{' '}
                {sortConfig.key === 'createdate' && (
                  <ArrowBox config={sortConfig} headerName='createdate' />
                )}
              </Wrapper>
            </th>
            {!hideColumns && (
              <th onClick={() => requestSort('vinnum')}>
                <Wrapper highlight={sortConfig.key === 'vinnum'}>
                  VIN{' '}
                  {sortConfig.key === 'vinnum' && (
                    <ArrowBox config={sortConfig} headerName='vinnum' />
                  )}
                </Wrapper>
              </th>
            )}
            {!hideColumns && (
              <th onClick={() => requestSort('vehcolor')}>
                <Wrapper highlight={sortConfig.key === 'vehcolor'}>
                  Color{' '}
                  {sortConfig.key === 'vehcolor' && (
                    <ArrowBox config={sortConfig} headerName='vehcolor' />
                  )}
                </Wrapper>
              </th>
            )}
            {!hideColumns && (
              <th onClick={() => requestSort('vehmake')}>
                <Wrapper highlight={sortConfig.key === 'vehmake'}>
                  Make{' '}
                  {sortConfig.key === 'vehmake' && (
                    <ArrowBox config={sortConfig} headerName='vehmake' />
                  )}
                </Wrapper>
              </th>
            )}
            {!hideColumns && (
              <th onClick={() => requestSort('vehmodel')}>
                <Wrapper highlight={sortConfig.key === 'vehmodel'}>
                  Model{' '}
                  {sortConfig.key === 'vehmodel' && (
                    <ArrowBox config={sortConfig} headerName='vehmodel' />
                  )}
                </Wrapper>
              </th>
            )}
            <th onClick={() => requestSort('issuedto')}>
              <Wrapper highlight={sortConfig.key === 'issuedto'}>
                Issued To{' '}
                {sortConfig.key === 'issuedto' && (
                  <ArrowBox config={sortConfig} headerName='issuedto' />
                )}
              </Wrapper>
            </th>
            {!hideColumns && (
              <th onClick={() => requestSort('departmentissue')}>
                <Wrapper highlight={sortConfig.key === 'departmentissue'}>
                  Dept{' '}
                  {sortConfig.key === 'departmentissue' && (
                    <ArrowBox
                      config={sortConfig}
                      headerName='departmentissue'
                    />
                  )}
                </Wrapper>
              </th>
            )}
            <th onClick={() => requestSort('amtnottoexceed')}>
              <Wrapper highlight={sortConfig.key === 'amtnottoexceed'}>
                Issued
                {sortConfig.key === 'amtnottoexceed' && (
                  <ArrowBox config={sortConfig} headerName='amtnottoexceed' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('totalspent')}>
              <Wrapper highlight={sortConfig.key === 'totalspent'}>
                Closed{' '}
                {sortConfig.key === 'totalspent' && (
                  <ArrowBox config={sortConfig} headerName='totalspent' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('totalspent')}>
              <Wrapper highlight={sortConfig.key === 'totalspent'}>
                Difference{' '}
                {sortConfig.key === 'totalspent' && (
                  <ArrowBox config={sortConfig} headerName='totalspent' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('createdby')}>
              <Wrapper highlight={sortConfig.key === 'createdby'}>
                Issued By{' '}
                {sortConfig.key === 'createdby' && (
                  <ArrowBox config={sortConfig} headerName='createdby' />
                )}
              </Wrapper>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedItems.map((voucher: any) => {
            return (
              <TR
                key={voucher.voucherid}
                voucher={voucher}
                openOverlay={openOverlay}
                hideColumns={hideColumns}
              />
            )
          })}
        </tbody>
      </table>
      <PrintButton />
      {paginate && (
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(fVouchers.length / RESULTS_PER_PAGE)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'paginate-active'}
        />
      )}
    </Container>
  )
}

export const fraudulentVouchers = (data: any) => {
  return cloneDeep(data).filter((datum: any) => {
    datum.createdate = formatDate(datum.createdate)
    datum.amtnottoexceed = currencyFormatter(datum.amtnottoexceed)
    datum.totalspent = currencyFormatter(datum.totalspent)
    datum.difference = currencyFormatter(
      datum.totalspent - datum.amtnottoexceed
    )
    return datum.totalspent > datum.amtnottoexceed
  })
}

export const simpleFraudVouchers = (data: any) =>
  data.filter((datum: any) => datum.totalspent > datum.amtnottoexceed)

const generateColumns = () => [
  {
    label: 'VoucherID',
    field: 'voucherid',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Issued',
    field: 'createdate',
    sort: 'asc',
    width: 150
  },
  {
    label: 'VIN',
    field: 'vinnum',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Color',
    field: 'vehcolor',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Make',
    field: 'vehmake',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Model',
    field: 'vehmodel',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Issued To',
    field: 'issuedto',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Dept',
    field: 'departmentissue',
    sort: 'asc',
    width: 150
  },
  {
    label: 'Issued $',
    field: 'amtnottoexceed',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Closed $',
    field: 'totalspent',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Diff $',
    field: 'difference',
    sort: 'asc',
    width: 100
  }
]
