import styled from 'styled-components'

export const TFoot = styled.tfoot`
  td {
    font-size: 11px;
    text-align: center;
    background-color: #fff;
    color: #fff;
    font-weight: bolder;
    background-color: #606877;
  }

  td:first-child {
    padding-left: 10px;
    text-align: center !important;
  }

  td:last-child {
    border-left: none !important;
  }
`