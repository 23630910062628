import styled, { css } from 'styled-components'

const redStyles = css`
  background-color: #fed9d1;
  color: #7f6c68;
`

const yellowStyles = css`
  background-color: #ffedc9;
  color: #89754a;
`

const greenStyles = css`
  background-color #cfebd4;
  color: #4a6650;
`

export const Container = styled.span<{ highlight: number }>`
  ${({ highlight }) => {
    switch (highlight) {
      case 1:
        return greenStyles
      case 0:
        return yellowStyles
      case -1:
        return redStyles
    }
  }}
  text-align: center;
  font-weight: bold;
  padding: 5px 12px;
  border-radius: 15px;
`
