import styled from 'styled-components'
const timing = '0.4s'
const width = '100%'

export const Container = styled.div<{ state?: string }>`
  position: fixed;
  z-index: 2000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation-name: fadeIn;
  animation-duration: ${timing};
  transition: all ${timing} ease-out;
  @keyframes fadeIn {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  background-color: ${({ state }) =>
    state === 'entering' || state === 'entered' ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0)'};
`

export const Wrapper = styled.div<{ state: string }>`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  overflow-y: auto;
  width: ${width};
  background-color: #fff;
  box-shadow: 4px 0px #ccc;
  transition: ${timing} ease-out;
  animation-name: slideIn;
  animation-duration: ${timing};
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0px);
    }
  }
  transform: translateX(
    ${({ state }) => (state === 'entering' || state === 'entered' ? '1px' : `-100%`)}
  );
`

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;

    .MuiTableCell-root {
      padding: 3px;
    }
`
