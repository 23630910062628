import React from 'react'
import { Container } from './Styled'
import { Link } from 'react-router-dom'

export const NavMenu: React.FC = () => (
  <Container>
    <ul>
      <li>
        <Link to='/reports/reconciled'>Reconciliation</Link>
      </li>
      <li>
        <Link to='/reports/recap'>Recap</Link>
      </li>
      <li>
        <Link to='/reports/fraud'>Fraud</Link>
      </li>
      <li>
        <Link to='/reports/permanent-inventory'>Permanent Inventory</Link>
      </li>
    </ul>
  </Container>
)
