import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`

export const Wrapper = styled.div`
  margin: 70px auto 50px;
  height: auto;
  width: 450px;
  position: relative;
  padding: 20px;
  z-index: 200;
  background-color: ${props => props.theme.main.light};
  border-radius: 10px;

  form {
    display: flex;
    margin: 0 auto;
    flex-direction: column;

    > div {
      margin-top: 15px;
    }

    input:disabled {
      cursor: no-drop;
      font-style: italic;
    }

    input,
    select {
      margin-bottom: 5px;
      font-size: 14px;
    }

    .invalid-feedback {
      margin: 0 0 5px 0;
    }

    input.form-control {
      padding: 0.3rem 0.5rem;
    }

    input.form-control.is-invalid {
      margin-bottom: 0;
    }

    fieldset {
      margin-bottom: 20px;
    }

    .small-legend {
      width: auto;
    }

    label {
      font-weight: bold;
      font-size: 0.9rem;
      color: ${props => props.theme.main.charcoal};
      margin: 0;
      text-align: left;

      span {
        color: red;
      }
    }

    button.disabled {
      cursor: not-allowed;
    }

    @media (max-width: ${props => props.theme.global.smMd}) {
      max-width: 100%;
    }
  }
`

export const Icon = styled.div`
  position: absolute;
  right: 30px;
  top: 68px;
  svg {
    height: 15px;
    width: 15px;
  }
`

export const CustomError = styled.div`
  margin: 0 0 5px 0 !important;
  height: 19px; 
  color: #dc3545;
  font-size: 12.8px;
`
