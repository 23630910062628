import React, { useState, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { statusFormatter } from 'data/helpers'
import { formatDate } from 'data/helpers/dates'
import { useLazyQuery } from '@apollo/client'
import { api } from 'data/apollo/api'
import { TBody, Row } from './Styled'
import { Highlight } from '../Highlight'
import { Spinner as CheckboxSpinner } from './Spinner'
import Checkbox from '@material-ui/core/Checkbox'
import { checkInitialValues } from 'data/helpers'
import { Modal } from 'core/components/Modal';
import { VoucherForm } from 'core/components/VoucherForm';

export const TR: React.FC<{
  voucher: any
  lastRow: boolean
}> = ({ voucher, lastRow }) => {
  const [expanded, setExpanded] = useState(false)
  const [reconcile] = useMutation(api.remote.mutation.editVoucher, {
    variables: {
      voucherid: voucher.voucherid,
      Reconciled: true
    },
    onError: () => {
      setButtonLoading(false)
    }
  })
  const [buttonLoading, setButtonLoading] = useState(false)
  const [fetch, { data }] = useLazyQuery(
    api.remote.query.findVouchersByIssuedTo
  )
  const [showModal, setShowModal] = useState(false)
  const [dataAfterChange, setDataAfterChange] = useState<any>(null)
  const [showCompletedModal, setCompletedModal] = useState(false)
  const origData = useRef(voucher)

  const handleClick = (type: string, data?: any) => (event: any) => {
    event.stopPropagation()
    if (type === 'id') {
      setShowModal(true)
    }
  }

  const handleModalClose = (event: any) => {
    event.stopPropagation()
    setShowModal(false)
    setCompletedModal(false)
    setDataAfterChange(null)
    origData.current = data
  }

  const handleSetReturnedData = (changedData: any) => {
    setDataAfterChange({ pre: origData.current, post: changedData })
  }

  const handleCompletedModal = () => {
    setCompletedModal(true)
  }

  const handleCheckbox = () => {
    if (voucher.Reconciled) {
      return
    }
    setButtonLoading(true)
    reconcile()
  }

  return (
    <TBody>
      <tr id={`reconciliation__tbody-row-${showModal ? 'show' : 'hidden'}`}>
        <td>
        <Modal
          isOpen={showModal}
          onClose={handleModalClose}
        >
          <VoucherForm
            initValues={checkInitialValues(voucher)}
            setReturnedData={handleSetReturnedData}
            setShowReturn={handleCompletedModal}
            handleClose={handleModalClose}
            formType='admin'
          />
        </Modal>
        </td>
      </tr>
      <Row
        key={voucher.voucherid}
        expanded={expanded}
        onClick={handleClick('row')}
        lastRow={lastRow}
      >
        <td onClick={handleClick('id', voucher)} style={voucherIdStyles}>
          <span>{voucher.voucherid}</span>
        </td>
        <td>{voucher.clientid}</td>
        <td>{statusFormatter(voucher.status)}</td>
        <td>{formatDate(voucher.createdate)}</td>
        <td>{voucher.issuedto}</td>
        <td>${currencyFormatter(voucher.amtnottoexceed)}</td>
        <td>${currencyFormatter(voucher.totalspent)}</td>
        <td>
          <Highlight
            data={{
              spent: voucher.totalspent,
              allowed: voucher.amtnottoexceed
            }}
          />
        </td>
        <td>{trimString(voucher.createdby)}</td>
        <td id='fuelops__recaps-recon-dots'>
          {buttonLoading ? (
            <CheckboxSpinner />
          ) : (
            <Checkbox
              checked={voucher.Reconciled}
              onClick={handleCheckbox}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          )}
        </td>
      </Row>
    </TBody>
  )
}

const voucherIdStyles: any = {
  color: '#788295',
  fontWeight: 'bold',
  cursor: 'pointer'
}

const trimString = (value: string) => {
  if (!value) return
  if (value.length > 10) {
    return value.substring(0, 8) + '...'
  }
  return value
}

export const currencyFormatter = (data: number | string) => {
  if (typeof data === 'string') {
    data = parseFloat(data)
  }
  const num = data.toFixed(2)
  if (+num === 0) {
    return Math.abs(+num).toFixed(2)
  }
  return num
}
