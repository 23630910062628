import React, { useState, ChangeEvent } from 'react'
import { Container, Wrapper, Options, OptionsWrapper, Button } from './Styled'
import { ReactComponent as MagGlass } from 'assets/images/magnifying-glass.svg'
import Switch from '@material-ui/core/Switch'
import { ReactComponent as OptionsIcon } from 'assets/images/options-icon.svg'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import OutsideClickHandler from 'react-outside-click-handler'

export const Header: React.FC<{
  data: any
  display: { tables: string[]; setTables: (arg: any) => void }
}> = ({ data, display: { tables, setTables } }) => {
  const { searchText, setSearchText } = data
  const [showOptions, setShowOptions] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const handleSetTables = (name: string) => () => {
    if (tables.includes(name)) {
      setTables(tables.filter((item: string) => item !== name))
    } else {
      const temp = [...tables, name]
      setTables(temp)
    }
  }

  const handleButton = (val?: boolean) => () => {
    if (val !== undefined) {
      setShowOptions(val)
    } else {
      setShowOptions(!showOptions)
    }
  }

  const createRipple = (event: any) => {
    const button = document.getElementById('my-button')
    const buttonDimensions = button?.getBoundingClientRect()
    const circle = document.createElement('div')
    button!.appendChild(circle)
    const d = Math.max(button!.clientWidth, button!.clientHeight)
    circle.style.width = circle.style.height = d + 'px'
    circle.style.left = event.clientX - buttonDimensions!.left - (d / 2) + 'px'
    circle.style.top = event.clientY - buttonDimensions!.top - (d / 2) + 'px'
    circle.classList.add('ripple')
    setTimeout(() => {
      button!.removeChild(circle)
    }, 600)
  }

  return (
    <Container>
      <Wrapper active={!!searchText}>
        <OutsideClickHandler
          display='contents'
          onOutsideClick={handleButton(false)}
        >
          <OptionsWrapper>
            <Button id='my-button' onClick={(event: any) => {
              handleButton()()
              createRipple(event)
            }}>
              Options
              <OptionsIcon />
            </Button>
            {showOptions && (
              <Options>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>
                    Show tables grouped by:
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tables.includes('createdby')}
                          onChange={handleSetTables('createdby')}
                          name='createdby'
                        />
                      }
                      label='User'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tables.includes('departmentissue')}
                          onChange={handleSetTables('departmentissue')}
                          name='departmentissue'
                        />
                      }
                      label='Department'
                    />
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={tables.includes('vehmake')}
                          onChange={handleSetTables('vehmake')}
                          name='createdby'
                        />
                      }
                      label='Vehicle Make'
                    /> */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tables.includes('stknum')}
                          onChange={handleSetTables('stknum')}
                          name='stknum'
                        />
                      }
                      label='Stock Number'
                    />
                  </FormGroup>
                  {/* <FormHelperText>Roll Tide</FormHelperText> */}
                </FormControl>
              </Options>
            )}
          </OptionsWrapper>
        </OutsideClickHandler>
        <div id='fuelops__recap-recon-search'>
          <label htmlFor='reconciliation__searchtext'>
            <MagGlass />
          </label>
          <input
            type='text'
            id='reconciliation__searchtext'
            value={searchText}
            onChange={handleChange}
            placeholder='Search vouchers'
          />
        </div>
      </Wrapper>
    </Container>
  )
}
