import styled from 'styled-components'

export const Button = styled.button`
  {
    background-color: transparent;
    border: 0;
    color: #000;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    opacity: .5;
    position: absolute;
    right: 3px;
    text-shadow: none;
    top: 0;
    padding: 0.5rem;
  }
`