import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from 'styled-components'
import { client } from 'data/apollo'
import { ApolloProvider } from '@apollo/client'
import { store } from 'data/store/index'
import { theme } from './theme'

ReactDOM.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router basename={process.env.REACT_APP_BASENAME}>
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
