import { createStore, applyMiddleware } from 'redux'
import { rootReducer } from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import { all } from 'redux-saga/effects'
import createSagaMiddleware from 'redux-saga'
import vouchersSaga from 'data/store/vouchers/sagas'
import usersSaga from 'data/store/users/sagas'

const sagaMiddleware = createSagaMiddleware()

const rootSaga = function* () {
  yield all([vouchersSaga(), usersSaga()])
}

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
)
sagaMiddleware.run(rootSaga)
