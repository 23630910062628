import React from 'react'
import { TBody, Row } from './Styled'
import { IData, IForm } from '../'

export const TR: React.FC<{ data: IData; lastRow: boolean; form: IForm }> = ({
  data,
  lastRow,
  form: { setFormData, setShowForm }
}) => {
  const { location, year, make, model, stknum, vinnum, description } = data
  return (
    <TBody>
      <Row lastRow={lastRow}>
        <td
          onClick={() => {
            setFormData(data)
            setShowForm(true)
          }}
        >
          Edit
        </td>
        <td>{location}</td>
        <td>{year}</td>
        <td>{make}</td>
        <td>{model}</td>
        <td>{stknum}</td>
        <td>{vinnum}</td>
        <td>{description}</td>
      </Row>
    </TBody>
  )
}
