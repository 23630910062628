import gql from 'graphql-tag'

export const api = {
  local: {
  },
  remote: {
    query: {
      allVouchers: gql`
        query AllVouchers {
          allVouchers {
            voucherid
            vinnum
            createdate
            createdby
            dollarsallowed
            totalspent
            status
            stknum
            issuedto
            amtnottoexceed
            vehyr
            vehmake
            vehmodel
            vehcolor
            fillup
            departmentissue
          }
        }
      `,
      doesStknumExist: gql`
        query GetPermanentStock($clientid: String!, $cids: [String!]!, $stknum: String) {
          permanentStock(clientid: $clientid, cids: $cids, stknum: $stknum) {
            ClientID
            stknum
            vinnum
            year
            make
            model
            color
            description
          }
        }
      `,
      duplicateVouchers: gql`
        query GetDuplicateVouchers(
          $clientID: String!
          $startDate: String!
          $endDate: String!
        ) {
          duplicateVouchers(
            clientID: $clientID
            startDate: $startDate
            endDate: $endDate
          ) {
            voucherid
            stknum
            vinnum
            duplicates
          }
        }
      `,
      dealerInfo: gql`
        query GetDealerInfo($clientid: String!) {
          dealerInfo(clientid: $clientid) {
            idsid
            dealername
            address1
            city
            state
            zip
            dirphone
          }
        }
      `,
      permanentStock: gql`
        query GetPermanentStock($cids: [String!]!) {
          permanentStock(cids: $cids) {
            ClientID
            stknum
            vinnum
            year
            make
            model
            color
            description
          }
        }
      `,
      activeUsers: gql`
        query ActiveUsers($corpDB: String!, $customerStatus: Float!) {
          activeUsers(corpDB: $corpDB, customerStatus: $customerStatus) {
            usertype
            Expr1
            UserName
            ClientID
            GroupNum
            GroupName
            Name
            Email
            StoreName
            UserID
            Phone
            FirstName
            LastName
            CreateDate
            FTUser
            Type
            UserAccess
            storeaccess
            dealerID
            MostRecentLogin
            DealerName
            CorpDB
          }
        }
      `,
      findVoucher: gql`
        query FindVoucher($voucherid: String!) {
          getVoucher(input: { voucherid: $voucherid }) {
            voucherid
          }
        }
      `,
      findPastRecords: gql`
        query GetPastRecords(
          $clientid: String!
          $vinnum: String!
        ) {
          getPastRecords(
            clientid: $clientid
            vinnum: $vinnum
          ) {
            voucherid
            createdby
            createdate
            issuedto
            vinnum
            amtnottoexceed
            fillup
          }
        }
      `,
      findVouchersByIssuedTo: gql`
        query FindVouchersIssuedTo($id: String!) {
          findVouchersByIssuedTo(id: $id) {
            voucherid
            vinnum
            createdate
            createdby
            dollarsallowed
            totalspent
            checked @client
            status
            Reconciled
            stknum
            completedby
            issuedto
            amtnottoexceed
            vehyr
            vehmake
            vehmodel
            vehcolor
            fillup
            departmentissue
            Reconciled
          }
        }
      `,
      vouchersInRange: gql`
        query GetVouchersInRange(
          $startDate: String!
          $endDate: String!
          $cids: [String!]!
        ) {
          vouchersInRange(
            startDate: $startDate
            endDate: $endDate
            cids: $cids
          ) {
            voucherid
            clientid
            vinnum
            createdate
            createdby
            dollarsallowed
            totalspent
            checked @client
            status
            Reconciled
            stknum
            issuedto
            amtnottoexceed
            vehyr
            vehmake
            vehmodel
            vehcolor
            fillup
            completedby
            changedby
            changeddate
            departmentissue
            otherreason
            Reconciled
          }
        }
      `,
      adminRecap: gql`
        query RecapVouchersInRange(
          $startDate: String!
          $endDate: String!
          $clientid: String!
        ) {
          vouchersInRange(
            startDate: $startDate
            endDate: $endDate
            clientid: $clientid
          ) {
            voucherid
            vinnum
            createdate
            createdby
            dollarsallowed
            totalspent
            status
            Reconciled
            stknum
            issuedto
            amtnottoexceed
            vehyr
            vehmake
            vehmodel
            vehcolor
            fillup
            departmentissue
            Reconciled
          }
        }
      `,
      makes: gql`
        query GetVehicleMakes {
          makes {
            MakeID
            Make
          }
        }
      `,
      getDealers: gql`
        query GetDealers(
          $cids: [String!]!
        ) {
          dealers(
            cids: $cids
          ) {
            idsid
            dealername
          }
        }
      `,
      searchVIorROorFTStock: gql`
        query SearchVIorROorFTStock($id: String, $cids: [String!]!, $corpdb: String!) {
          data: searchVIorRO(id: $id, cids: $cids, corpdb: $corpdb) {
            ... on ServiceOpenRO {
              __typename
              RepairOrderNumber
              uid
              VIN
              ModelYear
              Make
              Model
              Color
              clientid
            }
            ... on VehicleInventory {
              __typename
              StockNumber
              uid
              VIN
              ModelYear
              Make
              Model
              Color
              TypeNU
              clientid
            }
            ... on FuelTrackerStock {
              __typename
              stknum
              vinnum
              year
              make
              model
              color
              description
              deleted
              ClientID
            }
          }
        }
      `,
      login: gql`
        query Login($username: String!, $password: String!) {
          login(username: $username, password: $password) {
            accessToken
            refreshToken
          }
        }
      `,
      refreshToken: gql`
        query RefreshToken($refreshToken: String!) {
          token(refreshToken: $refreshToken) {
            accessToken
            refreshToken
          }
        }
      `
    },
    mutation: {
      createUser: gql`
        mutation AdminCreateUser(
          $adminUserName: String!
          $FirstName: String!
          $LastName: String!
          $Email: String!
          $Phone: String!
          $GroupNum: String!
          $DMS: String!
          $FTUserType: String!
          $UserAccess: String!
        ) {
          createUser(
            input: {
              adminUserName: $adminUserName
              FirstName: $FirstName
              LastName: $LastName
              Email: $Email
              Phone: $Phone
              GroupNum: $GroupNum
              DMS: $DMS
              FTUserType: $FTUserType
              UserAccess: $UserAccess
            }
          ) {
            UserName
            Name
            Phone
            GroupNum
            FTUserType
          }
        }
      `,
      addPermanentStock: gql`
        mutation CreatePermanentInventory(
          $stknum: String!
          $vinnum: String!
          $year: String!
          $make: String!
          $model: String!
          $color: String!
          $description: String!
          $ClientID: String!
        ) {
          addPermanentStock(
            input: {
              stknum: $stknum
              vinnum: $vinnum
              year: $year
              make: $make
              model: $model
              color: $color
              description: $description
              ClientID: $ClientID
            }
          ) {
            ClientID
            stknum
            vinnum
            year
            make
            model
            color
            description
          }
        }
      `,
      editPermanentStock: gql`
        mutation EditPermanentStock(
          $stknum: String
          $vinnum: String
          $year: String
          $make: String
          $model: String
          $color: String
          $description: String
          $ClientID: String!
        ) {
          editPermanentStock(
            input: {
              stknum: $stknum
              vinnum: $vinnum
              year: $year
              make: $make
              model: $model
              color: $color
              description: $description
              ClientID: $ClientID
            }
          ) {
            stknum
            vinnum
            year
            make
            model
            color
            description
            ClientID
          }
        }
      `,
      createVoucher: gql`
        mutation CreateVoucher(
          $stknum: String!
          $amtnottoexceed: Float
          $vehmake: String!
          $vehyr: String!
          $vehmodel: String!
          $vehcolor: String!
          $fillup: Boolean!
          $clientid: String!
          $vinnum: String!
          $otherreason: String
          $issuedto: String!
          $changedby: String!
          $createdby: String!
          $departmentissue: String!
        ) {
          createVoucher(
            input: {
              stknum: $stknum
              amtnottoexceed: $amtnottoexceed
              vehmake: $vehmake
              vehyr: $vehyr
              clientid: $clientid
              vehmodel: $vehmodel
              vehcolor: $vehcolor
              vinnum: $vinnum
              fillup: $fillup
              otherreason: $otherreason
              issuedto: $issuedto
              changedby: $changedby
              createdby: $createdby
              departmentissue: $departmentissue
            }
          ) {
            voucherid
            createdate
            totalspent
            status
            stknum
            amtnottoexceed
            vehmake
            vehyr
            vehmodel
            vehcolor
            issuedto
            fillup
            departmentissue
            otherreason
            vinnum
            clientid
          }
        }
      `,
      closeVoucher: gql`
        mutation CloseVoucher(
          $voucherid: String!
          $totalspent: Float!
          $completedby: String!
        ) {
          closeVoucher(
            input: {
              voucherid: $voucherid
              totalspent: $totalspent
              completedby: $completedby
            }
          ) {
            voucherid
            createdate
            dollarsallowed
            totalspent
            status
            stknum
            amtnottoexceed
            vehmake
            vehyr
            vehmodel
            vehcolor
            issuedto
            fillup
            departmentissue
            vinnum
          }
        }
      `,
      setVoucherPrinted: gql`
        mutation SetVoucherPrinter(
          $voucherid: String!
          $VoucherPrinted: Boolean!
          $VoucherSignature: String!
        ) {
          editVoucher(
            input: {
              voucherid: $voucherid
              VoucherPrinted: $VoucherPrinted
              VoucherSignature: $VoucherSignature
            }
          ) {
            voucherid
            VoucherPrinted
          }
        }
      `,
      editVoucher: gql`
        mutation AdminEditVoucher(
          $voucherid: String!
          $createdate: String
          $totalspent: Float
          $status: String
          $stknum: String
          $amtnottoexceed: Float
          $vehmake: String
          $vehyr: String
          $vehmodel: String
          $vehcolor: String
          $issuedto: String
          $fillup: Boolean
          $vinnum: String
          $departmentissue: String
          $otherreason: String
          $Reconciled: Boolean
          $changedby: String
        ) {
          editVoucher(
            input: {
              voucherid: $voucherid
              createdate: $createdate
              totalspent: $totalspent
              status: $status
              stknum: $stknum
              amtnottoexceed: $amtnottoexceed
              vehmake: $vehmake
              vehyr: $vehyr
              vehmodel: $vehmodel
              vehcolor: $vehcolor
              issuedto: $issuedto
              fillup: $fillup
              vinnum: $vinnum
              otherreason: $otherreason
              departmentissue: $departmentissue
              Reconciled: $Reconciled
              changedby: $changedby
            }
          ) {
            voucherid
            createdate
            totalspent
            status
            stknum
            amtnottoexceed
            vehmake
            vehyr
            vehmodel
            vehcolor
            issuedto
            fillup
            departmentissue
            vinnum
            otherreason
            Reconciled
            changedby
          }
        }
      `
    }
  }
}
