import styled from 'styled-components'

export const Container = styled.div``

export const Wrapper = styled.div`
  padding: 0 20px 0 40px;
  min-height: calc(100vh - 45px);
  background: rgb(9, 9, 121);
  font-family: Montserrat, sans-serif;
  background: linear-gradient(
    180deg,
    #384b60 200px,
    rgba(255, 255, 255, 1) 200px
  );
`
