import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  margin-top: 20px;
  grid-template-columns: 20% 30% 45%;
  box-sizing: border-box;
  grid-gap: 25px;
  width: 100%;
  align-items: flex-end;

  .box-1 {
    grid-column: 1;
    grid-row: 1;
    font-weight: bolder;
  }

  .box-2 {
    grid-column: 2;
    grid-row: 1;
    font-weight: bold;
    font-size: 1.5em;
  }

  .box-3 {
    grid-column: 3;
    grid-row: 1;
    font-size: 0.9em;
    font-weight: bold;
  }

  .box-4 {
    grid-column: 1;
    grid-row: 2;
    font-weight: bolder;
  }

  .box-5 {
    border-bottom: #555 2px dashed;
    grid-column: 2 / 4;
    grid-row: 2;
    margin-right: 20px;
    position: relative;

    &:after {
      color: #555;
      content: 'Not Valid Unless Signed By Authorizing Manager';
      font-size: 12px;
      position: absolute;
      text-align: center;
      display: block;
      font-weight: bold;
      bottom: -20px;
      width: 100%;
    }
  }

  .box-6 {
    grid-column: 1 / 3;
    grid-row: 3;
    font-weight: bolder;
  }
`
