import React from 'react'
import { Container, Wrapper } from './Styled'
import { Options } from './Options'
import { MDBInput } from 'mdbreact'

export const Header: React.FC<{
  setSearchText: any
  searchText: string
  setHideColumns: any
  hideColumns: boolean
  showOnlySelected: boolean
  setShowOnlySelected: any
  paginate: boolean
  setPaginate: any
}> = ({
  setSearchText,
  searchText,
  setHideColumns,
  hideColumns,
  showOnlySelected,
  setShowOnlySelected,
  paginate,
  setPaginate
}) => (
  <Container>
    <Wrapper>
      <MDBInput
        value={searchText}
        onChange={(e: any) => setSearchText(e.target.value)}
        label='Filter...'
      />
      <Options
        setHideColumns={setHideColumns}
        hideColumns={hideColumns}
        showOnlySelected={showOnlySelected}
        setShowOnlySelected={setShowOnlySelected}
        paginate={paginate}
        setPaginate={setPaginate}
      />
    </Wrapper>
  </Container>
)
