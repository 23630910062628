import {
  SET_USER,
  UNSET_USER,
  SHOW_SIGN_IN,
  HIDE_SIGN_IN,
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE
} from './actionTypes'

const initialState = {
  user: null,
  showSignIn: false,
  modal: {
    modalType: '',
    image: ''
  },
  error: null,
  users: []
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SIGN_IN:
      return {
        ...state,
        showSignIn: true,
        modal: action.payload
      }
    case HIDE_SIGN_IN:
      return {
        ...state,
        showSignIn: false,
        modal: {
          modalType: '',
          image: ''
        }
      }
    case SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case UNSET_USER:
      return {
        ...state,
        user: null
      }
    case FETCH_USERS_START:
      return {
        ...state,
        isFetching: true,
        error: null
      }
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: action.payload.users
      }
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      }
    default:
      return state
  }
}
