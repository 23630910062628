import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { api } from 'data/apollo/api'
import { Reconciled } from './Reconciled'
import { Recaps } from './Recaps'
import { Fraud } from './Fraud'
import { Permanent } from './Permanent'

export const Reports: React.FC<{ dealers: [any], dateRange: any, setDateRange: any }> = ({dealers, dateRange, setDateRange}) => {
  const { path } = useRouteMatch()
  const client = useApolloClient()
  client.query({ query: api.remote.query.makes })
  
  return (
    <Switch>
      <Route path={`${path}/reconciled`} render={() => (<Reconciled dealers={dealers} dateRange={dateRange} setDateRange={setDateRange} />)} />
      <Route path={`${path}/recap`} render={() => (<Recaps dealers={dealers} dateRange={dateRange} setDateRange={setDateRange} />)} />
      <Route path={`${path}/fraud`} render={() => (<Fraud dealers={dealers} dateRange={dateRange} setDateRange={setDateRange} />)} />
      <Route path={`${path}/permanent-inventory`} render={() => (<Permanent dealers={dealers} />)} />
    </Switch>
  )
}
