import React from 'react'
import styled from 'styled-components'

const Container = styled.div<{ position?: { top?: string; left?: string } }>`
  position: relative;
  left: ${props => props.position?.left || '0'};
  top: ${props => props.position?.top || '0'};
  font-weight: lighter;
  height: 25px;
  color: #000;
  max-width: 200px;

  #card__title-span {
    font-size: 14px;
  }

  text-transform: uppercase;
`

const Underline = styled.div`
  position: absolute;
  bottom: 0;
  left: 1px;
  width: 28px;
  height: 2px;
  border-radius: 10px;
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.global.foBlueGray};
`

interface IProps {
  title?: string
  position?: {
    top?: string
    left?: string
  }
}

export const Title: React.FC<IProps> = ({ title, position }) => (
  <Container position={position}>
    <span id='card__title-span'>{title}</span>
    <Underline />
  </Container>
)
