import styled from 'styled-components'

export const Container = styled.div<{ state: any; translate: any }>`
  position: relative;
  background-color: #fff;
  transition: all 0.2s ease-in;
  ${({ state, translate }) => {
    switch (state) {
      case 'exiting':
      case 'entering':
        return `transform: translate(${translate.x}px, ${translate.y}px) scale(0.5); opacity: 0`
    }
  }};
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086);
`

export const CardInfo = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Image = styled.div`
  position: absolute;
  top: 2px;
  right: 10px;
  width: 30px;
  height: 30px;
  z-index: 1000;

  svg {
    height: 15px;
    fill: #fff;
  }
`

export const Wrapper = styled.div<{ minimize?: boolean }>`
  font-size: 15px;
  color: #55585e;
  font-weight: bolder;
  height: 128px;

  > div {
    margin-bottom: 5px;
  }
`
