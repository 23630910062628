import * as actionTypes from './actionTypes'

export const fetchVouchersStart = payload => {
  return {
    type: actionTypes.FETCH_VOUCHERS_START,
    payload
  }
}

export const fetchVouchersSuccess = payload => ({
  type: actionTypes.FETCH_VOUCHERS_SUCCESS,
  payload
})

export const fetchVouchersUpdate = payload => ({
  type: actionTypes.FETCH_VOUCHERS_UPDATE,
  payload
})

export const fetchVouchersEdit = payload => ({
  type: actionTypes.FETCH_VOUCHERS_EDIT,
  payload
})

export const fetchVouchersClose = payload => ({
  type: actionTypes.FETCH_VOUCHERS_CLOSE,
  payload
})

export const fetchVouchersDelete = payload => ({
  type: actionTypes.FETCH_VOUCHERS_DELETE,
  payload
})

export const fetchVouchersFailure = payload => ({
  type: actionTypes.FETCH_VOUCHERS_FAILURE,
  payload
})

export const fetchVouchersLoading = () => ({
  type: actionTypes.FETCH_VOUCHERS_LOADING
})

export const fetchMakesStart = () => ({
  type: actionTypes.FETCH_MAKES_START
})

export const fetchMakesSuccess = payload => ({
  type: actionTypes.FETCH_MAKES_SUCCESS,
  payload
})

export const fetchMakesFailure = payload => ({
  type: actionTypes.FETCH_MAKES_FAILURE,
  payload
})
