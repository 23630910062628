import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  div {
    margin-bottom: 10px;
  }
  
  img {
    width: 200px;
    height: auto;
  }
`
