import styled from 'styled-components'

export const Wrapper = styled.div`
/*
margin: 50px auto;
  height: auto;
  width: 450px;
  padding: 20px;
  position: relative;
  z-index: 200;
  background-color: ${props => props.theme.main.light};
  border-radius: 10px;
*/

  form {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
  
    > div {
      margin-top: 15px;
    }

    input:disabled {
      cursor: no-drop;
      font-style: italic;
    }

    input, select {
      margin-bottom: 5px;
    }

    .invalid-feedback {
      margin: 0 0 5px 0;
    }

    input.form-control.is-invalid {
      margin-bottom: 0;
    }

    fieldset {
      margin-bottom: 20px;
    }

    .small-legend {
      width: auto;
    }
      
    label {
      font-weight: bold;
      font-size: 0.9rem;
      color: ${props => props.theme.main.charcoal};
      margin: 0;

      span {
        color: red;
      }
    }

    @media (max-width: ${props => props.theme.global.smMd}) {
      max-width: 100%;
    }
  }
`

export const FormActions = styled.span`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  button {
    margin: 0 10px;
  }
`
