import React from 'react'
import store from 'store'
import { Container } from './Styled'
import { ReactComponent as DArrow } from 'assets/images/down-arrow.svg'
import ProfilePic from 'assets/images/profiledemo1.jpg'

export const User: React.FC = () => {
  const user = store.get('foAccess')
  const payload = user?.split('.')[1]
  let username
  if (payload) {
    const buffer = Buffer.from(payload, 'base64')
    username = JSON.parse(buffer.toString('utf-8')).username
  }
  return (
    <Container>
      <span>{username.toUpperCase()}</span>
      {/* <DArrow /> */}
      <div />
      {/* <div id='fo__header-profile-container'>
        <img src={ProfilePic} alt='Profile Headshot' />
      </div> */}
    </Container>
  )
}
