import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  color: #fff;
  padding-top: 20px;
  max-width: 800px;
  justify-content: space-between;
  
  h4 {
    color: #fff;
  }
`
