import styled from 'styled-components'
const bColor: any = '#eef1f7'

export const Row = styled.tr<{ expanded?: boolean }>`
  height: 40px;
  font-size: 12px;
  vertical-align: top;
  color: #55585e;

  td:first-child {
    padding-left: 15px;
    border-left: 2px solid ${bColor};
  }

  td:last-child {
    text-align: center;
    border-right: 2px solid ${bColor};
    border-bottom: 2px solid ${props => (props.expanded ? '#f7fbff' : bColor)};
    border-left: ${props => (props.expanded ? `2px solid ${bColor}` : 'none')};
    cursor: pointer;
    background-color: ${props => (props.expanded ? '#f7fbff' : 'initial')};
  }

  td {
    border-bottom: 2px solid ${bColor};
    padding: 10px 5px 4px;
    font-weight: 600;
    width: 90px;
  }

  td:first-child {
    width: 150px;
  }

  td:last-child {
    width: 40px;
  }

  #fuelops__table-borderdiv {
    height: 70%;
    width: 2px;
  }

  #fuelops__recaps-recon-dots {
    cursor: pointer;
    padding: 0;
    svg {
      fill: #b6b7bd;
      height: 20px;
      width: 20px;
    }
  }

  #fuelops__recaps-recon-dots:hover {
    svg {
      fill: #5d6066;
    }
  }
`

export const TBody = styled.tbody`
  #reconciliation__tbody-row-hidden {
    display: default;
  }
`

export const TR2 = styled.tr<{ expanded?: boolean }>`
  background-color: #f7fbff;

  vertical-align: top;

  td {
    border-bottom: 2px solid ${bColor};
    color: #55585e;
  }

  td:first-child {
    border-left: 2px solid ${bColor};
  }

  td:last-child {
    border-right: 2px solid ${bColor};
  }

  #fuelops__recap-pagination-wrapper {
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 100px;
    height: 30px;
    
    .paginate-active {
      transition: all 0.4s ease-out;
      background-color: #788295;
      border-radius: 10px;
      font-size: 12px;
      padding-top: 1.4px;
      color: #fff;
    }

    .pagination {
      font-family: Montserrat;
      font-weight: bolder;
      font-size: 14px;
      color: #55585e;

      a {
        display: inline-block;
        outline: none;
        width: 100%;
      }

      > li {
        width: 20px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  #reconciliation__row-spinner-loading {
    text-align: center;
    padding: 15px;
    svg {
      width: 75px;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    overflow-wrap: break-word;
  }
`

export const NestedTableWrapper = styled.td<{ paginated: boolean }>`
  padding: 10px;
  height: ${props => props.paginated ? '345px' : 'auto'};
  position: relative;
`