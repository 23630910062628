import React from 'react'
import { Container } from './Styled'
import { ReactComponent as FilledArrow } from 'assets/images/filled-arrow.svg'

export const ArrowBox: React.FC<{ config: any; headerName: string }> = ({
  config,
  headerName
}) => {
  return (
    <Container>
      {config.order === 'asc' ? (
        <FilledArrow transform='rotate(-90)' />
      ) : (
        <FilledArrow transform='rotate(90)' />
      )}
    </Container>
  )
}
