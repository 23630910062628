import React, { useState, useEffect, useRef } from 'react'
import { initialValues } from 'data/schema'
import { debounce } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { api } from 'data/apollo/api'
import { Popup } from './Popup'
import { handleMakes } from 'data/helpers/form';
import { formatYear } from 'data/helpers/dates';
import { Container } from './Styled';
import { getTokenDetails } from 'data/helpers/token';

import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Radio, Table, Tag } from 'antd';

export const SearchResults = ({
  dealers,
  setFormType,
  setInitValues
}) => {
  const userAccess= getTokenDetails('userAccess');
  const corpdb = getTokenDetails('corpdb');
  const inputEl = useRef(null);
  const [tableType, setTableType] = useState('');
  
  const [searchResults, setSearchResults] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  
  const [search, { loading, called, data: qData, error }] = useLazyQuery(
    api.remote.query.searchVIorROorFTStock
  );
  
  const [popup, setPopup] = useState({
    show: false,
    data: null,
    event: null,
    type: ''
  });
  
  const handleForm = ({
    clientid,
    StockNumber,
    VIN,
    ModelYear,
    Make,
    Model,
    Color,
    DepartmentIssue
  }) => {
    setInitValues({
      gallonsallowed: '',
      amtnottoexceed: '',
      stknum: StockNumber ?? '',
      issuedto: '',
      departmentissue: DepartmentIssue ?? '',
      vinnum: VIN ?? '',
      vehyr: ModelYear ? formatYear(ModelYear) : '',
      vehmake: Make ? handleMakes(Make) : 'Other',
      vehmodel: Model ?? '',
      vehcolor: Color ?? '',
      fillup: false,
      clientid: clientid,
      location: dealers[clientid.toLowerCase()]
    });
    setFormType('create-existing');
  };
  
  useEffect(() => {
    if (!popup.show) setSelectedRow(null);
  }, [popup]);
  
  useEffect(() => {
    if (qData?.data) {
      const { data } = qData;
      
      const result = data.reduce(
        (acc, curr, index) => {
          if (curr.__typename === 'VehicleInventory') {
            let { clientid, StockNumber, VIN, Make, Model, ModelYear, Color, TypeNU } = curr;
            
            const obj = {
              key: index,
              clientid: clientid.toLowerCase(),
              location: dealers[clientid.toLowerCase()],
              type: TypeNU,
              vin: VIN,
              stock: StockNumber,
              year: ModelYear ? formatYear(ModelYear) : '',
              make: Make,
              model: Model,
              color: Color
            };
            
            acc.VehicleInventory.push(obj);
          }
          
          if (curr.__typename === 'ServiceOpenRO') {
            let {
              clientid, RepairOrderNumber: StockNumber, VIN, Make, Model, ModelYear, Color
            } = curr;
            
            const obj = {
              key: index,
              clientid: clientid.toLowerCase(),
              location: dealers[clientid.toLowerCase()],
              vin: VIN,
              stock: StockNumber,
              year: ModelYear ? formatYear(ModelYear) : '',
              make: Make,
              model: Model,
              color: Color,
            };
            
            acc.ServiceOpenRO.push(obj);
          }
          
          if (curr.__typename === 'FuelTrackerStock') {
            let {
              ClientID: clientid,
              vinnum: VIN,
              make: Make,
              model: Model,
              year: ModelYear,
              color: Color,
              description: Description,
              stknum: StockNumber
            } = curr;
            
            const obj = {
              key: index,
              clientid: clientid.toLowerCase(),
              location: dealers[clientid.toLowerCase()],
              vin: VIN,
              stock: StockNumber,
              year: ModelYear ? formatYear(ModelYear) : '',
              make: Make,
              model: Model,
              color: Color,
              description: Description,
            };
            
            acc.FuelTrackerStock.push(obj);
          }
          
          return acc;
        },
        {
          VehicleInventory: [],
          ServiceOpenRO: [],
          FuelTrackerStock: []
        }
      );
      
      if (result.VehicleInventory.length === 0) delete result.VehicleInventory;
      if (result.ServiceOpenRO.length === 0) delete result.ServiceOpenRO;
      if (result.FuelTrackerStock.length === 0) delete result.FuelTrackerStock;
      
      const textSorter = (a, b) => {
        const aLocal = (a === null ? '' : a.toUpperCase());
        const bLocal = (b === null ? '' : b.toUpperCase());
        
        if (aLocal > bLocal) return 1;
        if (aLocal < bLocal) return -1;
        return 0;
      };
      
      
      const editedDataSet = {};
      Object.keys(result).forEach(key => {
        editedDataSet[key] = {
          columns: [],
          data: result[key]
        };
        
        // Base column definitions
        let columns = [
          {
            title: 'Location',
            dataIndex: 'location',
            sorter: (a, b) => textSorter(a.location, b.location),
          },
          {
            title: 'VIN',
            dataIndex: 'vin',
            sorter: (a, b) => textSorter(a.vin, b.vin),
          },
          {
            title: 'Stock #',
            dataIndex: 'stock',
            sorter: (a, b) => textSorter(a.stock, b.stock),
          },
          {
            title: 'Year',
            dataIndex: 'year',
            sorter: (a, b) => textSorter(a.year, b.year),
          },
          {
            title: 'Make',
            dataIndex: 'make',
            sorter: (a, b) => textSorter(a.make, b.make),
          },
          {
            title: 'Model',
            dataIndex: 'model',
            sorter: (a, b) => textSorter(a.model, b.model),
          },
          {
            title: 'Color',
            dataIndex: 'color',
            render: color => (
              <span title={color}>
                {
                  (color && color.length > 6) ? color.substring(0, 6).trim() + '...' : color
                }
              </span>
            ),
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => textSorter(a.color, b.color),
          },
        ];
        
        if (key === 'VehicleInventory') {
          columns.splice(1, 0, {
            title: 'Type',
            align: 'center',
            dataIndex: 'type',
            filters: [
              {
                text: 'New',
                value: 'n',
              },
              {
                text: 'Used',
                value: 'u',
              }
            ],
            filterMultiple: false,
            onFilter: (value, record) => record.type.toLowerCase() === value.toLowerCase(),
            render: type => (
              <span>
                <Tag color={type.toLowerCase() === 'u' ? 'geekblue' : 'green'} key={type}>
                  {type.toLowerCase() === 'u' ? 'Used' : 'New'}
                </Tag>
              </span>
            ),
            sorter: (a, b) => textSorter(a.type, b.type)
          });
        }
        
        editedDataSet[key].columns = columns;
      });
      
      
      const keys = Object.keys(editedDataSet);
      setTableType(keys[0] || '');
      setSearchResults(editedDataSet);
    }
  }, [qData]);
  
  const searchStockOrVin = async e => {
    const { value } = e.target;
    if (value.length < 4) {
      return;
    }
    
    try {
      search({
        variables: {
          id: value,
          cids: userAccess,
          corpdb
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  };
  const debouncedSearchText = debounce(searchStockOrVin, 300);
  
  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.focus()
    }
  }, []);
  
  const handleChange = e => {
    e.persist()
    debouncedSearchText(e)
  };
  
  const handleTableName = name => {
    switch (name) {
      case 'VehicleInventory':
        return 'Vehicle Inventory'
      case 'ServiceOpenRO':
        return 'Repair Orders'
      case 'FuelTrackerStock':
        return 'Permanent Stock'
      default:
        return ''
    }
  };
  
  const handleClick = (event, type, record) => {
    // React "event pooling" BS
    // This is depricated in React 17+
    event.persist();
    
    setPopup({ show: true, data: record, event: event, type });
  };
  
  
  const viLength = searchResults.VehicleInventory?.data.length;
  const roLength = searchResults.ServiceOpenRO?.data.length;
  const ftStockLength = searchResults.FuelTrackerStock?.data.length;
  
  
  
  const LoadingIndicator = ({loading}) => {
    return (
      loading ? (
        <LoadingOutlined style={{color:'#ababab'}} />
      ):
      null
    );
  };
  
  return (
    <div style={{textAlign: 'center'}}>
      {error && <Alert style={{width: '60%', margin: '0 auto 1em auto'}} message="Error fetching search results" type="error" showIcon />}
      
      <label htmlFor='vehicleSearch' style={{ display:'block' }}>
        Enter Stock# or VIN
        <br/><small><i>Four character minimum</i></small>
      </label>
      <Input 
        autoComplete='false'
        id='vehicleSearch'
        onChange={handleChange}
        placeholder='Search...'
        ref={inputEl}
        size='large'
        spellCheck='false'
        style={{ width: 250 }}
        suffix={<LoadingIndicator loading={loading}/>}
      />
      
      <Container>
      {!loading && !error && called && !viLength && !roLength && !ftStockLength && (
        <div id='found-search-results'>
          <h5>Could not find any results. Create a ticket manually?</h5>
          <span>
            <Button
              onClick={() => {
                setInitValues(initialValues)
                setFormType('create-new')
              }}
              type='primary'
            >
              Ok
            </Button>
          </span>
        </div>
      )}
      </Container>
      
      {!loading && (viLength || roLength || ftStockLength) ? (
        <>
          <Radio.Group
            buttonStyle='solid'
            className='tab-like'
            onChange={(event) => {setTableType(event.target.value)}}
            optionType='button'
            value={tableType}
          >
            {Object.keys(searchResults).map(resultSet => (
              <Radio.Button
                key={resultSet}
                value={resultSet}
              >
                {handleTableName(resultSet)}
              </Radio.Button>
            ))}
          </Radio.Group>
          
          <div style={{position: 'relative'}}>
          {popup.show && (
            <Popup {...popup} setPopup={setPopup} handleForm={handleForm} />
          )}
          <Table
            className='search-results'
            columns={searchResults[tableType].columns}
            dataSource={searchResults[tableType].data}
            onRow={(record) => {
              return {
                onClick: (event) => {
                  setSelectedRow(record.key);
                  handleClick(event, tableType, record);
                },
              };
            }}
            pagination={{
              showTotal: (total, range) => range[0] + ' to ' + range[1] + ' of ' + total,
              size: 'small',
              total: searchResults[tableType].data.length
            }}
            rowClassName={(record) => (selectedRow !== null && selectedRow === record.key) ? 'selected' : null}
            size='small'
          />
          </div>
        </>
      ) : 
      null}
    </div>
  )
}
