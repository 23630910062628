import React from 'react'
import { Container } from './Styled'
import Button from '@material-ui/core/Button'

export const Header: React.FC<{
  setShowForm: (param: boolean) => void
}> = ({ setShowForm }) => {
  return (
    <Container>
      <h4>Permanent Stock Inventory</h4>
      <Button
        variant='contained'
        color='secondary'
        onClick={() => setShowForm(true)}
      >
        Create
      </Button>
    </Container>
  )
}
