import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px;
  table,
  th,
  td {
    border: 2px solid #ccc;
    border-collapse: collapse;
    font-size: 14px;
  }

  td:nth-child(1) {
    min-width: 200px;
  }

  .header-row {
    background-color: #2c3e50;
    color: #fff;
  }

  .pending,
  .closed,
  .total,
  .footer {
    text-align: center;
    padding: 0 20px;
  }

  .pending {
  }

  .closed {
  }

  .total {
  }

  .highlight {
    font-weight: bold;
  }
`
