import React from 'react'
import styled from 'styled-components'
import { ReactComponent as X } from 'assets/images/close-button.svg'

export const CloseButton: React.FC<{ handleClose?: any }> = ({ handleClose }) => {
  return (
    <Container onClick={handleClose}>
      <X />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 20px 0 0;
  padding: 10px;
  cursor: pointer;
  border-radius: 55%;

  :hover {
    background-color: #eee;
  }

  svg {
    fill: #aaa;
    width: 20px;
    height: 20px;
  }
`
