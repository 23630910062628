import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  margin-top: 20px;
  border: 2px solid ${props => props.theme.main.grey};
  padding: 10px;
  
  table {
    border: 0;
    width: 50%;
  }
  
  td {
    vertical-align: top;
    width: 48%;
  }
  
  td:first-child {
    font-weight: bolder;
    padding-right: 1%;
  }
  
  td:last-child {
    padding-right: 1%;
  }
`
