import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.main.charcoal};
  font-weight: bolder;
  padding-right: 15px;
  font-size: 13px;

  #fo__header-profile-container {
    height: 30px;
    width: 30px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #ccc;
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
  svg {
    margin: 0 12px;
    height: 15px;
    fill: #ccc;
  }
`