import React from 'react'
import styled from 'styled-components'

export const Tab: React.FC<{ minimize: any }> = ({
  minimize: { minimize, setMinimize }
}) => {
  return (
    <Container id='fuelops-reconciliation__card-summary-tab' minimize={minimize} onClick={() => setMinimize(false)}>
      Summary
    </Container>
  )
}

const Container = styled.div<{ minimize: boolean }>`
  display: ${props => (props.minimize ? 'flex' : 'none')};
  align-items: center;
  position: fixed;
  top: 104px;
  color: #fff;
  font-size: 12px;
  padding: 10px 15px;
  cursor: pointer;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-weight: bolder;
  background-color: #dc143c;
  right: -28px;
  border-radius: 0 0 6px 6px;
  transform: rotate(90deg);
`
