import styled from 'styled-components'

export const ModalWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    > div {
      margin-top: 15px;
    }

    input:disabled {
      cursor: no-drop;
      font-style: italic;
    }

    input {
      margin-bottom: 5px;
    }

    label {
      font-weight: bold;
      font-size: 0.9rem;
      color: ${props => props.theme.main.charcoal};
      margin: 0;

      span {
        color: red;
      }
    }

    .invalid-feedback {
      margin: 0 0 5px 0;
    }

    input.form-control {
      height: 32px;
    }

    input.form-control.is-invalid {
      margin-bottom: 0;
    }

    @media (max-width: ${props => props.theme.global.smMd}) {
      max-width: 100%;
    }
  }

  h4 {
    text-decoration: underline;
    margin: 10px 0 20px;
  }
`
