import React from 'react'
import { Container } from './Styled'
import { currencyFormatter } from '../TR'

interface HProps {
  data: {
    spent: string
    allowed: string
  }
}

export const Highlight: React.FC<HProps> = ({ data: { spent, allowed } }) => {
  const calcAmt = parseFloat(allowed) - parseFloat(spent)
  let amt: string | number = currencyFormatter(calcAmt)
  let highlight

  if (+calcAmt.toFixed(2) <= -1) {
    highlight = -1
  } else if (+calcAmt.toFixed(2) < 0) {
    highlight = 0
  } else {
    highlight = 1
  }

  return <Container highlight={highlight}>{amt}</Container>
}
