import styled from 'styled-components'

export const TBody = styled.tbody``

export const Row = styled.tr<{ lastRow: boolean }>`
  height: 40px;
  font-size: 12px;
  vertical-align: top;
  color: #55585e;

  td:first-child {
    padding-left: 15px;
    color: blue !important;
    cursor: pointer;
  }

  td:last-child {
    padding: 8px 10px 8px 0;
    text-align: center;
    cursor: pointer;
    background-color: initial;
  }

  td {
    border-bottom: ${props => (props.lastRow ? 'none' : '2px solid #eef1f7')};
    padding: 10px 5px 4px;
    font-weight: 600;
  }
`
