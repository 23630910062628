import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding-bottom: 20px;

  tbody {
    background-color: #fff;
    text-align: center;
  }

  thead {
    cursor: pointer;
    font-size: 10px;
    text-transform: uppercase;
    background-color: #788295;
  }

  .fuelops__recap-tfoot-spacer {
    width: 30px;
  }

  > table {
    width: 100%;
    max-width: 1100px;
  }

  > table > tbody > tr td:first-child {
    text-align: left;
  }

  > table > tbody > tr td:last-child {
    // border-left: 2px solid #f2f2f2;
  }

  > table > thead > tr th:first-child {
    padding-left: 15px;
    border-radius: 6px 0 0 0;
    max-width: 300px;
  }

  > table > thead > tr th:last-child {
    border-radius: 0 6px 0 0;
    padding-right: 15px;
    min-width: 40px;
  }

  > table > thead > tr th {
    padding: 10px 5px;
    border-top: 2px solid #f2f2f2;
  }

  > table > tfoot > tr td {
    background-color: #fff;
    font-size: 12px;
    font-weight: bold;
    color: #55585e;
    border-bottom: 2px solid #f2f2f2;
    text-align: center;
  }

  > table > tfoot > tr td:first-child {
    border-left: 2px solid #f2f2f2;
  }

  > table {
    .fuelops__recap-tfoot-td1 {
      width: 6%;
      text-align: center;
      border-left: 2px solid #f2f2f2;
      border-right: 2px solid #f2f2f2;
    }
    .fuelops__recap-tfoot-td2 {
      width: 6%;
      text-align: center;
      border-right: 2px solid #f2f2f2;
    }
  }

  > table > tfoot > tr td:last-child {
    border-right: 2px solid #f2f2f2;
  }

  #recon__dots-dots-dots {
    min-width: 10px;
  }
`

export const Wrapper = styled.div<{ highlight?: boolean; first?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.first ? 'flex-start' : 'center'};
  color: ${props => props.theme.main.light};

  .fuelops__th-underline {
    border-bottom: 1px solid
      ${props =>
        props.highlight ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0)'};
    transition: all 0.3s linear;
  }
`

export const Div = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  span:first-child {
    text-align: right;
    margin-right: 20px;
  }

  span:last-child {
    text-align: left;
    margin-left: 15px;
  }

  @media (min-width: 900px) {
    span:first-child {
      margin-right: 35px;
    }
    span:last-child {
      margin-left: 30px;
    }
  }

  @media (min-width: 1024px) {
    span:first-child {
      margin-right: 50px;
    }
    span:last-child {
      margin-left: 45px;
    }
  }
`
