import React, { useState } from 'react'
import { NavMenu } from '../NavMenu'
import { Container, Wrapper } from './Styled'
import { useLocation } from 'react-router-dom'
import OCH from 'react-outside-click-handler'
import ReactTooltip from 'react-tooltip'

interface IProps {
  Icon: any
  setShowModal?: (arg: boolean) => void
  as?: any
  to?: any
  type?: string
  route?: string
  admin?: any
  tooltip?: string
}

export const Icon: React.FC<IProps> = ({ Icon, type, route, tooltip, setShowModal }) => {
  const location = useLocation()
  const rootPath = location.pathname.split('/')[1]
  const [menu, setShowMenu] = useState(false)
  const handleClick = () => {
    if (type === 'admin') {
      if (!menu) {
        ReactTooltip.hide();
      }

      menu ? setShowMenu(false) : setShowMenu(true);
    }
    if (setShowModal) {
      setShowModal(true)
    }


  }

  return (
    <OCH display='contents' onOutsideClick={() => setShowMenu(false)}>
      <Container onClick={handleClick}>
        <Wrapper data-tip data-for={tooltip} highlightIcon={rootPath === route}>
          <Icon />
        </Wrapper>
        {menu && <NavMenu />}
      </Container>
      <ReactTooltip id={tooltip} place='right' type='dark' effect='solid'>
        <span>{tooltip}</span>
      </ReactTooltip>
    </OCH>
  )
}
