import React, { useState, useMemo } from 'react'
import { ArrowBox } from 'scenes/Reports/Fraud/Tables/FraudTable/ArrowBox'
import { Container, Wrapper } from './Styled'
import { TR } from './TR'
import { Header } from './Header'
import cloneDeep from 'lodash/cloneDeep'

const ReconciliationTable: React.FC<{
  data: any
  showReconciled: boolean
  setShowReconciled: (arg: any) => void
}> = ({ data: recData, showReconciled, setShowReconciled }) => {
  const data = cloneDeep(recData)
  data.forEach((datum: any) => {
    datum.difference = parseFloat(
      (datum.amtnottoexceed - datum.totalspent).toFixed(2)
    )
  })
  const [searchText, setSearchText] = useState('')
  const [sortConfig, setSortConfig] = useState({
    key: 'voucherid',
    order: 'desc'
  })

  let sortedItems = useMemo(() => {
    return data.sort((a: any, b: any) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.order === 'asc' ? -1 : 1
      } else if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.order === 'asc' ? 1 : -1
      } else {
        return 0
      }
    })
  }, [data, sortConfig.key, sortConfig.order])

  sortedItems = useMemo(() => {
    if (showReconciled) {
      return sortedItems
    }
    return sortedItems.filter((item: any) => !item.Reconciled)
  }, [showReconciled, sortedItems])

  sortedItems = useMemo(() => {
    return sortedItems.filter((ele: any) => {
      if (searchText === '') {
        return ele
      }
      let keepElement = false
      Object.keys(ele).forEach((key: any) => {
        if (keepElement === true) {
          return
        }
        keepElement =
          ele[key] &&
          ele[key].toString().toLowerCase().includes(searchText.toLowerCase())
      })
      return keepElement
    })
  }, [searchText, sortedItems])

  const requestSort = (key: string) => {
    let order = 'asc'
    if (sortConfig.key === key && sortConfig.order === 'asc') {
      order = 'desc'
    }
    setSortConfig({ key, order })
  }

  return (
    <Container>
      <Header
        data={{ searchText, setSearchText, showReconciled, setShowReconciled }}
      />
      <table>
        <thead>
          <tr>
            <th onClick={() => requestSort('voucherid')}>
              <Wrapper highlight={sortConfig.key === 'voucherid'}>
                <span className='fuelops__th-underline'>ID </span>
                {sortConfig.key === 'voucherid' && (
                  <ArrowBox config={sortConfig} headerName='voucherid' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('clientid')}>
              <Wrapper highlight={sortConfig.key === 'clientid'}>
                <span className='fuelops__th-underline'>Location </span>
                {sortConfig.key === 'clientid' && (
                  <ArrowBox config={sortConfig} headerName='clientid' />
                )}
              </Wrapper>
            </th>
            <th style={{ cursor: 'default' }}>
              <Wrapper highlight={sortConfig.key === 'status'}>
                <span className='fuelops__th-underline'>Status </span>
                {sortConfig.key === 'status' && (
                  <ArrowBox config={sortConfig} headerName='status' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('createdate')}>
              <Wrapper highlight={sortConfig.key === 'createdate'}>
                <span className='fuelops__th-underline'>Issued </span>
                {sortConfig.key === 'createdate' && (
                  <ArrowBox config={sortConfig} headerName='createdate' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('issuedto')}>
              <Wrapper highlight={sortConfig.key === 'issuedto'}>
                <span className='fuelops__th-underline'>Issued To </span>
                {sortConfig.key === 'issuedto' && (
                  <ArrowBox config={sortConfig} headerName='issuedto' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('amtnottoexceed')}>
              <Wrapper highlight={sortConfig.key === 'amtnottoexceed'}>
                <span className='fuelops__th-underline'>Issued </span>
                {sortConfig.key === 'amtnottoexceed' && (
                  <ArrowBox config={sortConfig} headerName='amtnottoexceed' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('totalspent')}>
              <Wrapper highlight={sortConfig.key === 'totalspent'}>
                <span className='fuelops__th-underline'>Closed </span>
                {sortConfig.key === 'totalspent' && (
                  <ArrowBox config={sortConfig} headerName='totalspent' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('difference')}>
              <Wrapper highlight={sortConfig.key === 'difference'}>
                <span className='fuelops__th-underline'>Difference </span>
                {sortConfig.key === 'difference' && (
                  <ArrowBox config={sortConfig} headerName='difference' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('createdby')}>
              <Wrapper highlight={sortConfig.key === 'createdby'}>
                <span className='fuelops__th-underline'>Issued By </span>
                {sortConfig.key === 'createdby' && (
                  <ArrowBox config={sortConfig} headerName='createdby' />
                )}
              </Wrapper>
            </th>
            <th style={{ cursor: 'default' }} id='recon__dots-dots-dots'>
              <Wrapper highlight={sortConfig.key === 'Reconciled'}>
                <span className='fuelops__th-underline'>Reconciled</span>
                {sortConfig.key === 'Reconciled' && (
                  <ArrowBox config={sortConfig} headerName='Reconciled' />
                )}
              </Wrapper>
            </th>
            {/* <th /> */}
          </tr>
        </thead>
        {sortedItems.map((voucher: any, idx: number) => (
          <TR
            key={voucher.voucherid}
            lastRow={idx === sortedItems.length - 1}
            voucher={voucher}
          />
        ))}
      </table>
    </Container>
  )
}

export default React.memo(ReconciliationTable, areEqual)


function areEqual (prevProps: any, nextProps: any) {
  if (prevProps.data.length !== nextProps.data.length) {
    return false
  }
  let areEqual = true
  // This runs in quadratic time which is not ideal,
  // but better than forcing an unnecessary re-render
  for (let i = 0; i < prevProps.data.length; ++i) {
    let obj = Object.keys(prevProps.data[i])
    for (let j = 0; j < obj.length; ++j) {
      if (prevProps.data[j] !== nextProps.data[j]) {
        areEqual = false
        i = prevProps.data.length
      }
    }
  }
  return areEqual
}