import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  font-family: Montserrat, sans-serif;
  display: flex;
  align-items: center;
  height: 100%;
  transition: background-color 0.2s ease-out;
  color: '#55585e';
  
  .input-wrapper {
    margin-left: 20px;
  }
  
  .input-wrapper .DayPickerInput input {
    appearance: none;
    padding: .3em 1em;
    border: 1px solid #ddd;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    max-width: 140px;
  }
  
  .input-wrapper .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: #2c3e50;
    color: #f0f8ff;
  }
  
  .input-wrapper .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #eaeef0 !important;
    color: #55585e;
  }
  
  .input-wrapper .DayPicker-Day--today {
    color: #e74c3c !important;
    font-weight: 700;
  }
  
  
  .input-wrapper .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #eaeef0;
  }
  
  .input-wrapper .DayPicker-Day {
    border-radius: 0 !important;
  }
  
  .input-wrapper .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  
  .input-wrapper .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  
  
  .reload-button {
    background: transparent;
    border: none !important;
    cursor: pointer;
    margin-left: 5px;
    margin-top: 2px;
    outline: none !important;
    padding: 0;
    
    svg {
      color: #2c3e50;
      transition: color 0.2s ease-out;
    }
    
    &:hover {
      svg {
        color: #e74c3c;
      }
    }
    
    &:disabled {
      cursor: default;
      
      svg {
        color: #efefef;
      }
    }
  }
`
