export const theme = {
  global: {
    maxWidth: '1200px',
    foDarkBlue: '#2c3e50',
    foBlueGray: '#607d8b',
    foLightBlueGray: '#b0bec5',
    foOrange: '#e74b3c',
    smBp: '767px',
    smMd: '900px'
  },
  main: {
    light: '#fff',
    dark: '#000',
    grey: '#ccc',
    charcoal: '#464646'
  },
  header: {
    light: '#fff',
    topbarBgColor: '#2c3e50',
    navbarBgColor: '#607D8B',
    dropdownBorder: '#cdcdcd'
  },
  fuelVoucher: {
    headerColor: '#333',
    brandColor: '#cc0000',
    bgColor: '#f8fcfc',
    borderColor: '#ebebeb',
    menuBg: '#415161',
    graphBackground: '#f5f5f5'
  },
  marketing: {
    bgColor: '#f5f5f4'
  }
}
