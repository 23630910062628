import styled, { css } from 'styled-components'

interface IProps {
  vis?: boolean
}

const edgeStyles = css`
  &:after {
    content: '';
    position: absolute;
    background-color: none;
    right: -10px;
    height: 10px;
    width: 10px;
    z-index: 50;
    bottom: 0;
    border-bottom-left-radius: 8px;
    box-shadow: -5px 0 0 0 ${props => props.theme.main.light};
  }
  
  &:before {
    content: '';
    position: absolute;
    background-color: none;
    left: -10px;
    height: 10px;
    width: 10px;
    z-index: 50;
    bottom: 0;
    border-bottom-right-radius: 8px;
    box-shadow: 5px 0 0 0 ${props => props.theme.main.light};
  }
`

export const Container = styled.div`
  width: 100%;
  
  background: linear-gradient(
    210deg,
    #fff,
    ${props => props.theme.fuelVoucher.graphBackground}
  );
  
  #wrapper {
    font-family: Montserrat, sans-serif;
    margin-bottom: 20px;
    width: 100%;
    
    .ag-cell-focus.no-focus {
      border-color: transparent;
      border-right-color: #e2e2e2;
    }
    
    .ag-header-cell.text-center {
      .ag-header-cell-label {
        justify-content: center;
      }
    }
    
    .fuelops__grid-actions {
      padding-left: 30px;
      padding-right: 10px;
      
      .fuelops__grid-action-item {
        cursor: pointer;
        padding: 5px;
        transition: color 0.2s ease-out;

        &:active,
        &:hover {
          color: #e74b3c;
        }
      }
    }
    
    
    .ag-header-cell-text {
      font-size: 11px;
    }
    
    .ag-header-cell {
      padding-left: 0;
      padding-right: 0;
    }
    
    .ag-cell {
      padding-left: 0;
      padding-right: 0;
    }
    
    .ag-row {
      font-size: 13px;
    }
  }
  
  #main__graph-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    // display: grid;
    // grid-template-columns: 85% 15%;
    // overflow-x: hidden;
    
    // @media (max-width: 1023px) {
    //   grid-template-columns: 100%;
    // }
  }
  
  #create {
    width: 150px;
    height: 50px;
  }
`

export const Wrapper = styled.div<IProps>`
  width: 100%;
  height: 100%;
  filter: ${props => (props.vis ? 'grayscale(1)' : 'grayscale(0)')};
  transition: filter 0.25s linear;
`

export const SearchResultsContainer = styled.div`
  display: flex;
  margin: 20px auto;
  width: 90%;
`

export const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 250px;
`

export const Filters = styled.div`
  display: flex;
  position: relative;
  font-family: Montserrat, sans-serif;
  z-index: 1;
  margin-left: 10px;
  
  .p-3 {
    padding: 0.8rem !important;
  }
  
  .small-legend {
    width: auto;
  }
  
  button {
    margin: 0 10px;
  }
`

export const ButtonWrapper = styled.div<{
  active?: boolean
  type?: string
}>`
  ${props => props.active && edgeStyles};
  background-color: ${props =>
    props.active ? props.theme.main.light : 'inherit'};
  padding: 5px 20px;
  position: relative;
  border-radius: 20px 20px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transition: color 0.2s ease-out;
  transition: height 1s ease-out;
  color: ${props => (props.active ? props.theme.main.charcoal : '#ccc')};
  
  h5 {
    font-size: 16px;
    margin: 0;
  }
  
  :hover {
    color: ${props => (props.active ? props.theme.main.dark : '#aaa')};
  }
  
  .fuelops__button-highlight {
    transition: all 0.2s ease-out;
    top: 0;
    left: ${props => (props.active ? '20%' : '50%')};
    height: 100%;
    width: ${props => (props.active ? '60%' : '0')};
    position: absolute;
    border-bottom: 5px solid #84254a;
    border-radius: 1.7px;
  }
`
