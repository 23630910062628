import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
  max-width: 993px;
  align-items: flex-end;
  color: #fff;

  h4 {
    margin: 0;
  }
`

export const Options = styled.div`
  background-color: #fff;
  border: 2px solid #e0e3eb;
  margin-left: 10px;
  padding: 4px 8px 0px 8px;

  .MuiTypography-body1 {
    font-size: 13px;
    color: #55585e;
    font-weight: bolder;
    font-family: Montserrat, sans-serif;
  }

  .MuiFormControlLabel-root {
    margin: 0;
  }
`

export const Wrapper = styled.div<{ active?: boolean }>`
  display: flex;
  width: 455px;
  justify-content: space-between;

  .MuiInputBase-input {
    color: #fff;
    font-family: Montserrat;
  }

  .MuiFormControlLabel-root {
    position: relative;
    top: 8px;
    margin: 0;
  }

  .MuiTypography-body1 {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
  }

  .MuiInputBase-root {
    font-family: Montserrat, sans-serif;
  }

  #fuelops__recap-recon-search {
    position: relative;

    :active,
    :focus-within,
    :focus {
      svg {
        fill: #7c818f;
      }
    }

    input {
      padding: 5px 5px 5px 30px;
      border: 2px solid #e0e3eb;
      border-radius: 3px;
    }

    input::placeholder {
      color: #cdd0d6;
    }

    input:focus {
      border: 2px solid #7c818f;
      outline: none;
    }

    svg {
      position: absolute;
      top: 11px;
      left: 10px;
      width: 15px;
      height: 15px;
      fill: ${props => (props.active ? '#7c818f' : '#e0e3eb')};
    }
  }
`
