import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LogoAnim } from 'assets/images/dots-anim.svg'

export const Spinner: React.FC = () => {
  return (
    <Container>
      <LogoAnim />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3000;
  height: 100%;
  width: 100%;

  svg {
    height: 120px;
    width: 120px;
  }
`
