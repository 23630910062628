import React, { useEffect, useState } from 'react'
import { DashboardLayout } from 'core/components/DashboardLayout'
import { useApolloClient, useQuery, gql } from '@apollo/client'
import { api } from 'data/apollo/api'
import { LogoAnim } from 'core/components/LogoAnim'
import { ErrorScreen } from 'core/components/ErrorScreen'
import { Container, TextWrapper } from './Styled'
import { Table } from './components/Table'
import { getTokenDetails } from 'data/helpers/token'
import { Dates } from 'core/components/Dates'
import Img from 'assets/images/results_not_found.jpg'
import moment from 'moment'

const Dashboard = ({dealers, dateRange, setDateRange}) => {
  const userAccess = getTokenDetails('userAccess');
  
  const client = useApolloClient();
  client.query({ query: api.remote.query.makes });
  
  const [showCreate, setShowCreate] = useState(false)
  const [vis, setVis] = useState(false)
  const { startDate, endDate } = dateRange
  let { loading, error, data } = useQuery(api.remote.query.vouchersInRange, {
    variables: { 
      startDate,
      endDate: moment(endDate).add(1, 'days'), cids: userAccess
    },
    fetchPolicy: 'network-only'
  });
  
  if (loading) {
    return <LogoAnim />
  }
  
  if (error) {
    return <ErrorScreen />
  }
  
  const handleDataChange = data =>
    data.map(datum => ({
      ...datum,
      clientid: dealers[datum.clientid.toLowerCase()],
      createdate: moment(datum.createdate).format('MM-DD-YYYY'),
      amtnottoexceed: datum.amtnottoexceed.toFixed(2)
    }))
  ;
  
  return (
    <Container>
      <DashboardLayout>
        <Dates dateRange={dateRange} setDateRange={setDateRange} />
      </DashboardLayout>
      {data.vouchersInRange && data.vouchersInRange.length ? (
        <Table
          visibility={vis}
          vouchers={handleDataChange(data.vouchersInRange)}
          showCreate={showCreate}
          setShowCreate={setShowCreate}
        />
      ) : (
        <TextWrapper>
          <h4>No results found</h4>
          <p>Please try widening your search criteria</p>
          <img src={Img} alt='Results not found' />
        </TextWrapper>
      )}
    </Container>
  )
}

export default Dashboard
