import React, { useState, useEffect } from 'react'
// @ts-ignore
import Lottie from 'react-lottie'
import animationData from 'assets/animations/checkmark.json'

export const PostRequest: React.FC<{
  setShowForm: (param: boolean) => void
}> = ({ setShowForm }) => {
  const [paused, setPaused] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowForm(false)
    }, 1000)
  }, [setShowForm])

  useEffect(() => {
    setTimeout(() => {
      setPaused(true)
    }, 990)
  }, [])

  return (
    <div>
      <Lottie
        isPaused={paused}
        options={{
          loop: false,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
      />
    </div>
  )
}
