import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { Wrapper, ReturnWrapper } from './Styled'
import { VoucherForm } from 'core/components/VoucherForm';

import { ReactComponent as Logo } from 'assets/images/dots.svg';
import { Col, Modal, Row, Typography } from 'antd';

const { Text } = Typography;


const VoucherClose: React.FC<{show: boolean; data: any; showClose: any}> = ({ show, data, showClose }) => {
  const [returnedData, setReturnedData] = useState<any>({})
  const [showReturn, setShowReturn] = useState(false)
  
  const handleClose = () => {
    showClose(false);
    setShowReturn(false);
  }
  
  return (
    <Modal
      footer={null}
      onCancel={handleClose}
      onOk={handleClose}
      title={(
        <Row justify="space-around" align="middle" style={{fontSize: '18px'}}>
          <Col flex="50px">
            <Logo className='logo' style={{maxWidth: '30px', maxHeight: '30px'}}/>
          </Col>
          <Col flex="auto" style={{textAlign: 'center'}}>
            <Text>Voucher: <strong>{data.voucherid}</strong></Text>
          </Col>
          <Col flex="50px"></Col>
        </Row>
      )}
      visible={show}
      width='90%'
      style={{maxWidth: '1000px'}}
    >
      {showReturn ? (
        <Wrapper>
          <ReturnWrapper>
            <div id='title'>Summary</div>
            <span>
              Voucher ID: <u>{returnedData.voucherid}</u>
            </span>
            <span>
              Total Spent: <u>${returnedData.totalspent}</u>
            </span>
            <div
              style={{
                margin: '25px 0',
                border: '2px solid #ccc',
                padding: '10px'
              }}
              id='title'
            >
              This voucher has been closed
            </div>
            <Button variant='primary' onClick={handleClose}>
              Exit
            </Button>
          </ReturnWrapper>
        </Wrapper>
      ) : (
        <VoucherForm
          initValues={data}
          setReturnedData={setReturnedData}
          setShowReturn={setShowReturn}
          handleClose={handleClose}
          formType='close'
        />
      )}
    </Modal>
  )
}

export default VoucherClose
