import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  svg {
    height: 9px;
    width: 9px;
    fill: #55585e;
    stroke: #55585e;
    stroke-opacity: 0.8;
  }
`
