import React from 'react'
import ReactModal from 'react-modal';
import { Button } from './Styled';

ReactModal.setAppElement('#root');
ReactModal.defaultStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    bottom: 0,
    left: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'fixed',
    right: 0,
    textAlign: 'center',
    top: 0,
    zIndex: 1040,
  },
  content: {
    background: '#fff',
    borderRadius: '10px',
    display: 'inline-block',
    height: 'auto',
    margin: '50px auto',
    minWidth: '400px',
    outline: 'none',
    padding: '20px',
    position: 'relative'
  }
}

export const Modal: React.FC<{isOpen: boolean; onClose: any}> = ({isOpen, children, onClose}) => {
  return (
    <ReactModal
      aria={{
        labelledby: 'modal-label'
      }}
      closeTimeoutMS={200}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <Button type="button" aria-label="Close" onClick={onClose}>
        <span aria-hidden="true">×</span>
      </Button>
      {children}
    </ReactModal>
  )
}

export default Modal