import React, { ChangeEvent } from 'react'
import { Container, Wrapper, Options } from './Styled'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { ReactComponent as MagGlass } from 'assets/images/magnifying-glass.svg'
import TextField from '@material-ui/core/TextField'
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme
} from '@material-ui/core/styles'

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#e74b3c'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#e5e5e5'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#e74b3c'
    },
    '& label': {
      color: '#e5e5e5'
    }
  }
})(TextField)

export const Header: React.FC<{ data: any }> = ({ data }) => {
  const { searchText, setSearchText, showReconciled, setShowReconciled } = data

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const handleChecked = () => {
    setShowReconciled(!showReconciled)
  }

  return (
    <Container>
      <h4>Reconciliation</h4>
      <Wrapper active={!!searchText}>
        <FormControlLabel
          control={
            <Switch
              checked={showReconciled}
              onChange={handleChecked}
              name='showReconciled'
              color='secondary'
            />
          }
          label='Show Reconciled'
        />
        <CssTextField
          onChange={handleChange}
          label='Search...'
          id='custom-css-standard-input'
          spellCheck='false'
        />
      </Wrapper>
    </Container>
  )
}
