import { put, all, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import store from 'store'
import * as actionTypes from './actionTypes'

function* fetchVouchers({ payload }) {
  yield put({ type: actionTypes.FETCH_VOUCHERS_LOADING })
  try {
    const { data } = yield axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BASEURL}/fetch-vouchers`,
      data: {
        data: payload
      },
      headers: {
        Authorization: `Bearer ${store.get('foAccess')}`
      }
    })
    yield put({ type: actionTypes.FETCH_VOUCHERS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: actionTypes.FETCH_VOUCHERS_FAILURE, payload: e })
  }
}

function* fetchVouchersSaga() {
  yield takeLatest(actionTypes.FETCH_VOUCHERS_START, fetchVouchers)
}

function* fetchMakes() {
  try {
    const { data } = yield axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BASEURL}/makes`,
      headers: {
        Authorization: `Bearer ${store.get('foAccess')}`
      }
    })
    yield put({ type: actionTypes.FETCH_MAKES_SUCCESS, payload: data})
  } catch (e) {
    yield put({ type: actionTypes.FETCH_MAKES_FAILURE, payload: e})
  }
}

function* fetchMakesSaga() {
  yield takeLatest(actionTypes.FETCH_MAKES_START, fetchMakes)
}

export default function* rootSaga() {
  yield all([fetchVouchersSaga(), fetchMakesSaga()])
}
