import React from 'react'
import Cookies from 'universal-cookie';
import { ReactComponent as Logout } from 'assets/images/signout.svg'
import { User } from '../User'
import { Container } from './Styled'
import store from 'store'

export const UserProfile: React.FC = () => {
  const handleClick = () => {
    store.remove('foStartDate');
    store.remove('foEndDate');
    store.remove('foAccess');
    store.remove('foRefresh');
    
    const cookies = new Cookies();
    cookies.remove('foDateRange');
    
    window.location.href = (process.env.REACT_APP_DOPS_URL ?? '');
  }
  return (
    <Container>
      <User />
      <div className='fo__navbar-logout' onClick={handleClick}>
        <Logout />
      </div>
    </Container>
  )
}
