import React from 'react'
import styled from 'styled-components'
import OCH from 'react-outside-click-handler'
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export const Popup = ({
  event,
  data,
  setPopup,
  type,
  handleForm
}) => {
  
  
  let location = {
    left: 80,
    top: 200
  };
  
  
  if (event) {
    const offset = (el) => {
      const modalBody = document.getElementById('modalBody'); 
      return (el.getBoundingClientRect().top - modalBody.getBoundingClientRect().top - 150);
    };
    
    location.top = offset(event.target);
  }
  
  
  
  const handleClose = () => setPopup({ show: false, data: null, event: null });
  const newVehicleInventoryType = data.type || null;
  const handleDepartment = name => {
    let vehicleInventoryDepartment = '';
    if (newVehicleInventoryType) {
      vehicleInventoryDepartment = newVehicleInventoryType === 'N' ? 'New Delivery' : 'Used Delivery';
    }
    
    switch (name) {
      case 'VehicleInventory':
        return vehicleInventoryDepartment
      case 'ServiceOpenRO':
        return 'SVC RO'
      case 'FuelTrackerStock':
        return data.description
      default:
        return ''
    }
  };
  
  
  const handleClick = () =>
    handleForm({
      clientid: data.clientid,
      Color: data.color,
      Make: data.make,
      Model: data.model,
      ModelYear: data.year,
      VIN: data.vin,
      StockNumber: data.stock,
      DepartmentIssue: handleDepartment(type)
    })
  ;
  
  return (
    <OCH onOutsideClick={handleClose} display='contents'>
      <Container location={location}>
        <CloseOutlined onClick={handleClose}/>
        <span>Select this row?</span>
        <Button type='primary' onClick={handleClick}>OK</Button>
      </Container>
    </OCH>
  )
}

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 90px;
  width: 180px;
  top: ${props => props.location.top}px;
  left: ${props => props.location.left}px;
  z-index: 1000;
  overflow: visible;
  background-color: #fff;
  color: #55585e;
  border-radius: 5px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086);
  
  &:before {
    display: block;
    content: ' ';
    border: 10px solid transparent;
    border-top: 10px solid #fff;
    left: 20px;
    transform: rotate(180deg);
    position: absolute;
    top: -20px;
  }
  
  .anticon-close {
    color: #55585e;
    position: absolute;
    right: 6px;
    top: 6px;
    
    &:active,
    &:hover {
      color: #000;
    }
  }
`