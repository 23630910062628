import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border: 2px solid #ccc;
  margin-top: 25px;

  h5 {
    padding: 10px 5px 0px;
    font-size: 0.9em;
    font-weight: bolder;
  }
`
