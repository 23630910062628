import React from 'react'
import { Container, Wrapper } from './Styled'
import { currencyFormatter } from 'data/helpers'
import { VictoryPie, VictoryLabel } from 'victory'

export const SummaryTable: React.FC<{ data: any }> = ({ data }) => {
  const summaryTotals = Object.keys(data).reduce(
    (acc: any, curr: any) => {
      const { count, pending, closed } = data[curr]
      acc.count += count
      acc.pending += pending
      acc.closed += closed
      acc.difference += closed - pending
      return acc
    },
    { count: 0, pending: 0, closed: 0, difference: 0 }
  )
  const chartData = [
    {
      x: 'Pending',
      y: summaryTotals.pending.toFixed(2)
    },
    {
      x: 'Closed',
      y: summaryTotals.closed.toFixed(2)
    }
  ]
  return (
    <Container>
      <Wrapper>
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th style={{ textAlign: 'center' }}>Count</th>
              <th style={{ textAlign: 'right' }}>Pending</th>
              <th style={{ textAlign: 'right' }}>Closed</th>
              <th style={{ textAlign: 'right' }}>Difference</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((item: any, idx: number) => {
              const { count, pending, closed } = data[item]
              return (
                <tr key={item}>
                  <td>{item}</td>
                  <td style={{ textAlign: 'center' }}>{count}</td>
                  <td style={{ textAlign: 'right' }}>
                    {currencyFormatter(pending)}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {currencyFormatter(closed)}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {currencyFormatter(closed - pending)}
                  </td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ textAlign: 'center' }}>
                <strong>Totals</strong>
              </td>
              <td style={{ textAlign: 'center' }}>{summaryTotals.count}</td>
              <td style={{ textAlign: 'right' }}>
                {currencyFormatter(summaryTotals.pending)}
              </td>
              <td style={{ textAlign: 'right' }}>
                {currencyFormatter(summaryTotals.closed)}
              </td>
              <td style={{ textAlign: 'right' }}>
                {currencyFormatter(summaryTotals.difference)}
              </td>
            </tr>
          </tfoot>
        </table>
      </Wrapper>
      <svg width={550} height={200}>
        <VictoryPie
          width={550}
          height={200}
          labelRadius={105}
          padAngle={5}
          standalone={false}
          labels={({ datum }) => `${datum.x}: $${datum.y}`}
          data={chartData}
          colorScale={['#2c3e50', '#E91E63']}
          innerRadius={60}
          cornerRadius={5}
          radius={95}
        />
        <VictoryLabel
          textAnchor='middle'
          verticalAnchor='middle'
          style={[
            {
              fontSize: 20,
              color: '#ccc',
              lineHeight: 20
            }
          ]}
          x={550 * 0.5}
          y={200 * 0.5}
          text={['Count', summaryTotals.count]}
        />
      </svg>
    </Container>
  )
}
