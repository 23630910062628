import React, { useState, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Container, Wrapper } from './Styled'
import { Table } from './Table'
import { api } from 'data/apollo/api'
import moment from 'moment'
import { DashboardLayout } from 'core/components/DashboardLayout'
import { Dates } from 'core/components/Dates';
import { ErrorScreen } from 'core/components/ErrorScreen'
import { Header } from './Table/Header'
import { LogoAnim } from 'core/components/LogoAnim'
import { getTokenDetails } from 'data/helpers/token'

export const Recaps: React.FC<{dealers: [any], dateRange: any, setDateRange: any}> = ({dealers, dateRange, setDateRange}) => {
  const userAccess = getTokenDetails('userAccess');
  
  const { startDate, endDate } = dateRange
  const [tables, setTables] = useState(['createdby', 'departmentissue'])
  const [searchText, setSearchText] = useState('')
  let { loading, error, data } = useQuery(api.remote.query.vouchersInRange, {
    variables: {
      startDate,
      endDate: moment(endDate).add(1, 'day').format('YYYY-MM-DD'),
      cids: userAccess
    }
  });
  
  const filteredData = useMemo(() => {
    if (!data) {
      return
    }
    
    const temp = data.vouchersInRange.map((datum: any) => {
      return {
        ...datum,
        clientid: dealers[datum.clientid.toLowerCase()]
      }
    });
    
    if (searchText === '') {
      return temp
    }
    
    return temp.filter((ele: any) => {
      if (searchText === '') {
        return ele
      }
      let keepElement = false
      Object.keys(ele).forEach((key: any) => {
        if (keepElement === true) {
          return
        }
        keepElement =
          ele[key] &&
          ele[key].toString().toLowerCase().includes(searchText.toLowerCase())
      })
      return keepElement
    })
  }, [searchText, data])

  let organizedData: any | null = null
  if (data) {
    organizedData = organizeData(filteredData, tables)
  }

  return (
    <Container>
      {loading && <LogoAnim />}
      {error && <ErrorScreen />}
      {organizedData && (
        <>
          <DashboardLayout>
            <Dates dateRange={dateRange} setDateRange={setDateRange} />
          </DashboardLayout>
          <Header
            data={{ searchText, setSearchText }}
            display={{ tables, setTables }}
          />
          <Wrapper>
            {Object.keys(organizedData).map((table: any) => (
              <Table
                key={table}
                data={organizedData[table]}
                tableName={table}
              />
            ))}
          </Wrapper>
        </>
      )}
    </Container>
  )
}

const organizeData = (data: any, keys: string[]) => {
  const mappingObject: { [key: string]: any } = {}
  keys.forEach((key: string) => {
    if (!mappingObject[key]) {
      mappingObject[key] = {}
    }
  })
  data.forEach((item: any) => {
    Object.keys(mappingObject).forEach((key: string) => {
      if (!mappingObject[key][item[key]]) {
        mappingObject[key] = { ...mappingObject[key], [item[key]]: [] }
      }
      mappingObject[key][item[key]].push(item)
    })
  })
  return mappingObject
}
