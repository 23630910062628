import styled from 'styled-components'


export const Container = styled.div`
  table,
  th,
  td {
    border: 2px solid #ccc;
    border-collapse: collapse;
    font-size: 14px;
  }

  .header-row {
    background-color: #2c3e50;
    color: #fff;

    th {
      text-align: center;
    }
  }

  .highlight {
    font-weight: bold;
  }

  tbody {
    td {
      font-weight: bolder;
    }
  }

  .allvouchers__status {
    width: 65px;
    text-align: center;
  }
  
  .allvouchers__footer {
      font-weight: bold;
      background-color: #ddd;
  }

  .allvouchers__voucherid {
    width: 55px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    color: #0000ee;
  }
  .allvouchers__createdate {
    width: 75px;
    text-align: center;
  }
  .allvouchers__stknum {
    width: 75px;
    text-align: center;
  }
  .allvouchers__vehcolor {
    width: 75px;
    text-align: center;
  }
  .allvouchers__vehmake {
    width: 75px;
    text-align: center;
  }
  .allvouchers__vehmodel {
    width: 150px;
    text-align: center;
  }
  .allvouchers__amtnottoexceed {
    width: 70px;
    text-align: center;
  }
  .allvouchers__issuedto {
    width: 125px;
    text-align: center;
    box-sizing: border-box;
  }
  .allvouchers__departmentissue {
    width: 180px;
    text-align: center;
  }
  .allvouchers__createdby {
    width: 80px;
    text-align: center;
  }
  .allvouchers__totalspent {
    width: 80px;
    text-align: center;
  }
`
