import * as yup from 'yup'

export const getSchema = showAdditional => {
  let params
  switch (showAdditional) {
    case 'close':
      params = {
        totalspent: yup
          .number()
          .required('Required')
          .moreThan(0, 'Total Spent must be greater than zero')
      }
      break
    case 'create-new':
      params = {
        clientid: yup.string().required('Required')
      }
      break
    default:
      params = null
  };
  
  return yup.object({
    amtnottoexceed: yup
      .number()
      .when('fillup', fillup => {
        if (fillup) {
          return yup.number().notRequired()
        }
      })
      .required('Required'),
    vinnum:
      showAdditional !== 'recap'
        ? yup
            .string()
            .required('Required')
            .test('isVinValid', 'VIN must be 8 or 17 characters', val => {
              if (!val) {
                return false
              }
              return val.length === 8 || val.length === 17
            })
        : null,
    vehyr:
      showAdditional !== 'recap'
        ? yup
            .string()
            .required('Required')
            .test('isYearValid', 'Year must be 2 or 4 digits', val => {
              if (!val) {
                return false
              }
              return val.length === 2 || val.length === 4
            })
        : null,
    stknum: yup.string().required('Required'),
    departmentissue: yup.string().required('Required'),
    issuedto: yup.string().required('Required'),
    vehmake: yup.string().required('Required'),
    vehmodel: yup.string().required('Required'),
    vehcolor: yup.string().required('Required'),
    ...params
  })
}

export const initialValues = {
  createdby: '',
  amtnottoexceed: '',
  stknum: '',
  issuedto: '',
  vinnum: '',
  departmentissue: '',
  vehyr: '',
  vehmake: '',
  vehmodel: '',
  vehcolor: '',
  fillup: false,
  otherreason: ''
}
