import styled from 'styled-components'

export const Container = styled.div`
  background-color: #fff;
  font-family: Montserrat;
  min-height: 100vh;

  .reconciliation__card-wrapper {
    z-index: 1;
    // position: fixed !important;
  }
  
  h4 {
    color: #fff;
  }
`

export const Wrapper = styled.div`
  padding: 0 20px 0 40px;
  min-height: calc(100vh - 45px);
  background: rgb(9, 9, 121);
  background: linear-gradient(
    180deg,
    #384b60 200px,
    rgba(255, 255, 255, 1) 200px
  );
`
