import styled from 'styled-components'

export const MainWrapper = styled.div`
  background-color: #fff;
`

export const InnerWrapper = styled.div`
  margin: 0 auto;
  // max-width: 1400px;
`

export const Container = styled.div`
  position: fixed;
  height: 100%;
  z-index: 1000;
  display: grid;
  grid-template-rows: 45px 1fr;
  width: 55px;
  box-sizing: border-box;

  #fo__logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #161f28;
  }

  #fo__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.main.light};
    height: 35px;
    width: 35px;
    border-radius: 50%;

    img {
      height: 26px;
      width: 26px;
    }
  }

  @media print {
    & {
      display: none;
    }
  }
`

export const Icons = styled.div`
  // background-color: #2c3e50;
  background-color: #161f28;

  @media (max-width: 767px) {
    display: none;
  }
`

export const Wrapper = styled.div<{ fade: boolean }>`
  position: relative;
  left: 55px;
  box-sizing: border-box;
  max-width: calc(100% - 55px);
  // filter: grayscale(${props => props.fade ? 1 : 0});

  @media (max-width: 767px) {
    left: 0;
    max-width: 100%;
  }

  @media print {
    & {
      left: auto;
      max-width: 100%;
    }
  }
`
