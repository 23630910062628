import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie';
import { Switch } from 'react-router-dom'
import { PrivateRoute } from 'core/components/PrivateRoutes'
import { Print } from './scenes/Print'
import Dashboard from './scenes/Dashboard'
import { Reports } from './scenes/Reports'
import { Sidebar } from 'core/components/Sidebar'
import { PrivateReports } from 'core/components/PrivateRoutes/Reports'
import { PrivatePrint } from 'core/components/PrivateRoutes/Print'
import { api } from 'data/apollo/api';
import { useQuery } from '@apollo/client';
import { getTokenDetails } from 'data/helpers/token';
import moment from 'moment';

function App() {
  const userAccess = getTokenDetails('userAccess');
  
  const cookies = new Cookies();
  
  const [dateRange, setDateRange] = useState({
    startDate: (cookies.get('foDateRange') && cookies.get('foDateRange').startDate) || moment().startOf('month').format('YYYY-MM-DD'),
    endDate: (cookies.get('foDateRange') && cookies.get('foDateRange').endDate) || moment().endOf('month').format('YYYY-MM-DD')
  });
  
  useEffect(() => {
    cookies.set('foDateRange', dateRange);
  }, [dateRange]);
  
  // TODO Fix me! This is hacky, and the query is firing multiple times. (DC-20201016)
  let { loading, error, data } = useQuery(api.remote.query.getDealers, {
    variables: {
      cids: userAccess
    }
  });
  
  if (loading) {
    return (
      <div>&nbsp;</div>
    )
  }
  
  // Create our dealer list for use throughout the components
  const myDealers = data.dealers.reduce(
    (map: any, dealer: any) => {
      map[dealer.idsid.toLowerCase()] = dealer.dealername;
      return map;
    }, {}
  );
  
  return (
    <Switch>
      <Sidebar dealers={myDealers} setDateRange={setDateRange}>
        <PrivateRoute path='/' component={Dashboard} dealers={myDealers} dateRange={dateRange} setDateRange={setDateRange} exact />
        <PrivateReports path='/reports' component={Reports} dealers={myDealers} dateRange={dateRange} setDateRange={setDateRange} />
        <PrivatePrint path='/print-voucher' component={Print} />
      </Sidebar>
    </Switch>
  )
}

export default App
