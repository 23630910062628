import styled from 'styled-components'

export const Container = styled.div`
  // border-top: 0.5px solid #bfc5ca;
  padding: 0;
  position relative;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
  box-sizing: border-box;

  :hover {
    background-color: #808b96;
  }

  :nth-child(1) {
    svg {
      width: 22px;
      height: auto;
    }
  }

  :nth-last-child(1) {
    // border-bottom: 0.5px solid #bfc5ca;
  }
`

export const Wrapper = styled.div<{ highlightIcon?: boolean }>`
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  // width: 20px;

  svg {
    fill: ${props => props.highlightIcon ? '#f2f3f4' : '#abb1b5'};
  }
`
