import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 3.2px;
  right: 0;
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  svg {
    height: 9px;
    width: 9px;
    fill: #fff;
    stroke: #fff;
    stroke-opacity: 0.8;
  }
`
