import React from 'react'
import { Route, Redirect } from 'react-router'
import { getTokenDetails } from 'data/helpers/token'

export const PrivateReports = ({ component: Component, dealers, dateRange, setDateRange, ...rest }) => {
  const auth = getTokenDetails('userType') === '4'
  return (
    <Route
      {...rest}
      render={props =>
        auth ? (
          <Component dealers={dealers} dateRange={dateRange} setDateRange={setDateRange} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/'
            }}
          />
        )
      }
    />
  )
}
