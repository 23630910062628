import React, { useState, useMemo } from 'react'
import { Container, Wrapper } from './Styled'
import { TR } from './TR'
import { ArrowBox } from 'scenes/Reports/Fraud/Tables/FraudTable/ArrowBox'

export interface IData {
  ClientID: string
  color: string
  description: string
  make: string
  model: string
  stknum: string
  vinnum: string
  year: string
  dealerID: string
  location: string
}

export interface IForm {
  setShowForm: (param: boolean) => void
  setFormData: (param: IData) => void
}

export const Table: React.FC<{ data: IData[], form: IForm, dealers: any }> = ({
  data,
  form,
  dealers
}) => {
  let data_new: any[] = [];
  data_new = data.map((datum) => {
    return Object.assign(
      {
        location: dealers[datum.ClientID.toLowerCase()]
      },
      datum
    );
  });
  
  const [sortConfig, setSortConfig] = useState({
    key: 'location',
    order: 'asc'
  })
  
  const requestSort = (key: string) => {
    let order = 'asc'
    if (sortConfig.key === key && sortConfig.order === 'asc') {
      order = 'desc'
    }
    setSortConfig({ key, order })
  }
  
  let sortedRows = useMemo(() => {
    return data_new.sort((a: any, b: any) => {
      if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
        return sortConfig.order === 'asc' ? -1 : 1
      } else if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
        return sortConfig.order === 'asc' ? 1 : -1
      } else {
        return 0
      }
    })
  }, [data_new, sortConfig.key, sortConfig.order])
  
  
  
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>
              <Wrapper>
                <span className='fuelops__th-underline'>Edit</span>
              </Wrapper>
            </th>
            <th onClick={() => requestSort('location')}>
              <Wrapper highlight={sortConfig.key === 'location'}>
                <span className='fuelops__th-underline'>Location</span>
                {sortConfig.key === 'location' && (
                  <ArrowBox config={sortConfig} headerName='location' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('year')}>
              <Wrapper highlight={sortConfig.key === 'year'}>
                <span className='fuelops__th-underline'>Year</span>
                {sortConfig.key === 'year' && (
                  <ArrowBox config={sortConfig} headerName='year' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('make')}>
              <Wrapper highlight={sortConfig.key === 'make'}>
                <span className='fuelops__th-underline'>Make</span>
                {sortConfig.key === 'make' && (
                  <ArrowBox config={sortConfig} headerName='make' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('model')}>
              <Wrapper highlight={sortConfig.key === 'model'}>
                <span className='fuelops__th-underline'>Model</span>
                {sortConfig.key === 'model' && (
                  <ArrowBox config={sortConfig} headerName='model' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('stknum')}>
              <Wrapper highlight={sortConfig.key === 'stknum'}>
                <span className='fuelops__th-underline'>Stock Number</span>
                {sortConfig.key === 'stknum' && (
                  <ArrowBox config={sortConfig} headerName='stknum' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('vinnum')}>
              <Wrapper highlight={sortConfig.key === 'vinnum'}>
                <span className='fuelops__th-underline'>VIN</span>
                {sortConfig.key === 'vinnum' && (
                  <ArrowBox config={sortConfig} headerName='vinnum' />
                )}
              </Wrapper>
            </th>
            <th onClick={() => requestSort('description')}>
              <Wrapper highlight={sortConfig.key === 'description'}>
                <span className='fuelops__th-underline'>Description</span>
                {sortConfig.key === 'description' && (
                  <ArrowBox config={sortConfig} headerName='description' />
                )}
              </Wrapper>
            </th>
          </tr>
        </thead>
        {sortedRows.map((datum, idx) => (
          <TR
            data={datum}
            key={datum.stknum + datum.vinnum + datum.ClientID}
            lastRow={idx === sortedRows.length - 1}
            form={form}
          />
        ))}
      </table>
    </Container>
  )
}
