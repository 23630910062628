import React, { useState, useEffect } from 'react'
import { TBody } from './Styled'
import { statusFormatter } from 'data/helpers'
import { Modal } from 'core/components/Modal';
import { VoucherForm } from 'core/components/VoucherForm';
import { Voucher } from 'data/types'
import { ReturnDisplay } from 'scenes/Dashboard/components/Table/components/ReturnDisplay'
import { Wrapper } from 'scenes/Dashboard/components/Table/components/VoucherEdit/Styled'
import { checkInitialValues } from 'data/helpers'

export const TableBody: React.FC<{ data: Voucher[] }> = ({ data }) => {
  const [modalData, setModalData] = useState<any>({})
  const [returnedData, setReturnedData] = useState<any>({})
  const [showReturn, setShowReturn] = useState(false)
  const [showAdminModal, setShowAdminModal] = useState<boolean>(false)

  const handleClose = () => {
    setModalData({})
    setShowAdminModal(false)
    setReturnedData({})
    setShowReturn(false)
  }

  useEffect(() => {
    if (!showAdminModal) {
      setModalData({})
      setReturnedData({})
      setShowReturn(false)
    }
  }, [showAdminModal])

  const handleSelect = (id: string) => {
    const foundRow = data.find((row: Voucher) => row.voucherid === id)
    setModalData(foundRow)
    setShowAdminModal(true)
  }

  return (
    <TBody>
      <Modal
        isOpen={showAdminModal}
        onClose={handleClose}
      >
        {showReturn ? (
          <Wrapper>
            <ReturnDisplay
              returnedData={returnedData}
              setShowReturn={setShowReturn}
              setShowAdminModal={setShowAdminModal}
              type='admin'
            />
          </Wrapper>
        ) : (
          <VoucherForm
            initValues={checkInitialValues(modalData)}
            setReturnedData={setReturnedData}
            setShowReturn={setShowReturn}
            handleClose={handleClose}
            formType='admin'
          />
        )}
      </Modal>
      {data.map((row: any) => {
        return (
          <tr key={row.voucherid}>
            <td
              id='fuelops__recap-voucherid-td'
              onClick={() => handleSelect(row.voucherid)}
            >
              {row.voucherid}
            </td>
            <td>{row.clientid}</td>
            <td>{statusFormatter(row.status)}</td>
            <td>{row.amtnottoexceed}</td>
            <td>{formatText(row.stknum)}</td>
            <td>{formatText(row.vehcolor)}</td>
            <td>{formatText(row.vehmake)}</td>
            <td>{formatText(row.vehmodel)}</td>
            <td>{formatText(row.issuedto)}</td>
            <td>{formatText(row.departmentissue)}</td>
            <td>{formatText(row.issuedto)}</td>
            <td>{row.totalspent}</td>
            <td>{formatText(row.createdby)}</td>
          </tr>
        )
      })}
    </TBody>
  )
}

const formatText = (text: string) => {
  if (!text) {
    return 'N/A'
  }
  if (text.length > 10) {
    return text.substring(0, 9) + '...'
  }
  return text
}
