import React, { useState } from 'react'
import { Container, Wrapper, Icons, MainWrapper, InnerWrapper } from './Styled'
import { Icon } from './Icon'
import { Link } from 'react-router-dom'
import DealerOpsLogo from 'assets/images/dealeropsfourdots.png'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as ChartIcon } from 'assets/images/bar-chart.svg'
import { ReactComponent as UsersIcon } from 'assets/images/users.svg'
import { ReactComponent as CreateIcon } from 'assets/images/add-document.svg'
import { getTokenDetails } from 'data/helpers/token'
import VoucherCreate from 'scenes/Dashboard/components/Table/components/VoucherCreate'

export const Sidebar: React.FC<{ dealers: [any], setDateRange: any }> = ({ dealers, setDateRange, children }) => {
  const isDesktop = useMediaQuery({ minWidth: '768px' })
  const auth = getTokenDetails('userType')
  const [showCreate, setShowCreate] = useState(false)
  const [showAdminNav, setShowAdminNav] = useState(false)
  
  return (
    <MainWrapper>
      <InnerWrapper>
        <Container>
          {isDesktop && (
            <Link to='/'>
              <div id='fo__logo-container'>
                <div id='fo__logo-wrapper'>
                  <img src={DealerOpsLogo} alt='DealerOps Logo' />
                </div>
              </div>
            </Link>
          )}
          <Icons>
            {auth === '4' && (
              <Icon
                Icon={UsersIcon}
                admin={{ showAdminNav, setShowAdminNav }}
                type='admin'
                route='reports'
                tooltip='Reports'
              />
            )}
            <Link to='/'>
              <Icon Icon={ChartIcon} tooltip='Dashboard' route='dashboard' />
            </Link>
            {auth !== '3' && auth !== '5' && (
              <Icon
                Icon={CreateIcon}
                tooltip='Create Voucher'
                setShowModal={setShowCreate}
              />
            )}
          </Icons>
        </Container>
        <Wrapper fade={showAdminNav}>{children}</Wrapper>
      </InnerWrapper>
      <VoucherCreate show={showCreate} setShow={setShowCreate} dealers={dealers} setDateRange={setDateRange} />
    </MainWrapper>
  )
}
