import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faCheckCircle, faClipboardCheck, faEdit } from '@fortawesome/free-solid-svg-icons'
import { formatDatetime } from 'data/helpers/dates';

export const VoucherActions: React.FC = (props: any) => {
  const { setShowAdminModal, setAdminData, setCloseData, setShowCloseModal, role, data } = props
  
  return (
    <div className='fuelops__grid-actions'>
      {role === '4' &&
        <span
          className='fuelops__grid-action-item'
          onClick={() => {
            setAdminData({...data, changeddate: (data.changeddate ? formatDatetime(data.changeddate) : formatDatetime(data.createddate) )})
            setShowAdminModal(true)
          }}
        >
          <FontAwesomeIcon icon={faPencilAlt} fixedWidth size='lg' />
        </span>
      }
      {data.status !== 'F' &&
        <span
          className='fuelops__grid-action-item'
          onClick={() => {
            setCloseData({...data});
            setShowCloseModal(true);
          }}
        >
          <FontAwesomeIcon icon={faClipboardCheck} fixedWidth size='lg' />
        </span>
      }
    </div>
  )
}