import React from 'react'
import Fab from '@material-ui/core/Fab'
import PrintIcon from '@material-ui/icons/Print'
import { Container } from './Styled'

export const PrintButton: React.FC = () => {
  const handlePrint = () => {
    window.print()
  }
  return (
    <Container>
      <Fab color='secondary' variant='extended' onClick={handlePrint}>
        <PrintIcon />
        Print
      </Fab>
    </Container>
  )
}
