import React from 'react'
import { Container } from './Styled'
import moment from 'moment';

interface VoucherLabelProps {
  amtnottoexceed: number
  fillup: boolean
  issuedto: string
  otherreason: string
  stknum: string
  vehcolor: string
  vehmake: string
  vehmodel: string
  vehyr: number
  vinnum: string
  voucherid: string
  location: any
  departmentissue: string,
  createdate: string,
  createdby: string
}

export const VoucherInformation: React.FC<VoucherLabelProps> = ({
  location,
  amtnottoexceed,
  fillup,
  issuedto,
  otherreason,
  stknum,
  vehcolor,
  vehmake,
  vehmodel,
  vehyr,
  vinnum,
  voucherid,
  departmentissue,
  createdate,
  createdby
}) => {
  return (
    <Container>
      <table>
        <tbody>
        <tr>
          <td>
            <span>Voucher ID</span>
          </td>
          <td>
            <span>{voucherid}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Gas Requested By</span>
          </td>
          <td>
            <span>{issuedto}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Department</span>
          </td>
          <td>
            <span>{departmentissue}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Vehicle</span>
          </td>
          <td>
            <span>{vehyr} {vehcolor} {vehmake} {vehmodel}</span>
          </td>
        </tr>
        </tbody>
      </table>
      
      <table>
        <tbody>
        <tr>
          <td>
            <span>Stock/RO Number</span>
          </td>
          <td>
            <span>{stknum}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Reason</span>
          </td>
          <td>
            <span>{otherreason}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Issued By</span>
          </td>
          <td>
            <span>{createdby}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Issued</span>
          </td>
          <td>
            <span>{moment(createdate).format('MM/DD/YYYY')}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </Container>
  )
}
