import React from 'react'
import { TFoot } from './Styled'

export const TableFooter: React.FC<{ data: any }> = ({ data }) => {
  const totals = calculateFooterTotals(data)
  return (
    <TFoot>
      <tr>
        <td colSpan={3}>Totals</td>
        <td>{totals.issued.toFixed(2)}</td>
        <td colSpan={7} />
        <td>{totals.closed.toFixed(2)}</td>
        <td />
      </tr>
    </TFoot>
  )
}

const calculateFooterTotals = (data: any) => {
  const obj = { issued: 0, closed: 0 }
  data.forEach((record: any) => {
    obj.issued += record.amtnottoexceed
    obj.closed += record.totalspent
  })
  return obj
}
