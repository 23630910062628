import moment from 'moment'
import {
  FETCH_VOUCHERS_START,
  FETCH_VOUCHERS_SUCCESS,
  FETCH_VOUCHERS_UPDATE,
  FETCH_VOUCHERS_EDIT,
  FETCH_VOUCHERS_CLOSE,
  FETCH_VOUCHERS_DELETE,
  FETCH_VOUCHERS_FAILURE,
  FETCH_VOUCHERS_LOADING,
  FETCH_MAKES_SUCCESS,
  FETCH_MAKES_FAILURE
} from './actionTypes'

const initialState = {
  isFetching: false,
  isFirstLoad: true,
  error: null,
  vouchers: [],
  makes: []
}

export const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VOUCHERS_START:
      return {
        ...state,
        isFetching: true,
        error: null
      }
    case FETCH_VOUCHERS_LOADING:
      return {
        ...state,
        isFetching: true,
        error: null
      }
    case FETCH_VOUCHERS_SUCCESS:
      return {
        ...state,
        isFirstLoad: false,
        isFetching: false,
        vouchers: action.payload.data
      }
    case FETCH_VOUCHERS_UPDATE:
      const { createdate } = action.payload
      action.payload.createdate = moment(createdate).format('MM-DD-YYYY')
      const copiedVouchers = [...state.vouchers]
      copiedVouchers.unshift(action.payload)
      return {
        ...state,
        vouchers: copiedVouchers
      }
    case FETCH_VOUCHERS_CLOSE:
      const vouchers = state.vouchers.map(voucher => {
        if (voucher.voucherid === action.payload.voucherid) {
          return {
            ...voucher,
            ...action.payload
          }
        }
        return voucher
      })
      return {
        ...state,
        vouchers
      }
    case FETCH_VOUCHERS_EDIT:
      const { voucherid } = action.payload
      const editedVouchers = [...state.vouchers]
      const index = editedVouchers.findIndex(e => e.voucherid === voucherid)
      editedVouchers.splice(index, 1, action.payload)
      return {
        ...state,
        vouchers: editedVouchers
      }
    case FETCH_VOUCHERS_DELETE:
      const keptVouchers = state.vouchers.filter(
        voucher => voucher.voucherid !== action.payload
      )
      return {
        ...state,
        vouchers: keptVouchers
      }
    case FETCH_VOUCHERS_FAILURE:
      return {
        ...state,
        isFirstLoad: false,
        isFetching: false,
        error: action.payload
      }
    case FETCH_MAKES_SUCCESS:
      return {
        ...state,
        makes: action.payload.data
      }
    case FETCH_MAKES_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}
