import React, { useEffect, useRef, useState } from 'react'
import { Container } from './Styled'

import moment from 'moment'
// Why don't the current momentjs versions work?
// Instead, we're using the versions shipped with react-day-picker.
//import { formatDate, parseDate } from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareRight } from '@fortawesome/free-regular-svg-icons';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';



export const Dates = ({ dateRange: { startDate, endDate }, setDateRange }) => {
  const firstUpdate = useRef(true);
  const toPicker = useRef(null);
  const [from, setFrom] = useState(new Date(startDate + ' 00:00:00'));
  const [to, setTo] = useState(new Date(endDate + ' 00:00:00'));
  const [buttonActive, setButtonActive] = useState(false);
  
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    else {
      setButtonActive(true);
    }
  }, [from, to]);
  
  const handleClick = () => {
    setDateRange({
      startDate: moment(from).format('YYYY-MM-DD'),
      endDate: moment(to).format('YYYY-MM-DD')
    })
  };
  
  return (
    <Container>
      <div className='input-wrapper'>
        <DayPickerInput
          value={from}
          inputProps={{readOnly: true}}
          placeholder='From'
          format='MMM D, YYYY'
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, {from, to}],
            disabledDays: [{ after: moment().endOf('month').toDate() }, { after: to }],
            toMonth: to,
            modifiers: { start: from, end: to },
            numberOfMonths: 1,
            onDayClick: () => {
              toPicker.current.getInput().focus();
            },
          }}
          onDayChange={(from) => {
            setFrom(from);
          }}
        />{' '}
        —{' '}
          <DayPickerInput
            ref={toPicker}
            value={to}
            inputProps={{readOnly: true}}
            placeholder='To'
            format='MMM D, YYYY'
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, {from, to}],
              disabledDays: [{ after: moment().endOf('month').toDate() }, { before: from }],
              modifiers: { start: from, end: to },
              month: from,
              fromMonth: from,
              numberOfMonths: 1,
            }}
            onDayChange={(to) => {
              setTo(to);
            }}
          />
      </div>
      
      <button className='reload-button' disabled={!buttonActive} onClick={handleClick}>
        <FontAwesomeIcon icon={faCaretSquareRight} fixedWidth size='2x' />
      </button>
    </Container>
  )
}
