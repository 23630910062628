import moment from 'moment'

export const monthDiff = (past: any, selection: any) => {
  // @ts-ignore
  return new moment().diff(new moment(past), 'months') > selection
}

export const handleFilters = (items: any[], ...options: any[]) => {
  let filtItems = [...items]
  for (const option of options) {
    const [identifier, value] = option
    switch (identifier) {
      case 'startDate':
        filtItems = filtItems.filter(item =>
          moment(item.createdate).isAfter(value)
        )
        break
      case 'endDate':
        filtItems = filtItems.filter(item =>
          moment(item.createdate).isBefore(value)
        )
        break
      default:
        break
    }
  }
  return filtItems
}

export const formatDate = (date: any) => {
  return moment(date).format('MM/DD/YY')
}

export const formatDatetime = (date: any) => {
  return moment(date).format('MM/DD/YYYY h:mm a')
}

export const formatYear = (year: string) => {
  if (year.length === 2) {
    if (parseInt(year) >= 40) {
      return '19' + year;
    }
    else {
      return '20' + year;
    }
  }
  
  return year;
}