import React, { useCallback } from 'react'
import palette from 'google-palette'
import { ResponsiveBar } from '@nivo/bar'
import { Title } from 'core/components/Card/components/Title'
import { Container } from './Styled'

export const Graph = ({ data, setResetActive, handleGraphData }) => {
  const { keys, mArray } = data
  // const colors = palette('mpn65', 20).map(i => `#${i}`)

  const otherColors = [
    '#2c3e50',
    '#566573',
    '#808b96',
    '#abb2b9',
    '#c0c5cb',
    '#d5d8dc',
    '#e74b3c',
    '#e95d50',
    '#ec6f63',
    '#ee8177',
    '#f1938a',
    '#f3a59e',
    '#f5b7b1',
    '#f8c9c5',
  ]

  const getColors = useCallback((otherColors) => {
    const randColors = []
    while (randColors.length < 20) {
      const color = otherColors[Math.floor(Math.random() * otherColors.length)]
      if (!randColors[color]) {
        randColors.push(color)
      }
    }
    return randColors
  }, [])

  const handleClick = ({ id, indexValue }) => {
    handleGraphData({ id, indexValue })
  }
  
  return (
    <Container>
      <Title position={{ top: '10px', left: '15px' }} title={'trendline'} />
      <ResponsiveBar
        data={mArray}
        keys={keys}
        indexBy='date'
        margin={{ top: 50, right: 160, bottom: 80, left: 60 }}
        padding={0.5}
        colors={otherColors}
        onClick={handleClick}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10
          }
        ]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -75,
          legend: data.length < 7 ? 'Date' : '',
          legendPosition: 'middle',
          legendOffset: 32
        }}
        axisLeft={{
          format: e => Math.floor(e) === e && e,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Quantity',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </Container>
  )
}
