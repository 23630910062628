import { put, all, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import * as actionTypes from './actionTypes'

function* fetchUsers() {
  yield put({ type: actionTypes.FETCH_USERS_START })
  try {
    const { data } = yield axios.get(`${process.env.REACT_APP_BASEURL}/admin/users`)
    yield put({ type: actionTypes.FETCH_USERS_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: actionTypes.FETCH_USERS_FAILURE, payload: error })
  }
}

function* fetchUsersSaga() {
  yield takeLatest(actionTypes.FETCH_USERS, fetchUsers)
}

export default function* rootSaga() {
  yield all([fetchUsersSaga()])
}