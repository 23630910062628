import React, { useState } from 'react'
import { currencyFormatter, statusFormatter } from 'data/helpers'
import Checkbox from '@material-ui/core/Checkbox'
import { formatDate } from 'data/helpers/dates'
import { useMutation, gql } from '@apollo/client'

export const TR: React.FC<{
  voucher: any
  hideColumns: boolean
  openOverlay: (args: any) => void
}> = ({ voucher, hideColumns, openOverlay }) => {
  const [showExtra, setShowExtra] = useState(false)
  const [toggleChecked] = useMutation(gql`
    mutation ToggleChecked($id: String) {
      toggleChecked(id: $id) @client
    }
  `)

  const handleClick = (type: string, data?: any) => (event: any) => {
    event.stopPropagation()
    if (type === 'id') {
      openOverlay(data)
    } else if (hideColumns) {
      setShowExtra(!showExtra)
    }
  }

  return (
    <>
      <tr
        key={voucher.voucherid}
        // onClick={handleClick('row')}
        style={rowStyles}
      >
        <td style={{ paddingLeft: '10px' }}>
          <Checkbox
            checked={voucher.checked}
            onChange={() =>
              toggleChecked({ variables: { id: voucher.voucherid } })
            }
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </td>
        <td onClick={handleClick('id', voucher)} style={voucherIdStyles}>
          <button style={buttonStyles}>{voucher.voucherid}</button>
        </td>
        <td>{voucher.clientid}</td>
        <td>{statusFormatter(voucher.status)}</td>
        <td>{formatDate(voucher.createdate)}</td>
        {!hideColumns && <td>{voucher.vinnum}</td>}
        {!hideColumns && <td>{trimString(voucher.vehcolor)}</td>}
        {!hideColumns && <td>{voucher.vehmake}</td>}
        {!hideColumns && <td>{voucher.vehmodel}</td>}
        <td>{voucher.issuedto}</td>
        {!hideColumns && <td>{trimString(voucher.departmentissue)}</td>}
        <td>{currencyFormatter(voucher.amtnottoexceed)}</td>
        <td>{currencyFormatter(voucher.totalspent)}</td>
        <td>
          {currencyFormatter(voucher.totalspent - voucher.amtnottoexceed)}
        </td>
        <td>{trimString(voucher.createdby)}</td>
      </tr>
      {hideColumns && showExtra && (
        <tr style={{ borderRadius: '0px', borderTop: 'none' }}>
          <td colSpan={9}>
            <ul>
              <li>Make: {voucher.vehmake}</li>
              <li>Model: {voucher.vehmodel}</li>
              <li>Color: {voucher.vehcolor}</li>
            </ul>
            <ul>
              <li>VIN: {voucher.vinnum}</li>
              <li>Department Issue: {voucher.departmentissue || 'N/A'}</li>
            </ul>
          </td>
        </tr>
      )}
    </>
  )
}

const voucherIdStyles: any = {
  color: 'blue',
  fontWeight: 'bold',
  cursor: 'pointer'
}

const rowStyles: any = {
  cursor: 'pointer'
}

const buttonStyles: any = {
  border: '2px solid #2c3e50',
  padding: '1px 10px',
  outline: 'none',
  backgroundColor: '#fff',
  color: '#2c3e50'
}

const trimString = (value: string) => {
  if (!value) return
  if (value.length > 10) {
    return value.substring(0, 8) + '...'
  }
  return value
}
