import React, { useState } from 'react'
import moment from 'moment'
import { api } from 'data/apollo/api'
import { Tab } from './components/Tab'
import Draggable from 'react-draggable'
import { Card } from './components/Card'
import { useQuery } from '@apollo/client'
import { Container, Wrapper } from './Styled'
import { LogoAnim } from 'core/components/LogoAnim'
import { Dates } from 'core/components/Dates'
import { getTokenDetails } from 'data/helpers/token'
import ReconciliationTable from './components/Tables'
import { ErrorScreen } from 'core/components/ErrorScreen'
import { DashboardLayout } from 'core/components/DashboardLayout'

export const Reconciled: React.FC<{dealers: [any], dateRange: any, setDateRange: any}> = ({dealers, dateRange, setDateRange}) => {
  const userAccess = getTokenDetails('userAccess');
  
  const [showReconciled, setShowReconciled] = useState(false)
  const [minimize, setMinimize] = useState(false)
  const { startDate, endDate } = dateRange
  let { loading, error, data } = useQuery(api.remote.query.vouchersInRange, {
    variables: {
      startDate,
      endDate: moment(endDate).add(1, 'day').format('YYYY-MM-DD'),
      cids: userAccess
    }
  });
  
  if (data) {
    data = data.vouchersInRange.filter((datum: any) => {
      if (datum.status === 'P') {
        return false
      }
      if (showReconciled) {
        return true
      } else {
        return datum.Reconciled === false
      }
    });
    
    data = data.map((datum: any) => {
      return {
        ...datum,
        clientid: dealers[datum.clientid.toLowerCase()]
      }
    });
  }

  return (
    <Container>
      {loading && <LogoAnim />}
      {error && <ErrorScreen />}
      {!loading && data && (
        <>
          <DashboardLayout>
            <Dates dateRange={dateRange} setDateRange={setDateRange} />
          </DashboardLayout>
          <Tab minimize={{ minimize, setMinimize }} />
          <Draggable
            axis='both'
            bounds='html'
            handle='.handle'
            defaultClassName='reconciliation__card-wrapper'
            defaultPosition={{ x: 100, y: 500 }}
            position={undefined}
            grid={[1, 1]}
            scale={1}
          >
            <div
              className='handle'
              style={{
                width: '22rem',
                cursor: 'pointer',
                position: 'fixed'
              }}
            >
              {data && (
                <Card data={data} minimize={{ minimize, setMinimize }} />
              )}
            </div>
          </Draggable>
          <Wrapper>
            <ReconciliationTable
              data={data}
              showReconciled={showReconciled}
              setShowReconciled={setShowReconciled}
            />
          </Wrapper>
        </>
      )}
    </Container>
  )
}
