import React, { useState, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { statusFormatter } from 'data/helpers'
import { formatDate } from 'data/helpers/dates'
import { useLazyQuery } from '@apollo/client'
import { api } from 'data/apollo/api'
import {
  TBody,
  Row,
  TR2,
  NestedTableWrapper
} from './Styled'
import { ReactComponent as Arrow } from 'assets/images/down-arrow.svg'
import { TableHeader } from './TableHeader'
import { TableBody } from './TableBody'
import { TableFooter } from './TableFooter'
// @ts-ignore
import ReactPaginate from 'react-paginate'

export const TR: React.FC<{
  data: any
  rowName: string
  tableName: string
}> = ({ data, rowName, tableName }) => {
  const [expanded, setExpanded] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [dataAfterChange, setDataAfterChange] = useState<any>(null)
  const [showCompletedModal, setCompletedModal] = useState(false)
  const rowTotals = calculateRowTotals(data, tableName)
  const [offset, setOffset] = useState(0)
  const [nestedTableSort, setNestedTableSort] = useState({
    key: 'voucherid',
    order: 'desc'
  })
  const RESULTS_PER_PAGE = 10

  const sortedData = useMemo(() => {
    return data.sort((a: any, b: any) => {
      let a1, b1
      if (
        typeof a[nestedTableSort.key] !== 'number' &&
        a[nestedTableSort.key] !== null &&
        b[nestedTableSort.key] !== null
      ) {
        a1 = a[nestedTableSort.key].toLowerCase()
        b1 = b[nestedTableSort.key].toLowerCase()
      } else {
        a1 = a[nestedTableSort.key]
        b1 = b[nestedTableSort.key]
      }
      if (a1 < b1) {
        return nestedTableSort.order === 'asc' ? -1 : 1
      } else if (a1 > b1) {
        return nestedTableSort.order === 'asc' ? 1 : -1
      } else {
        return 0
      }
    })
  }, [data, nestedTableSort.key, nestedTableSort.order])

  const handleExpand = () => {
    setExpanded(!expanded)
  }

  const handlePageClick = (data: any) => {
    let selected = data.selected
    setOffset(selected)
  }

  const requestSort = (key: string) => {
    let order = 'asc'
    if (nestedTableSort.key === key && nestedTableSort.order === 'asc') {
      order = 'desc'
    }
    setNestedTableSort({ key, order })
  }

  const paginatedData = sortedData.filter((_: any, idx: number) => {
    if (offset === 0) {
      return idx < RESULTS_PER_PAGE
    }
    return (
      idx >= offset * RESULTS_PER_PAGE && idx < (offset + 1) * RESULTS_PER_PAGE
    )
  })

  return (
    <TBody>
      <Row expanded={expanded}>
        <td>{rowName}</td>
        <td className='fuelops__recap-tfoot-td1'>
          {rowTotals[rowName].pending.total}
        </td>
        <td className='fuelops__recap-tfoot-td2'>
          {rowTotals[rowName].pending.amount.toFixed(2)}
        </td>
        <td className='fuelops__recap-tfoot-spacer' />{/* Extra td for spacing */}
        <td className='fuelops__recap-tfoot-td1'>
          {rowTotals[rowName].closed.total}
        </td>
        <td className='fuelops__recap-tfoot-td2'>
          {rowTotals[rowName].closed.amount.toFixed(2)}
        </td>
        <td className='fuelops__recap-tfoot-spacer' />{/* Extra td for spacing */}
        <td className='fuelops__recap-tfoot-td1'>
          {rowTotals[rowName].total.total}
        </td>
        <td className='fuelops__recap-tfoot-td2'>
          {rowTotals[rowName].total.amount.toFixed(2)}
        </td>
        <td onClick={handleExpand}>
          <div id='fuelops__table-borderdiv' />
          <Arrow
            fill='#ccc'
            width='12px'
            transform={`rotate (${expanded ? '0' : '180'})`}
          />
        </td>
      </Row>
      {expanded && (
        <TR2 style={{ borderTop: 'none' }} expanded={expanded}>
          <NestedTableWrapper
            paginated={data.length > RESULTS_PER_PAGE}
            colSpan={10}
          >
            <table>
              <TableHeader sorting={{ nestedTableSort, requestSort }} />
              <TableBody data={paginatedData} />
              <TableFooter data={data} />
            </table>
            {data.length > RESULTS_PER_PAGE && (
              <div id='fuelops__recap-pagination-wrapper'>
                <ReactPaginate
                  previousLabel={offset === 0 ? '' : '<'}
                  nextLabel={
                    offset === Math.ceil(data.length / RESULTS_PER_PAGE) - 1
                      ? ''
                      : '>'
                  }
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={Math.ceil(data.length / RESULTS_PER_PAGE)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'paginate-active'}
                />
              </div>
            )}
          </NestedTableWrapper>
        </TR2>
      )}
    </TBody>
  )
}

const calculateRowTotals = (data: any, tableName: string) => {
  const arrData: any = {}
  for (const datum of data) {
    if (!arrData[datum[tableName]]) {
      arrData[datum[tableName]] = {
        pending: { total: 0, amount: 0 },
        closed: { total: 0, amount: 0 },
        total: { total: 0, amount: 0 }
      }
    }
    const user = arrData[datum[tableName]]
    const { pending, closed, total } = user
    if (datum.status === 'P') {
      pending.amount += datum.amtnottoexceed
      pending.total++
      total.amount += datum.amtnottoexceed
      total.total++
    }
    if (datum.status === 'F') {
      closed.amount += datum.amtnottoexceed
      closed.total++
      total.amount += datum.amtnottoexceed
      total.total++
    }
  }
  return arrData
}
