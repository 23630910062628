export const handleMakes = (make: string) => {
  switch (make.toLowerCase()) {
    case 'acur':
    case 'acura':
      return 'Acura'
    case 'alfa':
    case 'alfa-romeo':
    case 'alfa romeo':
      return 'Alfa-Romeo'
    case 'aston martin':
      return 'Aston Martin'
    case 'audi':
      return 'Audi'
    case 'bentley':
      return 'Bentley'
    case 'bmw':
      return 'BMW'
    case 'buic':
    case 'buick':
      return 'Buick'
    case 'cadi':
    case 'cadillac':
      return 'Cadillac'
    case 'chev':
    case 'chevy':
    case 'chevrolet':
      return 'Chevrolet'
    case 'chry':
    case 'chrys':
    case 'chrysler':
      return 'Chrysler'
    case 'Dawwoo':
      return 'Daewoo'
    case 'daihatsu':
      return 'Daihatsu'
    case 'dodge':
      return 'Dodge'
    case 'ferrari':
      return 'Ferrari'
    case 'fiat':
      return 'Fiat'
    case 'ford':
      return 'Ford'
    case 'general motors':
      return 'General Motors'
    case 'gen':
    case 'genesis':
      return 'Genesis'
    case 'gmc':
      return 'GMC'
    case 'honda':
      return 'Honda'
    case 'hyun':
    case 'hyund':
    case 'hyundai':
      return 'Hyundai'
    case 'infin':
    case 'infini':
    case 'infinity':
    case 'infiniti':
      return 'Infiniti'
    case 'isuzu':
      return 'Isuzu'
    case 'jagu':
    case 'jaguar':
      return 'Jaguar'
    case 'jeep':
      return 'Jeep'
    case 'kia':
      return 'Kia'
    case 'lamborghini':
      return 'Lamborghini'
    case 'land rover':
      return 'Land rover'
    case 'lex':
    case 'lexu':
    case 'lexus':
      return 'Lexus'
    case 'linc':
    case 'lincoln':
      return 'Lincoln'
    case 'lotus':
      return 'Lotus'
    case 'maserati':
      return 'Maserati'
    case 'maybach':
      return 'Maybach'
    case 'mazd':
    case 'mazda':
      return 'Mazda'
    case 'mb':
    case 'merc':
    case 'merce':
    case 'mercedes':
      return 'Mercedes'
    case 'mercury':
      return 'Mercury'
    case 'mini':
      return 'Mini'
    case 'mits':
    case 'mitsu':
    case 'mitsubishi':
      return 'Mitsubishi'
    case 'nis':
    case 'niss':
    case 'nissan':
      return 'Nissan'
    case 'oldsmobile':
      return 'Oldsmobile'
    case 'other':
      return 'Other'
    case 'peugeot':
      return 'Peugeot'
    case 'pontiac':
      return 'Pontiac'
    case 'polestar':
      return 'Polestar'
    case 'pors':
    case 'porsche':
      return 'Porsche'
    case 'renault':
      return 'Renault'
    case 'rolls-royce':
    case 'rolls':
    case 'rolls royce':
      return 'Rolls-Royce'
    case 'saab':
      return 'Saab'
    case 'scion':
      return 'Scion'
    case 'shelby':
      return 'Shelby'
    case 'suba':
    case 'subaru':
      return 'Subaru'
    case 'saturn':
      return 'Saturn'
    case 'suzuki':
      return 'Suzuki'
    case 'tesla':
      return 'Tesla'
    case 'toyo':
    case 'toyota':
      return 'Toyota'
    case 'volv':
    case 'volvo':
      return 'Volvo'
    case 'vw':
    case 'volkswagen':
      return 'Volkswagen'
    default:
      return make
  }
}
