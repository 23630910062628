import axios from 'axios'

export const currencyFormatter = (data: number | string) => {
  if (typeof data === 'string') {
    data = parseInt(data)
  }
  return `$${data.toFixed(2)}`
}

export const statusFormatter = (status: string) => {
  switch (status) {
    case 'F':
      return 'Closed'
    case 'P':
      return 'Open'
  }
}

export const issuingDepartment = [
  'New Delivery',
  'Used Delivery',
  'New CSI',
  'Used CSI',
  'SVC CSI',
  'SVC RO',
  'SVC PDI',
  'B/S RO',
  'Co. Vehicle - SVC',
  'Co. Vehicle - Demo Units',
  'Co. Vehicle - Parts',
  'Co. Vehicle - B/S',
  'Co. Vehicle - Rental',
  'Co. Vehicle - Other',
  'Other - Enter a Reason'
]

const names = [
  'status',
  'createdate',
  'stknum',
  'vehmake',
  'vehmodel',
  'issuedto',
  'departmentissue',
  'dollarsallowed',
  'totalspent',
  'createdby',
  'vehyr'
]

export const checkInitialValues = (initVals: any) => {
  let val: boolean | string
  for (val in initVals) {
    initVals[val] = initVals[val] ?? ''
  }
  return initVals
}

export const addActions = (cols: any) => {
  cols.unshift({
    headerName: 'Actions',
    headerClass: 'text-center',
    cellClass: 'no-focus',
    cellRenderer: 'actionsRenderer',
    colId: 'voucherAction',
    maxWidth: 115,
    minWidth: 115,
    pinned: 'left'
  })
}

export const showCols = (name: string): { hide: boolean } => {
  const names = [
    'clientid',
    'status',
    'createdate',
    'stknum',
    'vehmake',
    'vehmodel',
    'issuedto',
    'departmentissue',
    'amtnottoexceed',
    'totalspent',
    'createdby',
    'vehyr'
  ]
  const hide = !names.includes(name)
  return { hide }
}

export const setWidth = (name: string) => {
  switch (name) {
    case 'vehmake':
      return 70
    case 'status':
    case 'vehyr':
      return 50
    case 'dollarsallowed':
    case 'totalspent':
    case 'createdate':
      return 80
    default:
      return 90
  }
}

export const nameExchange: { [key: string]: string } = {
  voucherid: 'VoucherID',
  clientid: 'Location',
  createdby: 'Created By',
  createdate: 'Create Date',
  gallonsallowed: 'Gallons Allowed',
  dollarsallowed: 'Dollars Allowed',
  gallonsused: 'GallonsUsed',
  totalspent: 'Total Spent',
  issuedto: 'Issued To',
  status: 'Voucher Status',
  pricepergallon: 'PricePerGallon',
  stknum: 'Stock Number',
  vinnum: 'VIN',
  vehyr: 'Year',
  vehmake: 'Make',
  vehmodel: 'Model',
  vehcolor: 'Color',
  amtnottoexceed: 'Allotted Amount',
  fillup: 'FillUp',
  vtype: 'VehicleStockType',
  completedate: 'CompletedDate',
  userID: 'UserName',
  void: 'Void',
  voidreason: 'VoidReason',
  completedby: 'CompletedBy',
  changedby: 'ChangedBy',
  changeddate: 'ChangedDate',
  departmentissue: 'Department',
  ronum: 'RepairOrderNumber',
  otherreason: 'ReasonForOther'
}

export const shiftHeaders = (name: keyof typeof nameExchange) =>
  nameExchange[name]

export const value = (curr: string) => {
  switch (curr) {
    case 'dollarsallowed':
    case 'totalspent':
    case 'amtnottoexceed':
      return currency
    case 'status':
      return status
    default:
      return null
  }
}

export const type = (curr: string) => {
  switch (curr) {
    case 'dollarsallowed':
      return 'rightAligned'
    default:
      return null
  }
}

const status = (val: any) => {
  const {
    colDef: { field },
    data: { status }
  } = val
  if (field !== 'status') {
    return val.value
  }
  switch (status) {
    case 'P':
      return 'Open'
    case 'F':
      return 'Closed'
    case 'V':
      return 'Void'
    default:
      return null
  }
}

const currency = (val: any) => {
  return `$${val.value}`
}

export const setFormVersion = (type: string, values: any) => {
  switch (type) {
    case 'create-new':
    case 'create-existing':
      return axios.post(`${process.env.REACT_APP_BASEURL}/vouchers`, {
        ...values
      })
    case 'close':
      const { voucherid, totalspent } = values
      return axios.patch(`${process.env.REACT_APP_BASEURL}/vouchers-close`, {
        voucherid,
        totalspent
      })
    case 'admin':
      return axios.patch(`${process.env.REACT_APP_BASEURL}/vouchers`, {
        ...values
      })
    default:
      throw new Error('You must supply an endpoint type')
  }
}

export const getSubmittingEndpoint = (type: string, values: any) => {
  switch (type) {
    case 'create-new':
    case 'create-existing':
      return axios.post(`${process.env.REACT_APP_BASEURL}/vouchers`, {
        ...values
      })
    case 'close':
      const { voucherid, totalspent } = values
      return axios.patch(`${process.env.REACT_APP_BASEURL}/vouchers-close`, {
        voucherid,
        totalspent
      })
    case 'admin':
      return axios.patch(`${process.env.REACT_APP_BASEURL}/vouchers`, {
        ...values
      })
    default:
      throw new Error('You must supply an endpoint type')
  }
}

export const getMetricValues = (data: any) => {
  return {
    open: ovOpenCalc(data),
    closed: ovClosedCalc(data),
    outstanding: ovOutstanding(data)
  }
}

const ovOpenCalc = (vouchers: any) =>
  vouchers.reduce((acc: any, curr: any) => {
    if (curr.status === 'P') {
      return acc + 1
    }
    return acc
  }, 0)

const ovClosedCalc = (vouchers: any) =>
  vouchers.reduce((acc: any, curr: any) => {
    if (curr.status === 'F') {
      return acc + 1
    }
    return acc
  }, 0)

const ovOutstanding = (vouchers: any) => {
  return vouchers.reduce((acc: any, curr: any) => {
    if (curr.status === 'P') {
      return acc + +curr.dollarsallowed
    }
    return acc
  }, 0)
}