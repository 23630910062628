import React, { useContext, useState } from 'react'
import { ModalWrapper } from './Styled'
import { EditContext } from 'scenes/Reports/Recap/components/EditRow'
import { handleMakes } from 'data/helpers/form'
import { Modal } from 'core/components/Modal';
import { VoucherForm } from 'core/components/VoucherForm';

export const Overlay: React.FC<{
  sharedData?: any
  state?: any
  closeModal?: any
  Component?: any
}> = ({ sharedData, closeModal, state, Component }) => {
  const { setSharedData } = useContext<any>(EditContext)
  const [showReturn, setShowReturn] = useState(false)
  const [finalModalData, setFinalModalData] = useState({})
  const handleClose = closeModal || setSharedData
  const {
    amtnottoexceed,
    voucherid,
    createdate,
    stknum,
    status,
    vehcolor,
    vehmake,
    vehmodel,
    vehyr,
    fillup,
    vinnum,
    issuedto,
    departmentissue,
    totalspent,
    createdby,
    clientid
  } = sharedData
  
  const initialValues = {
    amtnottoexceed: (typeof(amtnottoexceed === 'string') ? parseFloat(amtnottoexceed) : amtnottoexceed).toFixed(2),
    stknum,
    issuedto,
    vehmake: handleMakes(vehmake ?? '') ?? '',
    vehmodel,
    vehcolor,
    vehyr,
    fillup,
    vinnum,
    departmentissue: departmentissue ?? '',
    totalspent: (typeof(totalspent === 'string') ? parseFloat(totalspent) : totalspent).toFixed(2),
    createdby,
    createdate,
    status,
    voucherid,
    clientid
  }

  const handleModal = (data: any) => {
    setFinalModalData(data)
  }

  const returnVal = { ...initialValues, voucherid: '' }

  return (
    <Modal
      isOpen={true}
      onClose={handleClose}
    >
      {!showReturn ? (
        <VoucherForm
          initValues={initialValues}
          handleClose={() => handleClose(returnVal)}
          setReturnedData={handleModal}
          setShowReturn={() => setShowReturn(true)}
          formType='admin'
        />
      ) : (
        <ModalWrapper>
          <Component
            returnedData={finalModalData}
            setShowAdminModal={() => closeModal({})}
            type='admin'
          />
        </ModalWrapper>
      )}
    </Modal>
  )
}
