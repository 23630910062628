import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7fbff;
  font-family: Montserrat;
  min-height: 100vh;
  // filter: blur(3px);
  
  .reconciliation__card-wrapper {
    z-index: 1;
  }
`

export const Wrapper = styled.div`
  padding: 0 20px;
  margin-top: 30px;
`