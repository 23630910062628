import React, { useState } from 'react'
import { Container, Wrapper } from './Styled'
import { DashboardLayout } from 'core/components/DashboardLayout'
import { Header } from './Header'
import { Table } from './Table'
import { getTokenDetails } from 'data/helpers/token'
import { useQuery } from '@apollo/client'
import { api } from 'data/apollo/api'
import { LogoAnim } from 'core/components/LogoAnim'
import { ErrorScreen } from 'core/components/ErrorScreen'
import { Form } from './Form'

export const Permanent: React.FC<{dealers: [any]}> = ({dealers}) => {
  const userAccess = getTokenDetails('userAccess');
  const [showForm, setShowForm] = useState(false)
  const [formData, setFormData] = useState<any>(null)
  const { loading, error, data } = useQuery(api.remote.query.permanentStock, {
    variables: {
      cids: userAccess
    }
  })
  
  if (loading) {
    return <LogoAnim />
  }
  
  if (error) {
    return <ErrorScreen />
  }
  
  return (
    <Container>
      {showForm && (
        <Form dealers={dealers} setShowForm={setShowForm} form={{ formData, setFormData }} />
      )}
      <DashboardLayout />
      <Wrapper>
        <Header setShowForm={setShowForm} />
        <Table data={data.permanentStock} dealers={dealers} form={{ setShowForm, setFormData }} />
      </Wrapper>
    </Container>
  )
}
