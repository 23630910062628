import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const TextWrapper = styled.div`
  display: flex;
  font-family: Montserrat, sans-serif;
  flex-direction: column;
  height: calc(100vh - 45px);
  justify-content: center;
  align-items: center;

  img {
    max-width: 734px;
    max-height: 550px;

    width: 70%;
    height: auto;
  }
`
