import React, { useState } from 'react'
import { ArrowBox } from 'scenes/Reports/Fraud/Tables/FraudTable/ArrowBox'
import { Container, Wrapper } from './Styled'
import { TR } from './TR'
import cloneDeep from 'lodash/cloneDeep'
import { Voucher } from 'data/types'

export const Table: React.FC<{
  data: Record<string, Voucher>
  tableName: string
}> = ({ data: recData, tableName }) => {
  const data = cloneDeep(recData)
  const footerData = calculateFooterData(data)
  const [sortConfig] = useState({
    key: 'voucherid',
    order: 'desc'
  })

  return (
    <Container>
      <h5>Recap by {headerConversion[tableName]}</h5>
      <table>
        <thead>
          <tr>
            <th>
              <Wrapper first highlight={sortConfig.key === 'createdby'}>
                <span className='fuelops__th-underline'>
                  {headerConversion[tableName]}
                </span>
                {sortConfig.key === 'createdby' && (
                  <ArrowBox config={sortConfig} headerName='createdby' />
                )}
              </Wrapper>
            </th>
            <th colSpan={2}>
              <Wrapper highlight={sortConfig.key === 'status'}>
                <span className='fuelops__th-underline'>Pending</span>
                {sortConfig.key === 'status' && (
                  <ArrowBox config={sortConfig} headerName='status' />
                )}
              </Wrapper>
            </th>
            <th className='fuelops__recap-tfoot-spacer' />
            {/* Extra th element for spacing */}
            <th colSpan={2}>
              <Wrapper highlight={sortConfig.key === 'createdate'}>
                <span className='fuelops__th-underline'>Closed</span>
                {sortConfig.key === 'createdate' && (
                  <ArrowBox config={sortConfig} headerName='createdate' />
                )}
              </Wrapper>
            </th>
            <th className='fuelops__recap-tfoot-spacer' />
            <th colSpan={2}>
              <Wrapper highlight={sortConfig.key === 'issuedto'}>
                <span className='fuelops__th-underline'>Total</span>
                {sortConfig.key === 'issuedto' && (
                  <ArrowBox config={sortConfig} headerName='issuedto' />
                )}
              </Wrapper>
            </th>
            <th className='fuelops__recap-tfoot-spacer' />
            {/* Extra th element for spacing */}
          </tr>
        </thead>
        {Object.keys(data).map((row: any) => (
          <TR key={row} data={data[row]} rowName={row} tableName={tableName} />
        ))}
        {Object.keys(data).length > 1 && (
          <tfoot>
            <tr>
              <td>Totals</td>
              <td className='fuelops__recap-tfoot-td1'>{footerData.pC}</td>
              <td className='fuelops__recap-tfoot-td2'>
                {footerData.pT.toFixed(2)}
              </td>
              <td className='fuelops__recap-tfoot-spacer' />
              {/* Extra td element for table spacing */}
              <td className='fuelops__recap-tfoot-td1'>{footerData.cC}</td>
              <td className='fuelops__recap-tfoot-td2'>
                {footerData.cT.toFixed(2)}
              </td>
              <td className='fuelops__recap-tfoot-spacer' />
              {/* Extra td element for table spacing */}
              <td className='fuelops__recap-tfoot-td1'>{footerData.tC}</td>
              <td className='fuelops__recap-tfoot-td2'>
                {footerData.tT.toFixed(2)}
              </td>
              <td className='fuelops__recap-tfoot-spacer' />
              {/* Extra td element for table spacing */}
            </tr>
          </tfoot>
        )}
      </table>
    </Container>
  )
}

const headerConversion: { [key: string]: string } = {
  createdby: 'User',
  departmentissue: 'Department',
  vehmake: 'Vehicle Make',
  vehcolor: 'Vehicle Color',
  stknum: 'Stock Number'
}

const calculateFooterData = (data: any) => {
  const obj = { pC: 0, pT: 0, cC: 0, cT: 0, tC: 0, tT: 0 }
  for (const key in data) {
    data[key].forEach((voucher: any) => {
      if (voucher.status === 'F') {
        obj.cC++
        obj.cT += voucher.amtnottoexceed
      } else {
        obj.pC++
        obj.pT += voucher.amtnottoexceed
      }
      obj.tC++
      obj.tT += voucher.amtnottoexceed
    })
  }
  return obj
}
