import React, { useState } from 'react'
import { Wrapper } from './Styled'
import { ReturnDisplay } from '../ReturnDisplay'
import { checkInitialValues } from 'data/helpers'
import { VoucherForm } from 'core/components/VoucherForm';

import { ReactComponent as Logo } from 'assets/images/dots.svg';
import { Col, Modal, Row, Typography } from 'antd';

const { Text } = Typography;

const VoucherEdit: React.FC<{show: boolean; data: any; showClose: any}> = ({ show, data, showClose }) => {
  const initialValues = checkInitialValues(data)
  const [returnedData, setReturnedData] = useState<any>({})
  const [showReturn, setShowReturn] = useState(false)

  const handleClose = () => {
    showClose(false);
    setShowReturn(false);
  }
  
  return (
    <Modal
      footer={null}
      onCancel={handleClose}
      onOk={handleClose}
      title={(
        <Row justify="space-around" align="middle" style={{fontSize: '18px'}}>
          <Col flex="50px">
            <Logo className='logo' style={{maxWidth: '30px', maxHeight: '30px'}}/>
          </Col>
          <Col flex="auto" style={{textAlign: 'center'}}>
            <Text>Voucher: <strong>{data.voucherid}</strong></Text>
          </Col>
          <Col flex="50px"></Col>
        </Row>
      )}
      visible={show}
      width='90%'
      style={{maxWidth: '1000px'}}
    >
      {showReturn ? (
        <Wrapper>
          <ReturnDisplay
            returnedData={returnedData}
            setShowReturn={setShowReturn}
            setShowAdminModal={handleClose}
            type='admin'
          />
        </Wrapper>
      ) : (
        <VoucherForm
          initValues={initialValues}
          setReturnedData={setReturnedData}
          setShowReturn={setShowReturn}
          handleClose={handleClose}
          formType='admin'
        />
      )}
    </Modal>
  )
}

export default VoucherEdit