import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
  gql
} from '@apollo/client'
import { setContext } from '@apollo/link-context'
import store from 'store'
import moment from 'moment'

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BASEURL}/api`
})

const authLink = setContext((_, { headers }) => {
  const token = store.get('foAccess')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const resolvers: any = {
  FuelTracker: {
    checked: (voucher: any) => voucher.selected || false
  },
  Mutation: {
    toggleChecked: (_: any, args: any, ctx: any) => {
      const id = ctx.getCacheKey({
        voucherid: args.id,
        __typename: 'FuelTracker'
      })
      const fragment = gql`
        fragment voucherSelect on FuelTracker {
          checked
        }
      `
      const voucher = ctx.cache.readFragment({ fragment, id })
      const data = { checked: !voucher.checked }
      ctx.cache.writeFragment({ id, fragment, data })
      return null
    }
  }
}

const cache = new InMemoryCache({
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case 'VehicleMakes':
        return object.MakeID
      case 'FuelTracker':
        return object.voucherid
      case 'FuelTrackerStock':
        return object.stknum
      default:
        return defaultDataIdFromObject(object)
    }
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  resolvers
})

cache.writeQuery({
  query: gql`
    {
      dates @client {
        startDate
        endDate
      }
      recapDates @client {
        startDate
        endDate
      }
      modal @client {
        visible
        modalType
        image
      }
      createMenu @client {
        visible
      }
    }
  `,
  data: {
    dates: {
      __typename: 'CalendarDates',
      startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    },
    modal: {
      __typename: 'ModalProperties',
      visible: false,
      modalType: '',
      image: ''
    },
    createMenu: {
      __typename: 'CreateVoucherModal',
      visible: false
    }
  }
})
