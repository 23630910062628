import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Wrapper } from './Styled'
import { SearchResults } from './components/SearchResults'
import { fetchVouchersUpdate } from 'data/store/vouchers/actions'
import { initialValues } from 'data/schema'
import { VoucherForm } from 'core/components/VoucherForm';

import { ReactComponent as Logo } from 'assets/images/dots.svg';
import { Col, Modal, Row, Typography } from 'antd';

const { Text } = Typography;

const VoucherCreate = (props: any) => {
  const [formType, setFormType] = useState('');
  const [initValues, setInitValues] = useState(initialValues)
//  const inputEl = useRef<HTMLInputElement>(null)
  
  const handleClose = () => {
    props.setShow(false);
    setFormType('');
  }
  
//  useEffect(() => {
//    if (inputEl && inputEl.current) {
//      inputEl.current.focus()
//    }
//  }, [])
  
  return (
    
    <Modal
      destroyOnClose={true}
      footer={null}
      onCancel={() => props.setShow(false)}
      onOk={handleClose}
      title={(
        <Row justify="space-around" align="middle" style={{fontSize: '18px'}}>
          <Col flex="50px">
            <Logo className='logo' style={{maxWidth: '30px', maxHeight: '30px'}}/>
          </Col>
          <Col flex="auto" style={{textAlign: 'center'}}>
            <Text>Create Voucher</Text>
          </Col>
          <Col flex="50px"></Col>
        </Row>
      )}
      visible={props.show}
      width='90%'
      style={{maxWidth: '1000px'}}
    >
      <div id='modalBody'>
        {formType === '' ? 
          <Wrapper>
            <SearchResults
              dealers={props.dealers}
              setFormType={setFormType}
              setInitValues={setInitValues}
            />
          </Wrapper>
        :
          <VoucherForm
            initValues={initValues}
            handleClose={handleClose}
            formType={formType}
            dealers={props.dealers}
            setDateRange={props.setDateRange}
          />
        }
      </div>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  updateVouchers: (data: any) => dispatch(fetchVouchersUpdate(data))
})

export default connect(null, mapDispatchToProps)(VoucherCreate)
