import * as yup from 'yup'
import { getTokenDetails } from 'data/helpers/token'

export const initValues = {
  ClientID: getTokenDetails('clientid'),
  year: '',
  make: '',
  model: '',
  stknum: '',
  vinnum: '',
  description: '',
  color: ''
}

export const schema = yup.object({
  ClientID: yup.string().required('Required'),
  year: yup
    .string()
    .required('Required')
    .length(4, 'Must be four digits')
    .test('isAmountNumeric', 'Values can only be digits', value => {
      if (!value) {
        return false
      }
      if (!value.match(/^\d+\.?\d+$/)) {
        return false
      }
      return true
    }),
  make: yup.string().required('Required'),
  model: yup.string().required('Required'),
  stknum: yup.string().required('Required'),
  vinnum: yup
    .string()
    .required('Required')
    .test('isVinValid', 'VIN must be 8 or 17 characters', val => {
      if (!val) {
        return false
      }
      return val.length === 8 || val.length === 17
    }),
  description: yup.string().required('Required'),
  color: yup.string().required('Required')
})
