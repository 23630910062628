import styled from 'styled-components'

export const Container = styled.div<{ showMenu: boolean }>`
  height: 38px;
  margin-left: 20px;

  #fraud-options__button {
    margin-top: 2px;
    background-color: ${props => (props.showMenu ? '#f0f0f0' : '#f4f4f5')};
    border: 1.5px solid #ccc;
    padding: 5px 15px;
    border-radius: 6px;
    outline: none;
  }

  #fraud-options__button:hover {
    background-color: #f0f0f0;
  }

  #fraud-options__menu {
    margin-top: 5px;
    background-color: #fff;
    box-shadow: 0 0 2px 2px #e7e7e7;
    // height: 165px;
    width: 250px;
    border-radius: 5px;
    padding: 15px;
  }
`
