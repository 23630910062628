import styled from 'styled-components'

export const Wrapper = styled.div`
  h4 {
    text-decoration: underline;
    margin: 10px 0 20px;
  }
`

export const ReturnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  #title {
    text-align: center;
    margin: 15px 0;
  }

  span {
    font-weight: bold;
    font-size: 0.9rem;
    color: ${props => props.theme.main.charcoal};
    margin: 0;

    span {
      color: red;
    }
  }
`
