import React from 'react'
import { Container } from './Styled'
import logo from 'assets/images/fuelops-full.svg'

export const Header: React.FC<{ data: any }> = ({ data: { dealerInfo } }) => {
  const { address1, city, dealername, dirphone, state, zip } = dealerInfo
  return (
    <Container>
      <div id='fuelops__po-title'>
        <img src={logo} alt='FuelOps Logo' />
        <h3>Gas Purchase Order</h3>
      </div>
      <div id='fuelops__dealership-info'>
        <ul>
          <li>{dealername}</li>
          <li>{address1}</li>
          <li>
            {city}, {state} {zip}
          </li>
          <li>{dirphone}</li>
        </ul>
      </div>
    </Container>
  )
}
