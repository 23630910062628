import React, { useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Container, Filters, Wrapper, ButtonWrapper } from './Styled'
import { FilterByText } from './components/FilterByText'
import { MDBBtn } from 'mdbreact'
import { Graph } from '../Graph'
import * as types from 'data/types'
import {
  addActions,
  showCols,
  shiftHeaders,
  value
} from 'data/helpers'
import {
  VoucherActions
} from 'core/components/Buttons'

import VoucherEdit from './components/VoucherEdit'
import VoucherClose from './components/VoucherClose'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { getTokenDetails } from 'data/helpers/token'
// @ts-ignore
import randomColor from 'randomcolor'

export const Table: React.FC<types.IVouchers> = ({ vouchers, visibility }) => {
  const [tableHeaders, setTableHeaders] = useState<any[]>([])
  const [rowData, setRowData] = useState<any[]>([])
  const [status, setStatus] = useState('A')
  const [resetActive, setResetActive] = useState(false)
  const [gridApi, setGridApi] = useState<any>(null)
  const [columnApi, setColumnApi] = useState<any>(null)
  const [showAdminModal, setShowAdminModal] = useState<boolean>(false)
  const [showCloseModal, setShowCloseModal] = useState<boolean>(false)
  const [adminData, setAdminData] = useState({})
  const [closeData, setCloseData] = useState({})
  const [showHighlight, setShowHighlight] = useState(false)
  const role = getTokenDetails('userType')
  
  
  useEffect(() => {
    setStatus('A');
    if (vouchers.length) {
      handleSetData(vouchers)
      handleRowData(vouchers);
    }
  }, [vouchers])
  
  const handleSetData = (data: any[]) => {
    const initObject = Object.keys(data[0])
    const columnDefs = initObject.reduce<any[]>(
      (acc, curr: string) => [
        ...acc,
        {
          headerName: shiftHeaders(curr),
          field: curr,
          sortable: true,
          resizable: true,
          valueFormatter: value(curr),
          ...showCols(curr)
        }
      ],
      []
    )
    
    if (role === '4' || role === '2' || role === '3') {
      addActions(columnDefs);
    }

    setTableHeaders(columnDefs)
  }
  
  const handleRowData = (data: any[]) => {
    setRowData(data)
  }
  
  const handleGraphData = (data: { id: number; indexValue: string }) => {
    const voucherFilterComponent = gridApi.getFilterInstance('createdby')
    const dateFilterComponent = gridApi.getFilterInstance('createdate')
    voucherFilterComponent.setModel({
      type: 'startsWith',
      filter: data.id
    })
    dateFilterComponent.setModel({
      type: 'startsWith',
      filter: data.indexValue
    })
    gridApi.onFilterChanged()
    setResetActive(true)
  }
  
  const voucherStatus = (filter: types.StatusType) => {
    const voucherFilterComponent = gridApi.getFilterInstance('status')
    if (filter === 'A') {
      voucherFilterComponent.setModel(null)
    } else {
      voucherFilterComponent.setModel({
        type: 'startsWith',
        filter
      })
    }
    setStatus(filter)
    gridApi.onFilterChanged()
  }
  
  const updateFilterText = (e: string) => {
    gridApi.setQuickFilter(e)
  }
  
  const handleChart = (data: any) => {
    const initObject: any = {}
    const peopleKeys: any = {}
    const mArray: any = []
    data.forEach(({ createdby, createdate }: any) => {
      if (initObject[createdate]) {
        if (initObject[createdate][createdby]) {
          ++initObject[createdate][createdby]
        } else {
          initObject[createdate][createdby] = 1
          initObject[createdate][`${createdby}Color`] = randomColor({
            seed: initObject[createdate][createdby],
            format: 'hsl'
          })
        }
      } else {
        initObject[createdate] = {}
        initObject[createdate][createdby] = 1
        initObject[createdate][`${createdby}Color`] = randomColor({
          seed: initObject[createdate][createdby],
          format: 'hsl'
        })
      }
    })
    data.forEach((item: any) => {
      if (!peopleKeys[item.createdby]) {
        return (peopleKeys[item.createdby] = true)
      }
    })
    for (let date in initObject) {
      mArray.push({ date, ...initObject[date] })
    }
    mArray.sort((a: any, b: any) => +new Date(a.date) - +new Date(b.date))
    return { keys: Object.keys(peopleKeys), mArray }
  }
  
  const handleReset = () => {
    setResetActive(false)
    gridApi.setFilterModel(null)
  }
  
  return (
    <Container>
      <VoucherEdit show={showAdminModal} data={adminData} showClose={setShowAdminModal} />
      <VoucherClose show={showCloseModal} data={closeData} showClose={setShowCloseModal} />
      <Wrapper vis={visibility}>
        {vouchers.length && (
          <div id='main__graph-wrapper'>
            <Graph
              setResetActive={setResetActive}
              handleGraphData={handleGraphData}
              data={handleChart(vouchers)}
            />
          </div>
        )}
        <Filters>
          <ButtonWrapper
            active={status === 'A'}
            onClick={() => voucherStatus(types.StatusType.A)}
          >
            <div className='fuelops__button-highlight' />
            <h5>all</h5>
          </ButtonWrapper>
          <ButtonWrapper
            active={status === 'P'}
            onClick={() => voucherStatus(types.StatusType.P)}
          >
            <div className='fuelops__button-highlight' />
            <h5>open</h5>
          </ButtonWrapper>
          <ButtonWrapper
            active={status === 'F'}
            onClick={() => voucherStatus(types.StatusType.F)}
          >
            <div className='fuelops__button-highlight' />
            <h5>closed</h5>
          </ButtonWrapper>
          <ButtonWrapper active={showHighlight} type='search'>
            <FilterByText
              updateFilterText={updateFilterText}
              setShowHighlight={setShowHighlight}
              title='Filter'
            />
          </ButtonWrapper>
          {resetActive && (
            <ButtonWrapper active={showHighlight}>
              <MDBBtn onClick={handleReset}>Reset Filtering By Graph</MDBBtn>
            </ButtonWrapper>
          )}
        </Filters>
        <div id='wrapper' className='ag-theme-material'>
          <AgGridReact
            domLayout='autoHeight'
            rowSelection='single'
            columnDefs={tableHeaders}
            frameworkComponents={{
              actionsRenderer: (props: any) =>
              VoucherActions(
                {
                  setAdminData,
                  setCloseData,
                  setShowAdminModal,
                  setShowCloseModal,
                  role,
                  ...props
                }
              ),
            }}
            pagination={true}
            rowData={rowData}
            onGridReady={params => {
              setGridApi(params.api)
              setColumnApi(params.columnApi)
              params.api.sizeColumnsToFit()
            }}
          />
        </div>
      </Wrapper>
    </Container>
  )
}
