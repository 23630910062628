export const FETCH_VOUCHERS_START = 'FETCH_VOUCHERS_START'
export const FETCH_VOUCHERS_SUCCESS = 'FETCH_VOUCHERS_SUCCESS'
export const FETCH_VOUCHERS_UPDATE = 'FETCH_VOUCHERS_UPDATE'
export const FETCH_VOUCHERS_EDIT = 'FETCH_VOUCHERS_EDIT'
export const FETCH_VOUCHERS_CLOSE = 'FETCH_VOUCHERS_CLOSE'
export const FETCH_VOUCHERS_DELETE = 'FETCH_VOUCHERS_DELETE'
export const FETCH_VOUCHERS_FAILURE = 'FETCH_VOUCHERS_FAILURE'
export const FETCH_VOUCHERS_LOADING = 'FETCH_VOUCHERS_LOADING'

export const FETCH_MAKES_START = 'FETCH_MAKES_START'
export const FETCH_MAKES_SUCCESS = 'FETCH_MAKES_SUCCESS'
export const FETCH_MAKES_FAILURE = 'FETCH_MAKES_FAILURE'