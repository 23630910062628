import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  width: auto;
  flex-direction: column;
  align-items: center;
  transition: width 1s ease-out;
  padding-top: 2rem;
  
  #found-search-results {
    margin-top: 15px;
    width: 400px;
  }

`