import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  #fuelops__po-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
      width: 248px;
      height: auto;
    }
  }

  #fuelops__dealership-info {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      text-align: right;
    }
  }
`
