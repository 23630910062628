import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from './Styled'
import Logo from 'assets/images/fuelopslogo.png'
import { MDBBtn } from 'mdbreact'

export const ShowNull: React.FC<{
  times: { startTime: number; endTime: number }
}> = ({ times: { startTime, endTime } }) => {
  const [timer, setTimer] = useState(5)
  const history = useHistory()
  const displayCountdown = Math.abs(endTime - startTime) > 1000

  useEffect(() => {
    if (displayCountdown) {
      const interval = setInterval(() => {
        if (timer > 0) {
          setTimer((t: any) => t - 1)
        } else {
          history.replace('/')
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [timer, history, displayCountdown])

  const handleClick = () => {
    history.push('/')
  }

  return (
    <Container>
      <div>
        <img src={Logo} alt='FuelOps Logo' />
      </div>
      <p>You have attempted to print this voucher</p>
      {displayCountdown ? (
        <p>You will be redirected in... {timer}</p>
      ) : (
        <MDBBtn onClick={handleClick}>Back to Dashboard</MDBBtn>
      )}
    </Container>
  )
}
