import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  tspan {
    font-family: Montserrat !important;
  }
`

export const Wrapper = styled.div`
  width: 550px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 2px 2px #e7e7e7;

  th {
    min-width: 100px;
    padding: 8px 5px;
  }

  thead {
    tr:first-child {
      border-top: none;
    }
  }

  tr {
    border-top: 2px solid #f2f2f2;
  }

  td {
    padding: 8px 5px;
    color: ${props => props.theme.main.charcoal};
  }

  tfoot {
    tr {
      background-color: #f4f4f5;
      border-radius: 5px;
    }
  }

  .chart-inner-text {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding-top: 40%;
    text-align: center;
    font-size: 13px;
  }

  .chart-inner-value {
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5c596d;
    font-size: 15px;
    padding-bottom: 5px;
  }
`
