import React from 'react'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'
import { initialValues } from 'data/schema'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    width: 100%;
    margin-left: 100px;
  }

  li {
    width: 80%;
    display: flex;
    justify-content: space-between;
  }
`

export const ReturnDisplay = ({
  returnedData,
  setShowReturn,
  setInitValues,
  setShowAdminModal,
  type
}: {
  returnedData: any
  setShowReturn?: any
  setInitValues?: any
  setShowAdminModal?: (arg: boolean) => void
  type?: string
}) => {
  return (
    <Container>
      <ul style={{ padding: 0, listStyleType: 'none' }}>
        <li>
          <strong>VOUCHER ID:</strong>
          {returnedData.voucherid}
        </li>
        <li>
          <strong>CLIENT ID:</strong>
          {returnedData.clientid}
        </li>
        <li>
          <strong>CREATED BY:</strong>
          {returnedData.createdby}
        </li>
        <br />
        <li>
          <strong>STOCK NUMBER:</strong>
          {returnedData.stknum}
        </li>
        <li>
          <strong>GALLONS ALLOWED:</strong>
          {returnedData.gallonsallowed}
        </li>
        <li>
          <strong>DOLLARS ALLOWED:</strong>
          {returnedData.dollarsallowed}
        </li>
        <br />
        <li>
          <strong>VIN:</strong>
          {returnedData.vinnum}
        </li>
        <li>
          <strong>VEHICLE YEAR:</strong>
          {returnedData.vehyr}
        </li>
        <li>
          <strong>VEHICLE MAKE:</strong>
          {returnedData.vehmake}
        </li>
        <li>
          <strong>VEHICLE COLOR:</strong>
          {returnedData.vehcolor}
        </li>
      </ul>
      <Button
        onClick={() => {
          switch (type) {
            case 'admin':
              if (setShowAdminModal) {
                setShowAdminModal(false)
              }
              break
            default:
              setShowReturn(false)
              setInitValues(initialValues)
              break
          }
        }}
      >
        {type === 'admin' ? 'CLOSE' : 'CREATE ANOTHER'}
      </Button>
    </Container>
  )
}
