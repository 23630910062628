import React, { useState } from 'react'
import { MDBInput } from 'mdbreact'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'

interface IFilterByText {
  updateFilterText: (e: string) => void
  setShowHighlight: (arg: boolean) => void
  title?: string
}

interface IProps {
  highlight: boolean
}

const Wrapper = styled.div<IProps>`
  text-transform: none;

  h5 {
    transition: 0.2s all ease-out;
    color: ${props =>
      !props.highlight
        ? props.theme.header.dropdownBorder
        : props.theme.main.dark};
  }
`

const useStyles = makeStyles({
  root: {
    fontFamily: 'Montserrat, sans-serif'
  }
})

export const FilterByText: React.FC<IFilterByText> = ({
  updateFilterText,
  setShowHighlight
}) => {
  const classes = useStyles()
  const [highlight, setHighlight] = useState(false)
  const [text, setText] = useState('')

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const { value } = e.target as HTMLInputElement
    updateFilterText(value)
    setText(value)
  }

  const handleFocus = () => {
    setShowHighlight(true)
  }

  const handleBlur = () => {
    if (text) {
      return
    }
    setShowHighlight(false)
  }

  return (
    <Wrapper highlight={highlight}>
      {/* <MDBInput
        outline
        label='Search...'
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        size='sm'
        value={text}
      /> */}
      <TextField
        id='outlined-basic'
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={text}
        label='Search...'
        variant='outlined'
        size='small'
        inputProps={{ style: { fontFamily: 'Montserrat' } }}
        className={classes.root}
      />
    </Wrapper>
  )
}
