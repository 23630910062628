import React from 'react'
import { Container } from './Styled'

export const AuthorizationDetails: React.FC<{
  amtnottoexceed: number,
  fillup: boolean
}> = ({ amtnottoexceed, fillup }) => {
  return (
    <Container>
      <div className='box-1'>Max Gasoline Amount:</div>
      <div className='box-2'>
        {fillup
          ? 'Fill-up' 
          : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amtnottoexceed)
        }
      </div>
      <div className='box-3' />
      <div className='box-4'>Authorizing Manager:</div>
      <div className='box-5' />
      <div className='box-6'>Printed Comments:</div>
    </Container>
  )
}
