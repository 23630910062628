export interface IVouchers {
  vouchers: any
  visibility: boolean
  showCreate: boolean
  setShowCreate?: (arg: boolean) => void
}

export enum StatusType {
  A = 'A',
  P = 'P',
  F = 'F'
}

export type FormType = 'admin' | 'create-new' | 'create-existing' | 'close' | string

export interface InitValues {
  createdby: string
  amtnottoexceed: string
  fillup?: boolean
  issuedto?: string
  otherreason?: string
  stknum?: string
  vehcolor?: string
  vehmake?: string
  vehmodel?: string
  departmentissue?: string
  vehyr?: string
  vinnum?: string
  status?: string
  changedby?: string
  changeddate?: string
  totalspent?: string
  voucherid?: string
  createdate?: string
  clientid?: string
  location?: string
}

export interface Voucher extends InitValues {
  Reconciled: boolean
  checked?: boolean
  __typename: string
}
