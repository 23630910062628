import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  height: calc(100vh - 90px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  
  svg {
    height: 120px;
    width: 120px;
  }
`
