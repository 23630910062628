import React, { useState } from 'react'
import { Container } from './Styled'
import OCH from 'react-outside-click-handler'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export const Options: React.FC<{
  setHideColumns: any
  hideColumns: boolean
  showOnlySelected: boolean
  setShowOnlySelected: any
  paginate: boolean
  setPaginate: any
}> = ({
  setHideColumns,
  hideColumns,
  showOnlySelected,
  setShowOnlySelected,
  paginate,
  setPaginate
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const handleOutsideClick = () => {
    if (!showMenu) {
      return
    }
    setShowMenu(false)
  }
  return (
    <Container showMenu={showMenu}>
      <OCH onOutsideClick={handleOutsideClick} display='contents'>
        <button
          id='fraud-options__button'
          onClick={() => setShowMenu(!showMenu)}
        >
          Options
        </button>
        {showMenu && (
          <div id='fraud-options__menu'>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={hideColumns}
                    onChange={() => setHideColumns(!hideColumns)}
                    color='secondary'
                    name='checkedB'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label='Condensed View'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showOnlySelected}
                    onChange={() => setShowOnlySelected(!showOnlySelected)}
                    color='secondary'
                    name='checkedB'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label='Show Selected'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={paginate}
                    onChange={() => setPaginate(!paginate)}
                    color='secondary'
                    name='checkedB'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label='Paginate Results'
              />
            </FormGroup>
          </div>
        )}
      </OCH>
    </Container>
  )
}
