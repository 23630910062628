import styled from 'styled-components'

export const THead = styled.thead`
  th {
    padding: 3px 2px;
    border-top: 2px solid #f2f2f2;
    color: #fff;
    background-color: #606877;
    text-align: center;
  }

  th:first-child {
    border-radius: 5px 0 0 0;
    padding-left: 10px;
    text-align: left;
  }

  th:last-child {
    border-radius: 0 5px 0 0;
  }
`

export const Wrapper = styled.div<{ highlight: boolean }>`
  display: inline-block;
  position: relative;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.main.light};

  .fuelops__th-underline {
    border-bottom: 1px solid
      ${props =>
        props.highlight ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0)'};
    transition: all 0.3s linear;
  }
`
