import React, { useMemo } from 'react'
import { Container } from './Styled'
import { DashboardLayout } from 'core/components/DashboardLayout'
import { Dates } from 'core/components/Dates'
import { Tables } from './Tables'
import { useQuery } from '@apollo/client'
import { api } from 'data/apollo/api'
import { getTokenDetails } from 'data/helpers/token'
import { LogoAnim } from 'core/components/LogoAnim'
import { ErrorScreen } from 'core/components/ErrorScreen'
import moment from 'moment'

export const Fraud: React.FC<{dealers: [any], dateRange: any, setDateRange: any}> = ({dealers, dateRange, setDateRange}) => {
  const userAccess = getTokenDetails('userAccess');
  
  const { startDate, endDate } = dateRange
  const { loading, error, data } = useQuery(api.remote.query.vouchersInRange, {
    variables: {
      startDate,
      endDate: moment(endDate).add(1, 'day').format('YYYY-MM-DD'),
      cids: userAccess
    }
  });
  
  
  const newData = useMemo(() => {
    if (!data) {
      return
    }
    
    const temp = data.vouchersInRange.map((datum: any) => {
      return {
        ...datum,
        clientid: dealers[datum.clientid.toLowerCase()]
      }
    });
    
    return temp;
  }, [data])
  
  
  if (loading) {
    return <LogoAnim />
  }
  
  if (error) {
    return <ErrorScreen />
  }
  
  return (
    <Container>
      <DashboardLayout>
        <Dates dateRange={dateRange} setDateRange={setDateRange} />
      </DashboardLayout>
      <Tables data={newData} />
    </Container>
  )
}
