import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  float: right;
  justify-self: flex-end;
  height: 100%;

  .fo__navbar-logout {
    display: flex;
    align-items: center;
    border-left: 2px solid #ccc;
    cursor: pointer;
    padding: 0 20px;
    transition: all 1s ease-out;

    :hover {
      svg {
        fill: #ddd;
      }
    }

    svg {
      width: 22px;
      fill: #55585e;
    }
  }
`
