import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding-bottom: 20px;
  font-size: 12px;
  color: #55585e;
  width: 100%;
  padding: 20px 0;

  tbody {
    background-color: #fff;
  }

  table {
    box-shadow: 0 20px 50px #476481;
  }

  thead {
    cursor: pointer;
    font-size: 10px;
    text-transform: uppercase;
    background-color: #fff;
    border-bottom: 2px solid #f2f2f2;

    th:first-child {
      padding-left: 15px;
      border-radius: 6px 0 0 0;
      // border-left: 2px solid #f2f2f2;
      min-width: 80px;
      cursor: default;
    }

    th:last-child {
      border-radius: 0 6px 0 0;
      padding-right: 15px;
      // border-right: 2px solid #f2f2f2;
    }

    th {
      min-width: 115px;
      padding: 15px 5px;
      // border-top: 2px solid #f2f2f2;
    }

    th:last-child {
      min-width: 40px;
    }
  }

  #recon__dots-dots-dots {
    min-width: 110px;
  }
`

export const Wrapper = styled.div<{ highlight?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #55585e;

  .fuelops__th-underline {
    border-bottom: 1px solid
      ${props =>
        props.highlight ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0)'};
    transition: all 0.3s linear;
  }
`
