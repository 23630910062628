import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { api } from 'data/apollo/api'
import { useHistory } from 'react-router-dom'
import { InitValues, FormType } from 'data/types'
import { getTokenDetails } from 'data/helpers/token'
import _clone from 'lodash/cloneDeep'
import _includes from 'lodash/includes';
import Cleave from 'cleave.js/react';
import { formatYear } from 'data/helpers/dates';
import { useApolloClient } from '@apollo/client';
import _map from 'lodash/map';
import moment from 'moment';

import { Button, Card, Col, Descriptions, Form, Input, List, Row, Select, Switch } from 'antd';

export const VoucherForm: React.FC<{
  initValues: InitValues
  setReturnedData?: (arg: any) => void
  setShowReturn?: any
  handleClose: (event?: any) => void
  formType: FormType
  dealers?: any
  setDateRange?: any
}> = ({
  initValues,
  setReturnedData,
  setShowReturn,
  handleClose,
  formType,
  dealers,
  setDateRange
}) => {
  
  const client = useApolloClient();
  const { makes } = client.readQuery<any>({ query: api.remote.query.makes });
  const availableMakes = makes.map((make: { Make: string }) => (make.Make));
  
  if (initValues.location === undefined && initValues.clientid !== undefined) {
    initValues.location = initValues.clientid;
  }
  
  // If we're creating a non-inventory voucher,
  // we'll just populate the clientid with the first choice.
  if (formType === 'create-new') {
    initValues.clientid = Object.keys(dealers)[0].toLowerCase();
  }
  
  if (!availableMakes.includes(initValues.vehmake ?? '')) {
    initValues.vehmake = 'Other';
  }
  
  const availableDepartments = [
    'New Delivery',
    'Used Delivery',
    'New CSI',
    'Used CSI',
    'SVC CSI',
    'SVC RO',
    'SVC PDI',
    'B/S RO',
    'Co. Vehicle - SVC',
    'Co. Vehicle - Demo Units',
    'Co. Vehicle - Parts',
    'Co. Vehicle - B/S',
    'Co. Vehicle - Rental',
    'Co. Vehicle - Other',
    'Other - Enter a reason'
  ];
  
  if (!availableDepartments.includes(initValues.departmentissue ?? '')) {
    initValues.departmentissue = '';
  }
  
  initValues.totalspent = parseFloat(initValues.totalspent ? initValues.totalspent : '0').toFixed(2);
  
  if (formType === 'close' && parseFloat(initValues.totalspent) === 0) initValues.totalspent = '';
  
  const history = useHistory();
  const username = getTokenDetails('username');
  
  const [closeVoucher] = useMutation(api.remote.mutation.closeVoucher, {
    onCompleted: data => {
      setReturnedData!(data.closeVoucher);
    }
  });
  
  const [createVoucher] = useMutation(api.remote.mutation.createVoucher, {
    onCompleted: data => {
      setDateRange({
          startDate: moment().startOf('month').format('YYYY-MM-DD'),
          endDate: moment().endOf('month').format('YYYY-MM-DD')
      });
      handleClose()
      history.push('/print-voucher', {
        dataValues: {
          ...data.createVoucher,
          createdby: username
        }
      })
      return
    }
  });
  
  const [editVoucher] = useMutation(api.remote.mutation.editVoucher, {
    update(cache, { data: { editVoucher } }) {
      if (editVoucher.status === 'V') {
        cache.modify({
          fields: {
            vouchersInRange(existingVouchers, { readField }) {
              const copiedVouchers = _clone(existingVouchers)
              return copiedVouchers.filter(
                (voucher: any) =>
                  readField('voucherid', voucher) !== editVoucher.voucherid
              )
            }
          }
        })
      }
    },
    onCompleted: ({ editVoucher }) => {
      if (setReturnedData) {
        setReturnedData(editVoucher)
      }
    }
  });
  
  const [issue, setIssue] = useState(initValues.departmentissue);
  const [fillup, setFillup] = useState(initValues.fillup);
  const [submitting, setSubmitting] = useState(false);
  
  const [form] = Form.useForm();
  
  // We need to reset the form fields when the initValues change.
  // This should occur every time the modal form is opened.
  useEffect(() => {
    form.setFieldsValue(initValues);
    form.resetFields();
    setFillup(initValues.fillup);
    setIssue(initValues.departmentissue);
  }, [form, initValues]);
  
  
  return (
    <Form
      autoComplete='off'
      form={form}
      initialValues={initValues}
      labelCol={{span: 8}}
      onFinish={async (values) => {
        setSubmitting(true);
        
        try {
          switch (formType) {
            case 'close':
              await closeVoucher({
                variables: {
                  ...values,
                  completedby: username,
                  changedby: username,
                  totalspent: +values.totalspent!,
                  voucherid: initValues.voucherid
                }
              });
              setSubmitting(false);
              setShowReturn(true);
              break
              
            case 'create-new':
              if (values.fillup) {
                values.amtnottoexceed = '0.00'
              }
              createVoucher({
                variables: {
                  ...values,
                  amtnottoexceed: +values.amtnottoexceed,
                  vehyr: values.vehyr + '',
                  changedby: username,
                  createdby: username
                }
              });
              break
              
            case 'create-existing':
              if (values.fillup) {
                values.amtnottoexceed = '0.00'
              }
              
              createVoucher({
                variables: {
                  ...values,
                  amtnottoexceed: +values.amtnottoexceed,
                  vehyr: initValues.vehyr,
                  vehmake: initValues.vehmake,
                  vehmodel: initValues.vehmodel,
                  vehcolor: initValues.vehcolor,
                  clientid: initValues.clientid ? initValues.clientid.toLowerCase() : '',
                  stknum: initValues.stknum,
                  vinnum: initValues.vinnum,
                  changedby: username,
                  createdby: username
                }
              });
              break
              
            case 'recap':
              await editVoucher({
                variables: {
                  ...values,
                  changedby: username,
                  amtnottoexceed: +values.amtnottoexceed
                }
              });
              setSubmitting(false)
              setShowReturn(true)
              break
              
            case 'admin':
              await editVoucher({
                variables: {
                  ...values,
                  changedby: username,
                  amtnottoexceed: +values.amtnottoexceed,
                  totalspent: +values.totalspent!,
                  voucherid: initValues.voucherid
                }
              });
              setSubmitting(false)
              setShowReturn(true)
          }
        }
        catch (e) {
//          console.log(e);
          console.log('Error submitting form');
          setSubmitting(false);
        }
      }}
      preserve={false}
      spellCheck='false'
      wrapperCol={{span: 16}}
    >

        {formType === 'admin' && (
          <Card style={{marginBottom: '24px'}}>
            <Descriptions
              column={{ md: 3, sm: 2, xs: 1 }}
              layout='vertical'
            >
              <Descriptions.Item
                contentStyle={{justifyContent: 'center'}}
                label='Created By'
                labelStyle={{justifyContent: 'center', fontWeight: 600, width: '100%'}}
              >
                { initValues.createdby }
              </Descriptions.Item>
              <Descriptions.Item
                contentStyle={{justifyContent: 'center'}}
                label='Last Changed By'
                labelStyle={{justifyContent: 'center', fontWeight: 600, width: '100%'}}
              >
                { initValues.changedby }
              </Descriptions.Item>
              <Descriptions.Item
                contentStyle={{justifyContent: 'center'}}
                label='Changed Date'
                labelStyle={{justifyContent: 'center', fontWeight: 600, width: '100%'}}
              >
                { initValues.changeddate }
              </Descriptions.Item>
            </Descriptions>
          </Card>
        )}
        
        <Row gutter={24}>
          <Col xs={24} md={12}>
            {(formType === 'create-new') ?
              <Card size='small' title='Vehicle Information' bodyStyle={{padding: '24px'}} headStyle={{fontSize: '16px'}} style={{marginBottom: '24px'}}>
                <Form.Item
                  htmlFor='clientid'
                  label='Location'
                  labelCol={{span: 8}}
                  name='clientid'
                  rules={[
                    {required: true, message: 'Required'}
                  ]}
                  style={{marginBottom: '0', marginTop: '0'}}
                >
                  <Select id='clientid'>
                  {
                    _map(dealers, (location: string, clientid: string) => {
                      return <Select.Option key={clientid.toLowerCase()} value={clientid.toLowerCase()}>{location}</Select.Option>;
                    })
                  }
                  </Select>
                </Form.Item>
                
                <Form.Item
                  htmlFor='vinnum'
                  label='VIN'
                  labelCol={{span: 8}}
                  name='vinnum'
                  rules={[
                    {pattern: /^\S{8}$|^\S{17}$/, message: 'VIN must be 8 or 17 characters'},
                    {required: true, message: 'Required'}
                  ]}
                  style={{marginBottom: '0', marginTop: '0.6em'}}
                >
                  <Input autoFocus={formType === 'create-new'} id='vinnum' maxLength={17}/>
                </Form.Item>
                
                <Form.Item
                  htmlFor='stknum'
                  label='Stock #'
                  labelCol={{span: 8}}
                  name='stknum'
                  rules={[
                    {required: true, message: 'Required'}
                  ]}
                  style={{marginBottom: '0', marginTop: '0.6em'}}
                >
                  <Input id='stknum'/>
                </Form.Item>
                
                <Form.Item
                  htmlFor='vehyr'
                  label='Year'
                  labelCol={{span: 8}}
                  name='vehyr'
                  rules={[
                    {pattern: /(^(19|20)[0-9]{2}$)|(^[0-9]{2}$)/, message: 'Invalid Year'},
                    {required: true, message: 'Required'}
                  ]}
                  style={{marginBottom: '0', marginTop: '0.6em'}}
                >
                  <Cleave
                    className='ant-input'
                    id='vehyr'
                    options={{
                      date: true,
                      datePattern: ['Y']
                    }}
                    onBlur={event => {
                      form.setFieldsValue({vehyr: formatYear(event.target.value)});
                    }}
                  />
                </Form.Item>
                
                <Form.Item
                  htmlFor='vehmake'
                  label='Make'
                  labelCol={{span: 8}}
                  name='vehmake'
                  rules={[
                    {required: true, message: 'Required'}
                  ]}
                  style={{marginBottom: '0', marginTop: '0.6em'}}
                >
                  <Select id='vehmake'>
                  {
                    availableMakes.map((make: string) => {
                      return <Select.Option key={make} value={make}>{make}</Select.Option>;
                    })
                  }
                  </Select>
                </Form.Item>
                
                <Form.Item
                  htmlFor='vehmodel'
                  label='Model'
                  labelCol={{span: 8}}
                  name='vehmodel'
                  rules={[
                    {required: true, message: 'Required'}
                  ]}
                  style={{marginBottom: '0', marginTop: '0.6em'}}
                >
                  <Input id='vehmodel'/>
                </Form.Item>
                
                <Form.Item
                  htmlFor='vehcolor'
                  label='Color'
                  labelCol={{span: 8}}
                  name='vehcolor'
                  rules={[
                    {required: true, message: 'Required'}
                  ]}
                  style={{marginBottom: '0', marginTop: '0.6em'}}
                >
                  <Input id='vehcolor'/>
                </Form.Item>
              </Card>
            :
              <Card size='small' title='Vehicle Information' bodyStyle={{padding: '0'}} headStyle={{fontSize: '16px'}} style={{marginBottom: '24px'}}>
                <List size='small'>
                  <List.Item style={{display: 'flex'}}>
                    <span style={{fontWeight: 600, marginRight: '0.5em', textAlign: 'right', width: '30%'}}>Location:</span> <span style={{flexGrow: 1}}>{initValues.location}</span>
                  </List.Item>
                  <List.Item style={{display: 'flex'}}>
                    <span style={{fontWeight: 600, marginRight: '0.5em', textAlign: 'right', width: '30%'}}>VIN:</span> <span style={{flexGrow: 1}}>{initValues.vinnum}</span>
                  </List.Item>
                  <List.Item style={{display: 'flex'}}>
                    <span style={{fontWeight: 600, marginRight: '0.5em', textAlign: 'right', width: '30%'}}>Stock #:</span> <span style={{flexGrow: 1}}>{initValues.stknum}</span>
                  </List.Item>
                  <List.Item style={{display: 'flex'}}>
                    <span style={{fontWeight: 600, marginRight: '0.5em', textAlign: 'right', width: '30%'}}>Year:</span> <span style={{flexGrow: 1}}>{initValues.vehyr}</span>
                  </List.Item>
                  <List.Item style={{display: 'flex'}}>
                    <span style={{fontWeight: 600, marginRight: '0.5em', textAlign: 'right', width: '30%'}}>Make:</span> <span style={{flexGrow: 1}}>{initValues.vehmake}</span>
                  </List.Item>
                  <List.Item style={{display: 'flex'}}>
                    <span style={{fontWeight: 600, marginRight: '0.5em', textAlign: 'right', width: '30%'}}>Model:</span> <span style={{flexGrow: 1}}>{initValues.vehmodel}</span>
                  </List.Item>
                  <List.Item style={{display: 'flex'}}>
                    <span style={{fontWeight: 600, marginRight: '0.5em', textAlign: 'right', width: '30%'}}>Color:</span> <span style={{flexGrow: 1}}>{initValues.vehcolor}</span>
                  </List.Item>
                </List>
              </Card>
            }
          </Col>
          
          <Col xs={24} md={12}>
            <Card size='small' title='Voucher Information' bodyStyle={{padding: '24px'}} headStyle={{fontSize: '16px'}} style={{marginBottom: '24px'}}>
              {formType === 'admin' && (
                <Form.Item
                  htmlFor='status'
                  label='Status'
                  labelCol={{span: 8}}
                  name='status'
                  rules={[
                    {required: true, message: 'Required'}
                  ]}
                  style={{marginBottom: '0', marginTop: '0'}}
                >
                  <Select id='status'>
                    <Select.Option disabled={_includes(['F','V'], initValues.status)} value='P'>Pending</Select.Option>
                    <Select.Option disabled={_includes(['V'], initValues.status)} value='F'>Finalized</Select.Option>
                    <Select.Option value='V'>Void</Select.Option>
                  </Select>
                </Form.Item>
              )}
              
              <Form.Item
                dependencies={['otherreason']}
                htmlFor='departmentissue'
                label='Department'
                labelCol={{span: 8}}
                name='departmentissue'
                rules={[
                  {required: true, message: 'Required'}
                ]}
                style={{marginBottom: '0', marginTop: (formType === 'admin') ? '0.6em' : '0'}}
              >
                <Select id='departmentissue' onChange={(e) => {setIssue(form.getFieldValue('departmentissue'));}} disabled={formType === 'close' && !!initValues.departmentissue}>
                {
                  availableDepartments.map((dept: string) => {
                    return <Select.Option key={dept} value={dept}>{dept}</Select.Option>
                  })
                }
                </Select>
              </Form.Item>
              
              <Form.Item
                htmlFor='issuedto'
                label='Issued To'
                labelCol={{span: 8}}
                name='issuedto'
                rules={[
                  {
                    message: 'Required',
                    required: true
                  }
                ]}
                style={{marginBottom: '0', marginTop: '0.6em'}}
              >
                <Input
                  autoFocus={formType === 'create-existing'}
                  id='issuedto'
                  disabled={!!initValues.issuedto && formType === 'close'}
                />
              </Form.Item>
              
              <Form.Item
                htmlFor='amtnottoexceed'
                label='Max Amount'
                labelCol={{span: 8}}
                name='amtnottoexceed'
                normalize={val => (val.charAt(0) === '$') ? val.slice(1) : val}
                required={!fillup}
                rules={[
                  {
                    transform: val => (val.charAt(0) === '$') ? val.slice(1) : val,
                    validator: (_, val) => {
                      if (!fillup && (isNaN(parseFloat(val)) || parseFloat(val) <= 0)) {
                        return Promise.reject('Required');
                      }
                      
                      return Promise.resolve();
                    },
                  },
                ]}
                style={{marginBottom: '0', marginTop: '0.6em'}}
              >
                <Cleave
                  className='ant-input'
                  id='amtnottoexceed'
                  options={{
                    numeral: true,
                    numericOnly: true,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'thousand',
                    prefix: '$',
                    stripLeadingZeroes: true,
                  }}
                  onBlur={(e) => {
                    if (parseFloat(e.target.value.slice(1))) {
                      form.setFieldsValue({amtnottoexceed: parseFloat(e.target.value.slice(1)).toFixed(2)})
                    }
                  }}
                  disabled={(!!initValues.amtnottoexceed && formType === 'close') || fillup}
                />
              </Form.Item>
              
              <Form.Item
                colon={false}
                label='Fill Up?'
                labelCol={{span: 8}}
                name='fillup'
                style={{marginBottom: '0', marginTop: '0'}}
                valuePropName='checked'
              >
                <Switch
                  disabled={formType === 'close'}
                  onChange={() => {
                    if (form.getFieldValue('fillup')) {
                      form.setFieldsValue({amtnottoexceed: '0.00'});
                    }
                    
                    setFillup(form.getFieldValue('fillup'));
                    form.validateFields(['amtnottoexceed']);
                  }}
                />
              </Form.Item>
              
              {(formType === 'create-new' ||
                issue === 'Other - Enter a reason' ||
                (formType === 'admin' && !!initValues.otherreason)) && (
                  <Form.Item
                    colon={false}
                    htmlFor='otherreason'
                    label='Why are you creating this voucher?'
                    labelCol={{span: 24}}
                    name='otherreason'
                    rules={[
                      {
                        required: formType === 'create-new' || issue === 'Other - Enter a reason',
                        message: 'Required'
                      }
                    ]}
                    style={{marginBottom: '0', marginTop: '0.6em'}}
                    wrapperCol={{span: 24}}
                  >
                    <Input.TextArea disabled={formType === 'close'} id='otherreason' autoSize={{minRows: 2, maxRows: 6 }} />
                  </Form.Item>
                )
              }
              
              {(formType === 'close' ||
                formType === 'admin' ||
                formType === 'recap') && (
                  <Form.Item
                    htmlFor='totalspent'
                    label='Total Spent'
                    labelCol={{span: 8}}
                    name='totalspent'
                    normalize={val => (val.charAt(0) === '$') ? val.slice(1) : val}
                    rules={[
                      {
                        required: true,
                        message: 'Required',
                        transform: val => (val.charAt(0) === '$') ? val.slice(1) : val,
                      }
                    ]}
                    style={{marginBottom: '0', marginTop: '0.6em'}}
                  >
                    <Cleave
                      autoFocus={formType === 'close'}
                      name='totalspent'
                      className='ant-input'
                      id='totalspent'
                      options={{
                        numeral: true,
                        numericOnly: true,
                        numeralPositiveOnly: true,
                        numeralThousandsGroupStyle: 'thousand',
                        prefix: '$',
                        stripLeadingZeroes: true,
                      }}
                      onBlur={(e) => {
                        if (parseFloat(e.target.value.slice(1))) {
                          form.setFieldsValue({totalspent: parseFloat(e.target.value.slice(1)).toFixed(2)})
                        }
                      }}
                    />
                  </Form.Item>
                )
              }
            </Card>
          </Col>
        </Row>
        
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button htmlType='submit' type='primary' loading={submitting} style={{marginLeft: '8px'}}>
            {submitting ? 'Saving' : 'Save'}
          </Button>
        </div>
    </Form>
  )
}
