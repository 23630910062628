import styled from 'styled-components'

export const TBody = styled.tbody`
  tr {
    width: 100%;
  }

  td:first-child {
    padding-left: 10px;
  }

  td:last-child {

  }

  td {
    max-width: 50px;
    font-size: 11px;
    padding: 3px 2px;
    font-weight: bolder
  }

  td:last-child {
    border-left: none !important;
  }

  #fuelops__recap-voucherid-td {
    cursor: pointer;
    font-weight: bold;
    color: #788295;
  }
`
