import React from 'react';
import moment from 'moment'
import { Table } from 'react-bootstrap'
import { Container } from './Styled'
import { api } from 'data/apollo/api';
import { useQuery } from '@apollo/client';

export const OrderHistory: React.FC<{ currentVoucher: any }> = ({
  currentVoucher
}) => {
  
  const {
    data: historyData
  } = useQuery(api.remote.query.findPastRecords, {
    variables: {
      clientid: currentVoucher.clientid,
      vinnum: currentVoucher.vinnum
    }
  });
  
  return (historyData && historyData.getPastRecords.length > 1) ? (
    <Container>
      <h5>VOUCHER HISTORY FOR THIS VEHICLE</h5>
      <Table responsive size='sm'>
        <thead>
          <tr>
            <th>Date Created</th>
            <th>VIN</th>
            <th>Authorized Amount</th>
            <th>Created By</th>
            <th>Issued To</th>
          </tr>
        </thead>
        <tbody>
          {historyData && historyData.getPastRecords.map((voucher: any, idx: number) => (
            (voucher.voucherid !== currentVoucher.voucherid) && 
              <tr key={idx}>
                <td>{moment(voucher.createdate).format('MM/DD/YYYY')}</td>
                <td>{voucher.vinnum}</td>
                <td>
                  {voucher.fillup
                    ? 'Fill-up'
                    : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(voucher.amtnottoexceed)
                  }
                </td>
                <td>{voucher.createdby}</td>
                <td>{voucher.issuedto}</td>
              </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  ) : null
}
